const insertParamsIntoRoutePath = (route, params) => {
  let updatedRoute = route;
  if (typeof params === "object") {
    Object.entries(params).forEach(([key, value]) => {
      const paramName = ":" + key;

      if (!route.includes(paramName)) {
        throw new Error(
          `The following param argument is invalid: ${paramName}`
        );
      }

      updatedRoute = updatedRoute.replace(paramName, value);
    });
  }

  if (updatedRoute.indexOf(":") !== -1) {
    throw new Error("Failed to replace one of the route params");
  }

  return updatedRoute;
};

const routes = {
  HOME: "/",

  // Data
  SEC_FILING_DATA: "/secFilingsData",
  EARNINGS_TRANSCRIPT_DATA: "/earningsTranscriptData",

  // Transcripts
  EARNINGS_TRANSCRIPTS: "/earningsTranscripts",
  EARNINGS_TRANSCRIPT: "/earningsTranscripts/:earningsTranscriptId",

  // Filings
  SEC_FILINGS: "/secFilings",
  SEC_FILING: "/secFilings/:secFilingId",
  ADMIN_SEC_FILING_REVIEW: "/secFilings/adminSecFilingReview/:secFilingId",

  // Admin
  ADMIN_DATUM_REVIEW: "/adminDatumReview",
  COMPANY_METRIC_REVIEW: "/companyMetricReview",
  ADMIN_METRIC_REVIEW: "/adminMetricReview",

  // Auth
  LOGIN: "/login",
  SIGNUP: "/signup",
  CONFIRM_EMAIL: "/confirmEmail",

  // About
  ABOUT: "/about",

  // Contact
  CONTACT: "/contact",

  insertParamsIntoRoutePath,
};

export default routes;
