import React from "react";

const TableRow = ({
  row,
  rowIndex,
  rows,
  prepareRow,
  columnWidthsState,
  defaultColumnWidth,
  rowHeightsState,
  defaultRowHeight,
  classes,
  startResizingColumn,
  handleColumnDoubleClick,
  startResizingRow,
  handleRowDoubleClick,
  wrapText,
  isHighlightedCell,
  isHighlightedSourceCell,
  finDataTable,
}) => {
  prepareRow(row);
  return (
    <tr {...row.getRowProps()} key={rowIndex}>
      {row.cells.map((cell, columnIndex) => {
        const isCellHighlighted = isHighlightedCell(
          // Adding 1 to account for the header row
          rowIndex + 1,
          columnIndex
        );
        const isCellHighlightedSource = isHighlightedSourceCell(
          // Adding 1 to account for the header row
          rowIndex + 1,
          columnIndex
        );
        const colorOfHighlightedSource = isCellHighlightedSource
          ? finDataTable.grabColorOfHighlightedSourceCell(
              rowIndex + 1,
              columnIndex
            )
          : null;

        return (
          <td
            className={`${classes.resizableTd} ${
              columnIndex !== 0 ? classes.rightAlignedCell : ""
            }`}
            {...cell.getCellProps()}
            style={{
              width: `${
                columnWidthsState[columnIndex] || defaultColumnWidth
              }px`,
              paddingRight: "4px",
              paddingLeft: "4px",
              height: `${rowHeightsState[rowIndex + 1] || defaultRowHeight}px`,
              whiteSpace: wrapText ? "normal" : "nowrap",
              overflow: "hidden",
              textOverflow: wrapText ? "clip" : "ellipsis",
              wordBreak: wrapText ? "break-word" : "normal",
              borderLeft: isCellHighlightedSource
                ? `3px solid ${colorOfHighlightedSource}`
                : isCellHighlighted
                ? "3px solid black"
                : columnIndex === 0
                ? "2px solid black"
                : "1px solid black",
              borderRight: isCellHighlightedSource
                ? `3px solid ${colorOfHighlightedSource}`
                : isCellHighlighted
                ? "3px solid black"
                : columnIndex === row.cells.length - 1
                ? "2px solid black"
                : "1px solid black",
              borderBottom: isCellHighlightedSource
                ? `3px solid ${colorOfHighlightedSource}`
                : isCellHighlighted
                ? "3px solid black"
                : rowIndex === rows.length - 1
                ? "2px solid black"
                : "1px solid black",
              borderTop: isCellHighlightedSource
                ? `3px solid ${colorOfHighlightedSource}`
                : isCellHighlighted
                ? "3px solid black"
                : "1px solid black",
            }}
            key={columnIndex}
          >
            {cell.render("Cell")}
            <div
              className={classes.resizeHandle}
              onDoubleClick={() => handleColumnDoubleClick(columnIndex)}
              onMouseDown={(e) =>
                startResizingColumn(
                  columnIndex,
                  columnWidthsState[columnIndex],
                  e.clientX
                )
              }
            />
            <div
              className={classes.rowResizeHandle}
              onMouseDown={(e) =>
                startResizingRow(
                  rowIndex + 1,
                  rowHeightsState[rowIndex + 1],
                  e.clientY
                )
              }
              onDoubleClick={() => handleRowDoubleClick(rowIndex + 1)}
            />
          </td>
        );
      })}
    </tr>
  );
};

export default TableRow;
