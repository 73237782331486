import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    subTitle: {
      marginTop: 50,
    },
  };
});

function About() {
  const classes = useStyles();

  const allTickers = [
    "AIRC",
    "AVB",
    "BXP",
    "CPT",
    "DEI",
    "EQR",
    "ESS",
    "HPP",
    "ILPT",
    "IRT",
    "KIM",
    "KRC",
    "PGRE",
    "UDR",
  ];

  return (
    <div>
      <h2>About findata.ai</h2>
      <p>
        At findata.ai, we believe that the future of financial analysis will be
        deeply integrated with artificial intelligence
      </p>
      <p>
        By utilizing AI, our tools enable analysts to automate mundane, manual
        tasks while also broadening the scope of their analyses to that of
        entire industries or markets
      </p>
      <h4 className={classes.subTitle}>Current products</h4>
      <p>
        - Automated scraping of REIT cap table data based on 10-Q / 10-K filings
      </p>
      <p>- Automated scraping of REIT quarterly earnings transcripts</p>
      <p>
        {`- Deep links between each piece of data and either 1) the location within the SEC filing or
        earnings transcript in which it was found or 2) details about the
        formula used for calculating the data in the case of computed metrics`}
      </p>
      <p>REITs covered:</p>
      <p>- {allTickers.join(", ")}</p>
      <h4 className={classes.subTitle}>
        Under development / on product roadmap
      </h4>
      <p>
        - Fully automated scraping of covered company 10-Qs, 10-Ks, investor
        presentations, and financial supplements (including deep-linking)
      </p>
      <p>
        - Data extraction UI interface where analysts can manually extract
        important data. Our AI system will then automatically extract that data
        in future quarters for use within our website
      </p>
      <p>- Expansion of public company coverage universe</p>
      <p>
        - Expansion to private markets: simply upload a CIM, Private Placement
        Memorandum, or Quarterly Report and have our system extract the data for
        you
      </p>
    </div>
  );
}

export default About;
