/**
 * Colorize the cell inner content based on the highlighted source cells in the finDataTable
 * @param {string} cellInnerContent - The inner content of the cell
 * @param {FinDataTable} finDataTable - The FinDataTable object
 * @returns {string} - The colorized cell inner content
 */
export function colorizeCellInnerContent(cellInnerContent, finDataTable) {
  // Regular expression to match cell addresses in the formula
  const regex = /[A-Z]+\d+/g;
  let matches = cellInnerContent.match(regex) || [];

  // Deduplicate matches to avoid processing the same cell reference multiple times
  let uniqueMatches = [...new Set(matches)];

  // Process each unique match
  uniqueMatches.forEach((match) => {
    // Find the corresponding highlighted cell in finDataTable.highlightedSourceCells
    let highlightedCell = finDataTable.highlightedSourceCells.find(
      (cell) => cell.cellAddress === match
    );

    // If a highlighted cell is found, replace the cell reference in the formula with a colored span
    if (highlightedCell) {
      const color = highlightedCell.color; // Assuming this is how you access the color
      const coloredSpan = `<span style="color: ${color};">${match}</span>`;

      // Replace all occurrences of the cell reference in the formula with the colored span
      // Use a function as the second argument to replace to ensure all occurrences are replaced
      cellInnerContent = cellInnerContent.split(match).join(coloredSpan);
    }
  });

  return cellInnerContent;
}
