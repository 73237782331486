import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import { COGNITO_USER_GROUP_ADMIN } from "../../constants";

// Sub-components
import ViewMetrics from "./ViewMetrics";
import ViewFinDataTables from "./ViewFinDataTables";
import ViewMetricFinDataTables from "./ViewMetricFinDataTables";
import CreateNewMetric from "../SimpleDynamoDBForms/CreateNewMetric";
import CreateNewMetricFinDataTable from "../SimpleDynamoDBForms/CreateNewMetricFinDataTable";
import UpdateMetric from "../SimpleDynamoDBForms/UpdateMetric";

const useStyles = makeStyles({});

function AdminMetricReview(props) {
  const classes = useStyles();
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );
  const history = useHistory();

  // Lookups
  const metricsLookup = useSelector((state) => state.metrics.metricsLookup);
  const finDataTablesLookup = useSelector(
    (state) => state.finDataTables.finDataTablesLookup
  );
  const metricFinDataTablesLookup = useSelector(
    (state) => state.metricFinDataTables.metricFinDataTablesLookup
  );

  useEffect(() => {
    if (
      !(
        authenticatedUser &&
        authenticatedUser.groups &&
        Array.isArray(authenticatedUser.groups) &&
        authenticatedUser.groups.length > 0 &&
        authenticatedUser.groups.includes(COGNITO_USER_GROUP_ADMIN)
      )
    ) {
      history.push(routes.HOME);
      return;
    }
  }, [authenticatedUser, history]);

  return (
    <>
      <div>
        <div style={{ marginBottom: "36px" }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              marginBottom: "15px",
            }}
          >
            {"All Metrics"}
          </div>
          <div
            style={{
              marginTop: "24px",
            }}
          >
            <ViewMetrics metricsLookup={metricsLookup} />
          </div>
          <div style={{ marginTop: "12px" }}>
            <CreateNewMetric showCreateNewMetricFormula={true} />
          </div>
          <div style={{ marginTop: "12px" }}>
            <UpdateMetric showUpdateMetricFormula={true} />
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "36px" }}>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginBottom: "15px",
          }}
        >
          {"All FinDataTables"}
        </div>
        <div
          style={{
            marginTop: "24px",
          }}
        >
          <ViewFinDataTables finDataTablesLookup={finDataTablesLookup} />
        </div>
      </div>

      <div style={{ marginBottom: "36px" }}>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginBottom: "15px",
          }}
        >
          {"All MetricFinDataTables"}
        </div>
        <div
          style={{
            marginTop: "24px",
          }}
        >
          <ViewMetricFinDataTables
            metricFinDataTablesLookup={metricFinDataTablesLookup}
            metricsLookup={metricsLookup}
            finDataTablesLookup={finDataTablesLookup}
          />
        </div>
        <div style={{ marginTop: "12px" }}>
          <CreateNewMetricFinDataTable />
        </div>
      </div>
    </>
  );
}

export default AdminMetricReview;
