import ActionTypes from "./actionTypes";

export const setAuthenticatedUser = async (dispatch, authenticatedUser) => {
  if (authenticatedUser) {
    dispatch({
      type: ActionTypes.SET_AUTHENTICATED_USER,
      payload: authenticatedUser,
    });
  } else {
    dispatch({
      type: ActionTypes.RESET_AUTHENTICATION,
    });
  }
};
