import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import useFormChangeHook from "../hooks/formChangeHook";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateDatumAction } from "../../actions/datumsActions";
import { useDispatch, useSelector } from "react-redux";
import ButtonWithCircularProgress from "../ButtonWithCircularProgress";

const useStyles = makeStyles((theme) => {
  return {
    formContainer: {
      marginBottom: 75,
      width: "100%",
    },
    fullWidthContainer: {
      width: "100%",
      marginBottom: 20,
    },
    inputContainer: {
      marginBottom: 20,
    },
    subTitle: {
      fontSize: "14px",
      fontStyle: "italic",
      marginBottom: 10,
    },
  };
});

const EMPTY_FORM = {
  id: "",
  transcriptMetadata: "",
  sentence: "",
  paragraph: "",
  rawCompletion: "",
  unableToConfidentlyExtractData: "",
  value: null,
  isSnapshot: "",
  timePeriodInterval: "",
  timePeriodValue: null,
  timePeriodEndDate: "",
  asOfDate: "",
  symbol: "",
  qualifiers: [],
  isGuidance: "",
  timePeriodConfidence: "",
  companyOrMarketData: "",
  comparisonPeriod: "",
  humanReviewed: null,
  metricId: "",
  metric: "",
  companyId: "",
  sentenceEarningsTranscriptStartIdx: null,
  sentenceEarningsTranscriptEndIdx: null,
  xbrlTag: null,
  tenQOrKId: null,
};

function SingularDatumReview10QOrK(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const datum = props.datum;
  const metricsLookup = useSelector((state) => state.metrics.metricsLookup);
  const metric = metricsLookup[datum.metricId];
  const [isSaving, setIsSaving] = useState(false);

  const { form, setForm, formChanged, resetInitialForm } =
    useFormChangeHook(EMPTY_FORM);

  useEffect(() => {
    let initialForm = {
      id: datum.id,
      transcriptMetadata: datum.transcriptMetadata,
      sentence: datum.sentence,
      paragraph: datum.paragraph,
      rawCompletion: datum.rawCompletion,
      unableToConfidentlyExtractData: datum.unableToConfidentlyExtractData,
      value: datum.value,
      isSnapshot: datum.isSnapshot,
      timePeriodInterval: datum.timePeriodInterval,
      timePeriodValue: datum.timePeriodValue,
      timePeriodEndDate: datum.timePeriodEndDate,
      asOfDate: datum.asOfDate,
      symbol: datum.symbol,
      qualifiers: datum.qualifiers,
      isGuidance: datum.isGuidance,
      timePeriodConfidence: datum.timePeriodConfidence,
      companyOrMarketData: datum.companyOrMarketData,
      comparisonPeriod: datum.comparisonPeriod,
      humanReviewed: datum.humanReviewed,
      metricId: datum.metricId,
      metric: metric.metric,
      companyId: datum.companyId,
      sentenceEarningsTranscriptStartIdx:
        datum.sentenceEarningsTranscriptStartIdx,
      sentenceEarningsTranscriptEndIdx: datum.sentenceEarningsTranscriptEndIdx,
      xbrlTag: datum.xbrlTag,
      tenQOrKId: datum.tenQOrKId,
    };
    resetInitialForm(initialForm);
  }, []);

  const updateForm = (newFormVals) => {
    setForm({ ...form, ...newFormVals });
  };

  const handleCreateNewQualifier = () => {
    updateForm({
      qualifiers:
        form.qualifiers && Array.isArray(form.qualifiers)
          ? [...form.qualifiers, ""]
          : [""],
    });
  };

  const handleSubmitDatumReview = async () => {
    setIsSaving(true);
    // One. Make sure that you remove any empty strings from array of qualifiers upon submit
    let qualifiersWithEmptyStringsRemoved = [];
    if (form.qualifiers && Array.isArray(form.qualifiers)) {
      qualifiersWithEmptyStringsRemoved = form.qualifiers.filter(
        (qualifier) => !!qualifier
      );
    }

    const updatedForm = {
      ...form,

      // The below updates to values are mainly in order to convert empty strings to null in the event
      // where I am removing values from the form in an event where OpenAI tried to deduce
      // a value from the data but upon human review, it was decided all fields should be removed
      qualifiers: form.unableToConfidentlyExtractData
        ? null
        : qualifiersWithEmptyStringsRemoved,
      value: isNaN(form.value) ? null : form.value,
      isSnapshot: form.unableToConfidentlyExtractData ? null : form.isSnapshot,
      timePeriodInterval: !form.timePeriodInterval
        ? null
        : form.timePeriodInterval,
      timePeriodEndDate: !form.timePeriodEndDate
        ? null
        : form.timePeriodEndDate,
      asOfDate: !form.asOfDate ? null : form.asOfDate,
      symbol: !form.symbol ? null : form.symbol,
      isGuidance: form.unableToConfidentlyExtractData ? null : form.isGuidance,
      timePeriodConfidence: !form.timePeriodConfidence
        ? null
        : form.timePeriodConfidence,
      companyOrMarketData: !form.companyOrMarketData
        ? null
        : form.companyOrMarketData,
      comparisonPeriod: !form.comparisonPeriod ? null : form.comparisonPeriod,
    };
    delete updatedForm["metric"];
    delete updatedForm["xbrlTag"];

    await updateDatumAction(dispatch, updatedForm, datum.earningsTranscriptId);

    setIsSaving(false);
    props.setSidePanelClosed();
  };

  return (
    <div>
      <Typography>ID:</Typography>
      <Typography className={classes.inputContainer}>{form.id}</Typography>

      <Typography>Transcript Metadata:</Typography>
      <TextField
        value={form.transcriptMetadata}
        onChange={(e) => updateForm({ transcriptMetadata: e.target.value })}
        className={classes.fullWidthContainer}
      />

      <Typography>Sentence:</Typography>
      <div
        dangerouslySetInnerHTML={{ __html: form.sentence }}
        className={classes.fullWidthContainer}
      />

      <Typography>Sentence Raw HTML:</Typography>
      <TextField
        value={form.sentence}
        onChange={(e) => updateForm({ sentence: e.target.value })}
        className={classes.fullWidthContainer}
        multiline
      />

      <Typography>Paragraph:</Typography>
      <div
        dangerouslySetInnerHTML={{ __html: form.paragraph }}
        className={classes.fullWidthContainer}
      />

      <Typography>Paragraph Raw HTML:</Typography>
      <TextField
        value={form.paragraph}
        onChange={(e) => updateForm({ paragraph: e.target.value })}
        className={classes.fullWidthContainer}
        multiline
      />

      <Typography>Raw completion:</Typography>
      <TextField
        value={form.rawCompletion}
        onChange={(e) => updateForm({ rawCompletion: e.target.value })}
        className={classes.fullWidthContainer}
        multiline
      />

      <Typography>Unable to Confidently Extract Data:</Typography>
      <Checkbox
        checked={form.unableToConfidentlyExtractData}
        onChange={(e) =>
          updateForm({ unableToConfidentlyExtractData: e.target.checked })
        }
        className={classes.inputContainer}
      />

      <Typography>Value</Typography>
      <TextField
        type="number"
        value={form.value}
        onChange={(e) => updateForm({ value: parseFloat(e.target.value) })}
        className={classes.inputContainer}
      />

      <Typography>Is Snapshot</Typography>
      <Checkbox
        checked={form.isSnapshot}
        onChange={(e) => updateForm({ isSnapshot: e.target.checked })}
        className={classes.inputContainer}
      />

      <Typography>Time Period Interval:</Typography>
      <Typography
        className={classes.subTitle}
      >{`${"note: should be DAYS, MONTHS, QUARTERS, YEARS, MONTH_TO_DATE, QUARTER_TO_DATE, or YEAR_TO_DATE"}`}</Typography>

      <TextField
        value={form.timePeriodInterval}
        onChange={(e) => updateForm({ timePeriodInterval: e.target.value })}
        className={classes.inputContainer}
      />

      <Typography>Time Period Value</Typography>
      <TextField
        type="number"
        value={form.timePeriodValue}
        onChange={(e) =>
          updateForm({ timePeriodValue: parseInt(e.target.value) })
        }
        className={classes.inputContainer}
      />

      <Typography>Time Period End Date:</Typography>
      <TextField
        value={form.timePeriodEndDate}
        onChange={(e) => updateForm({ timePeriodEndDate: e.target.value })}
        className={classes.inputContainer}
      />

      <Typography>As of Date:</Typography>
      <TextField
        value={form.asOfDate}
        onChange={(e) => updateForm({ asOfDate: e.target.value })}
        className={classes.inputContainer}
      />

      <Typography>Symbol:</Typography>
      <TextField
        value={form.symbol}
        onChange={(e) => updateForm({ symbol: e.target.value })}
        className={classes.inputContainer}
      />

      <Typography>Qualifiers:</Typography>
      <Typography
        className={classes.subTitle}
      >{`${"note: empty strings will be removed from qualifiers array upon submit"}`}</Typography>
      {form.qualifiers && form.qualifiers.length > 0 ? (
        form.qualifiers.map((qualifier) => {
          return (
            <div id={qualifier}>
              <TextField
                value={qualifier}
                onChange={(e) => {
                  const updatedQualifier = e.target.value;
                  const indexOfQualifier = form.qualifiers.findIndex(
                    (q) => q === qualifier
                  );
                  updateForm({
                    qualifiers: [
                      ...form.qualifiers.slice(0, indexOfQualifier),
                      updatedQualifier,
                      ...form.qualifiers.slice(indexOfQualifier + 1),
                    ],
                  });
                }}
                className={classes.inputContainer}
              />
            </div>
          );
        })
      ) : (
        <Typography className={classes.inputContainer}>N/A</Typography>
      )}
      <Button
        variant="contained"
        onClick={handleCreateNewQualifier}
        className={classes.inputContainer}
      >
        Add new qualifier
      </Button>
      <Typography>Is Guidance:</Typography>
      <Checkbox
        checked={form.isGuidance}
        onChange={(e) => updateForm({ isGuidance: e.target.checked })}
        className={classes.inputContainer}
      />
      <Typography>Time Period Confidence:</Typography>
      <TextField
        value={form.timePeriodConfidence}
        onChange={(e) => updateForm({ timePeriodConfidence: e.target.value })}
        className={classes.inputContainer}
      />
      <Typography>Company or Market Data</Typography>
      <Typography
        className={classes.subTitle}
      >{`${"note: should be COMPANY or MARKET"}`}</Typography>
      <TextField
        value={form.companyOrMarketData}
        onChange={(e) => updateForm({ companyOrMarketData: e.target.value })}
        className={classes.inputContainer}
      />
      <Typography>Comparison Period</Typography>
      <Typography
        className={classes.subTitle}
      >{`${"note: should be MoM, YoY, QoQ, or LEASE_OVER_LEASE"}`}</Typography>
      <TextField
        value={form.comparisonPeriod}
        onChange={(e) => updateForm({ comparisonPeriod: e.target.value })}
        className={classes.inputContainer}
      />
      <Typography>Metric ID:</Typography>
      <Typography className={classes.inputContainer}>
        {form.metricId}
      </Typography>
      <Typography>Metric:</Typography>
      <Typography className={classes.inputContainer}>{form.metric}</Typography>
      {form.xbrlTag && (
        <>
          <Typography>XBRL Tag:</Typography>
          <Typography className={classes.inputContainer}>
            {form.xbrlTag.tag}
          </Typography>
          <Typography>XBRL Tag Plain English:</Typography>
          <Typography className={classes.inputContainer}>
            {form.xbrlTag.tagPlainEnglish}
          </Typography>
        </>
      )}

      <Typography>TenQOrK ID:</Typography>
      <Typography className={classes.inputContainer}>
        {form.tenQOrKId}
      </Typography>
      <Typography>Company ID:</Typography>
      <Typography className={classes.inputContainer}>
        {form.companyId}
      </Typography>
      <Typography>Human Reviewed:</Typography>
      <Checkbox
        checked={form.humanReviewed}
        onChange={(e) => updateForm({ humanReviewed: e.target.checked })}
        className={classes.inputContainer}
      />
      <div style={{ marginTop: "12px" }}>
        <ButtonWithCircularProgress
          onClick={handleSubmitDatumReview}
          disabled={!formChanged}
          isSaving={isSaving}
          buttonText="Submit Datum Review"
        />
      </div>
    </div>
  );
}

export default SingularDatumReview10QOrK;
