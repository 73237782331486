import moment from "moment";
import routes from "../routes";

// Helpers
import { convertWordIntoALowerCaseWordWithUpperCaseFirstLetter } from "../helpers/miscHelpers";
import { formatMetric } from "../helpers/formatNumberHelpers";
import {
  determineFiscalQuarterFromQuarterEndDate,
  convertFiscalQuarterAndFiscalYearToShortHandString,
} from "../helpers/dateHelpers";
import { scrollWithOffset } from "../helpers/scrollHelpers";

// Classes
import FinDataTableCell from "../classes/FinDataTableCell";
import FinDataTable from "../classes/FinDataTable";
import ReactRouterHashLinkMeta from "../classes/ReactRouterHashLinkMeta";

// Constants
import {
  TIME_PERIOD_INTERVAL_MONTHS_DB_ENUM,
  COGNITO_USER_GROUP_ADMIN,
} from "../constants";

// --------------- Filings selectors ---------------

export const selectCurrentFilingFinancialStatements = (state, filing) => {
  const returnObj = {};
  Object.values(state.financialStatements.financialStatementsLookup).forEach(
    (financialStatement) => {
      if (financialStatement.tenQOrKId === filing.id) {
        returnObj[financialStatement.id] = financialStatement;
      }
    }
  );
  return returnObj;
};

// --------------- Datums selectors ---------------

export const selectHumanlyReviedConfidentlyExtractedDatumsForEarningsTranscript =
  (state, earningsTranscript) => {
    const datumsLookup = state.datums.datumsLookup;
    const returnObj = {};
    Object.entries(datumsLookup).forEach(([datumId, datum]) => {
      if (
        datum.earningsTranscriptId === earningsTranscript.id &&
        datum.humanReviewed &&
        !datum.unableToConfidentlyExtractData
      ) {
        returnObj[datumId] = datum;
      }
    });
    return returnObj;
  };

export const selectNonHumanReviewedDatums = (state) => {
  const datumsLookup = state.datums.datumsLookup;
  const returnObj = {};
  Object.entries(datumsLookup).forEach(([datumId, datum]) => {
    if (!datum.humanReviewed) {
      returnObj[datumId] = datum;
    }
  });
  return returnObj;
};

export const selectHumanReviewedConfidentlyExtractedSecFilingsDataForCompany = (
  state,
  companyId
) => {
  const datumsLookup = state.datums.datumsLookup;
  const returnObj = {};
  Object.entries(datumsLookup).forEach(([datumId, datum]) => {
    if (
      datum.humanReviewed &&
      !datum.unableToConfidentlyExtractData &&
      datum.tenQOrKId &&
      datum.companyId === companyId
    ) {
      returnObj[datumId] = datum;
    }
  });
  return returnObj;
};

export const createFinDataTableClassInstanceUsingFinDataTableIdAndCompanyId = (
  state,
  finDataTableId,
  companyId,
  finDataTableName
) => {
  const datumsLookup = state.datums.datumsLookup;
  const companiesLookup = state.companies.companiesLookup;
  const company = companiesLookup[companyId];

  const companyFinDataTableMetricOrdersLookup =
    state.companyFinDataTableMetricOrders.companyFinDataTableMetricOrdersLookup;

  const metricsLookup = state.metrics.metricsLookup;

  const columnHeaders = [];
  const rowHeaders = [];
  const data = [];
  const datumForFinDataTableAndCompanyLookup = {};
  const filingsIds = new Set();

  Object.entries(companyFinDataTableMetricOrdersLookup).forEach(
    ([companyFinDataTableMetricOrderId, companyFinDataTableMetricOrder]) => {
      if (
        companyFinDataTableMetricOrder.finDataTableId === finDataTableId &&
        companyFinDataTableMetricOrder.companyId === companyId
      ) {
        const metricId = companyFinDataTableMetricOrder.metricId;
        const metric = metricsLookup[metricId];

        if (
          !rowHeaders
            .map((rowHeader) => rowHeader?.metadata?.key)
            .includes(metric?.metric)
        ) {
          rowHeaders.push(
            new FinDataTableCell(
              convertWordIntoALowerCaseWordWithUpperCaseFirstLetter(
                metric?.metricPlainEnglish
              ),
              null,
              {
                key: metric?.metric,
                order: companyFinDataTableMetricOrder?.order,
                metricId: companyFinDataTableMetricOrder?.metricId,
                metricType: metric?.type,
                metricFormula: metric?.formula
                  ? JSON.parse(metric.formula)
                  : null,
              }
            )
          );
        }

        for (const idx in datumsLookup) {
          const datum = datumsLookup[idx];

          // TODO: we are only going to support having
          // EITHER
          // timePeriodInterval of TIME_PERIOD_INTERVAL_MONTHS_DB_ENUM
          // AND timePeriodValue of 1
          // OR
          // isSnapshot of true
          // Will need to update this if we want to support
          // other timePeriodIntervals and timePeriodValues
          const datumIsQuarterDataOrSnapshot =
            (datum.timePeriodInterval === TIME_PERIOD_INTERVAL_MONTHS_DB_ENUM &&
              datum.timePeriodValue === 1) ||
            datum.isSnapshot;

          if (
            datum.metricId === metricId &&
            datum.companyId === companyId &&
            datumIsQuarterDataOrSnapshot
          ) {
            datumForFinDataTableAndCompanyLookup[datum.id] = datum;

            if (
              !columnHeaders
                .map((header) => header?.metadata?.timePeriodEndDate)
                .includes(datum?.timePeriodEndDate)
            ) {
              const [fiscalQuarter, fiscalYear] =
                determineFiscalQuarterFromQuarterEndDate(
                  datum.timePeriodEndDate,
                  company.fiscalYearEnd
                );
              const quarter =
                convertFiscalQuarterAndFiscalYearToShortHandString(
                  fiscalQuarter,
                  fiscalYear
                );

              columnHeaders.push(
                new FinDataTableCell(quarter, null, {
                  timePeriodEndDate: datum.timePeriodEndDate,
                })
              );
            }
          }
        }
      }
    }
  );

  columnHeaders.sort((a, b) => {
    return (
      new Date(a?.metadata?.timePeriodEndDate) -
      new Date(b?.metadata?.timePeriodEndDate)
    );
  });
  rowHeaders.sort((a, b) => a?.metadata?.order - b?.metadata?.order);

  const authenticatedUser = state.auth.authenticatedUser;
  const userIsAdmin =
    authenticatedUser &&
    authenticatedUser.groups &&
    Array.isArray(authenticatedUser.groups) &&
    authenticatedUser.groups.length > 0 &&
    authenticatedUser.groups.includes(COGNITO_USER_GROUP_ADMIN);

  rowHeaders.forEach((rowHeader) => {
    const row = [];
    const metric = metricsLookup[rowHeader?.metadata?.metricId];
    columnHeaders.forEach((columnHeader) => {
      const datum = Object.values(datumForFinDataTableAndCompanyLookup).find(
        (datum) => {
          const metric = metricsLookup[datum.metricId];
          return (
            datum?.timePeriodEndDate ===
              columnHeader?.metadata?.timePeriodEndDate &&
            metric?.metric === rowHeader?.metadata?.key
          );
        }
      );

      row.push(
        new FinDataTableCell(
          formatMetric(datum?.value, metric?.valueType),
          undefined,
          datum?.id ? { datumId: datum?.id } : undefined,
          undefined,
          // If datum exists and has a tenQOrKId, create a ReactRouterHashLinkMeta instance
          datum && datum.tenQOrKId
            ? new ReactRouterHashLinkMeta(
                {
                  pathname: `${routes.insertParamsIntoRoutePath(
                    userIsAdmin
                      ? routes.ADMIN_SEC_FILING_REVIEW
                      : routes.SEC_FILING,
                    {
                      secFilingId: datum.tenQOrKId,
                    }
                  )}`,
                  hash: `#TBU`,
                  search: "?source=datumLink",
                },
                scrollWithOffset,
                () => {
                  return;
                }
              )
            : undefined
        )
      );
      if (datum?.tenQOrKId) {
        filingsIds.add(datum?.tenQOrKId);
      }
    });
    data.push(row);
  });

  return new FinDataTable(
    `${convertWordIntoALowerCaseWordWithUpperCaseFirstLetter(
      finDataTableName
    )}`,
    null,
    columnHeaders,
    rowHeaders,
    data,
    null,
    null,
    filingsIds
  );
};

export const selectDatumForTenQOrK = (state, tenQOrKId) => {
  const datumsLookup = state.datums.datumsLookup;
  const returnObj = {};
  Object.entries(datumsLookup).forEach(([datumId, datum]) => {
    if (datum.tenQOrKId === tenQOrKId) {
      returnObj[datumId] = datum;
    }
  });
  return returnObj;
};

export const selectDatumsGroupedByEarningsTranscript = (state) => {
  const datumsLookup = state.datums.datumsLookup;
  const transcriptsLookup = state.transcripts.transcriptsLookup;
  const returnObj = {};
  // Group all earningsTranscripts to have a data array that contains all
  // datums that belong to the earningsTranscript
  Object.entries(transcriptsLookup).forEach(([transcriptId, transcript]) => {
    returnObj[transcriptId] = {
      ...transcript,
      data: Object.values(datumsLookup).filter(
        (datum) =>
          datum.earningsTranscriptId === transcriptId &&
          datum.humanReviewed &&
          !datum.unableToConfidentlyExtractData
      ),
    };
  });
  return returnObj;
};

// ------------------ FIN_DATA_TABLE / COMPANY_FIN_DATA_METRIC_TABLE_ORDERS SELECTORS ------------------

export const createCompanyFinDataTableMetricOrdersLookupForCompany = (
  state,
  companyId
) => {
  const companyFinDataTableLookup = {};

  if (
    !companyId ||
    !state ||
    !state.companyFinDataTableMetricOrders ||
    !state.companyFinDataTableMetricOrders
      .companyFinDataTableMetricOrdersLookup ||
    !state.finDataTables ||
    !state.finDataTables.finDataTablesLookup
  ) {
    return companyFinDataTableLookup;
  }

  // Iterate over each CompanyFinDataTableMetricOrder
  Object.values(
    state.companyFinDataTableMetricOrders.companyFinDataTableMetricOrdersLookup
  ).forEach((order) => {
    // Check if the CompanyFinDataTableMetricOrder belongs to the specified company
    if (order.companyId === companyId) {
      // Create a unique key using companyId and finDataTableId
      const key = `${companyId}#${order.finDataTableId}`;

      // If the key doesn't exist in the lookup, initialize it
      if (!companyFinDataTableLookup[key]) {
        const finDataTable =
          state.finDataTables.finDataTablesLookup[order.finDataTableId];

        // Check if the corresponding FinDataTable exists
        if (finDataTable) {
          companyFinDataTableLookup[key] = {
            ...finDataTable,
            companyFinDataTableMetricOrders: [],
          };
        }
      }

      // Add the current order to the companyFinDataTableMetricOrders array
      if (companyFinDataTableLookup[key]) {
        companyFinDataTableLookup[key].companyFinDataTableMetricOrders.push(
          order
        );
      }
    }
  });

  return companyFinDataTableLookup;
};

// ---------------------- METRICS FOR COMPANY FOR FIN_DATA_TABLE SELECTORS ----------------------

export const selectMetricsInUseForCompany = (state, companyId) => {
  // Extract all Datum records for the given companyId
  const allDataForCompany = Object.values(state.datums.datumsLookup).filter(
    (datum) => datum.companyId === companyId
  );

  // Reduce the filtered data into a lookup object of unique Metrics
  const metricsLookup = allDataForCompany.reduce((acc, datum) => {
    const metricId = datum.metricId;
    if (metricId && !acc[metricId]) {
      acc[metricId] = state.metrics.metricsLookup[metricId];
    }
    return acc;
  }, {});

  return metricsLookup;
};

// ---------------------- COMPANIES SELECTORS ----------------------

export const selectCompaniesWithAtLeastOneCompanyFinDataTableMetricOrder = (
  state
) => {
  const companyFinDataTableMetricOrdersLookup =
    state.companyFinDataTableMetricOrders.companyFinDataTableMetricOrdersLookup;
  const companiesLookup = state.companies.companiesLookup;
  const returnObj = {};
  Object.values(companyFinDataTableMetricOrdersLookup).forEach((order) => {
    if (!returnObj[order.companyId]) {
      returnObj[order.companyId] = companiesLookup[order.companyId];
    }
  });
  return returnObj;
};
