import moment from "moment-timezone";
import {
  // Metric Types
  METRIC_TYPE_COMPUTED,
  METRIC_TYPE_API,

  // API Metrics
  METRIC_API_COMMON_STOCK_SHARE_PRICE,

  // Computed Metrics
  METRIC_COMPUTED_EQUITY_MARKET_CAP_NOT_DILUTED,
  METRIC_COMPUTED_EQUITY_MARKET_CAP_FULLY_DILUTED,
  METRIC_COMPUTED_FULLY_DILUTED_SHARES_OUTSTANDING,
  METRIC_COMPUTED_OPTIONS_EXERCISABLE_DILUTION,
} from "../constants";

import { formatMetric } from "./formatNumberHelpers";
import { updateFinDataTableForComputedMetric } from "./metricHelpers";

// Classes
import FinDataTableCell from "../classes/FinDataTableCell";

// This function will directly modify the data array
// We will iterate through the rowHeaders array and check to see
// the metricType of each row. If the metricType is 'API' or 'COMPUTED'
// then we will add the appropriate data to the data array.
// In order to do so, we will use two separate switch statements for
// 'API' and 'COMPUTED' metricTypes.
/**
 * @param {FinDataTable} finDataTable
 * @param {Object} selectedCompanyStockPriceData - The stock price data for the selected company
 * @param {Object} selectedCompanyStockPriceHistory - The stock price history for the selected company
 * @param {Object} metricsLookup - The metrics lookup
 * @param {Object} filingsLookup - The filings lookup
 * @param {Function} updateHighlightedCell - The function to update the highlighted cell
 * @param {Function} updateHighlightedSourceCells - The function to update the highlighted source cells
 * @returns {FinDataTable} - The updated finDataTable
 */
export const fillOutApiAndComputedDataForCapTable = (
  finDataTable,
  selectedCompanyStockPriceData,
  selectedCompanyStockPriceHistory,
  metricsLookup,
  filingsLookup,
  updateHighlightedCell,
  updateHighlightedSourceCells
) => {
  for (const rowIdx in finDataTable.rowHeaders) {
    const updatedData = [...finDataTable.data];
    const rowHeader = finDataTable.rowHeaders[rowIdx];

    switch (rowHeader?.metadata?.metricType) {
      // --------------- METRIC API TYPES ---------------
      case METRIC_TYPE_API:
        switch (rowHeader?.metadata?.key) {
          case METRIC_API_COMMON_STOCK_SHARE_PRICE:
            fillOutDataForCommonStockSharePrice(
              finDataTable,
              updatedData,
              selectedCompanyStockPriceData,
              selectedCompanyStockPriceHistory,
              rowIdx,
              rowHeader,
              metricsLookup,
              filingsLookup
            );
            finDataTable.data = updatedData;
            break;

          default:
            break;
        }
        break;

      // --------------- METRIC COMPUTED TYPES ---------------
      case METRIC_TYPE_COMPUTED:
        switch (rowHeader?.metadata?.key) {
          case METRIC_COMPUTED_EQUITY_MARKET_CAP_NOT_DILUTED:
          case METRIC_COMPUTED_OPTIONS_EXERCISABLE_DILUTION:
          case METRIC_COMPUTED_EQUITY_MARKET_CAP_FULLY_DILUTED:
          case METRIC_COMPUTED_FULLY_DILUTED_SHARES_OUTSTANDING:
            updateFinDataTableForComputedMetric(
              finDataTable,
              updatedData,
              selectedCompanyStockPriceData,
              selectedCompanyStockPriceHistory,
              rowIdx,
              rowHeader,
              metricsLookup,
              filingsLookup,
              updateHighlightedCell,
              updateHighlightedSourceCells
            );
            finDataTable.data = updatedData;
            break;
          default:
            break;
        }

        break;

      default:
        break;
    }
  }

  return finDataTable;
};

// --------------------- Fill out data for API metrics ---------------------

function fillOutDataForCommonStockSharePrice(
  finDataTable,
  updatedData,
  selectedCompanyStockPriceData,
  selectedCompanyStockPriceHistory,
  rowIdx,
  rowHeader,
  metricsLookup,
  filingsLookup
) {
  const metric = metricsLookup[rowHeader?.metadata?.metricId];
  const filingsIds = finDataTable.filingsIds;
  let filingsIdsArray = Array.from(filingsIds);
  filingsIdsArray.sort((a, b) => {
    return moment(filingsLookup[a].filingDate).diff(
      moment(filingsLookup[b].filingDate)
    );
  });
  const mostRecentFilingDate =
    filingsLookup[filingsIdsArray[filingsIdsArray.length - 1]].filingDate;
  const lastSalePriceDate = selectedCompanyStockPriceData?.lastSaleTime;
  const differenceInDaysBetweenLastSalePriceDateAndMostRecentFilingDate =
    moment(lastSalePriceDate).diff(mostRecentFilingDate, "days");

  finDataTable.columnHeaders.forEach((columnHeader, columnIdx) => {
    const isQ4 = columnHeader.value.includes("Q4");
    const tenQOrKBasedOnIsQ4 = isQ4 ? "10-K" : "10-Q";
    // For the last column, we will simply utilize the
    // selectedCompanyStockPriceData (which is
    // the price data as of today), to fill out the column
    if (columnIdx === finDataTable.columnHeaders.length - 1) {
      updatedData[rowIdx][columnIdx] = new FinDataTableCell(
        formatMetric(
          selectedCompanyStockPriceData?.lastSalePrice,
          metric.valueType
        )
      );
      finDataTable.addFootnote(
        0,
        columnIdx + 1,
        `Share price as of ${moment(lastSalePriceDate)
          .tz("America/New_York")
          .format(
            "YYYY-MM-DD hh:mm:ss A"
          )} EST. ${tenQOrKBasedOnIsQ4} data filed on ${moment(
          mostRecentFilingDate
        ).format("YYYY-MM-DD")}. Share price is ${moment(
          lastSalePriceDate
        ).diff(
          moment(mostRecentFilingDate),
          "days"
        )} days after ${tenQOrKBasedOnIsQ4} filing.`
      );
      return;
    }

    const filingId = filingsIdsArray[columnIdx];
    const filing = filingsLookup[filingId];
    const filingFilingDate = filing?.filingDate;

    let targetDate = moment(filingFilingDate).add(
      Math.min(
        80,
        differenceInDaysBetweenLastSalePriceDateAndMostRecentFilingDate
      ),
      "days"
    );
    let foundStockPrice = null;

    // Generate the modified date range array
    let dateRange = [];
    // Add the target date to the range
    dateRange.push(targetDate.format("YYYY-MM-DD"));
    for (let i = 1; i <= 7; i++) {
      dateRange.push(
        targetDate.clone().subtract(i, "days").format("YYYY-MM-DD")
      ); // days before targetDate
      dateRange.push(targetDate.clone().add(i, "days").format("YYYY-MM-DD")); // days after targetDate
    }

    // Add additional days before the target date, up to 90 days before
    for (let i = 8; i <= 90; i++) {
      dateRange.push(
        targetDate.clone().subtract(i, "days").format("YYYY-MM-DD")
      ); // days before targetDate
    }

    // Find stock price within the modified date range
    for (let i = 0; i < dateRange.length; i++) {
      if (selectedCompanyStockPriceHistory[dateRange[i]]) {
        foundStockPrice = selectedCompanyStockPriceHistory[dateRange[i]];
        break;
      }
    }

    if (foundStockPrice) {
      // Push the found stock price or "N/A" if not found
      updatedData[rowIdx][columnIdx] = new FinDataTableCell(
        formatMetric(foundStockPrice?.close, metric.valueType)
      );
      finDataTable.addFootnote(
        0,
        columnIdx + 1,
        `Share price as of ${moment(foundStockPrice?.date).format(
          "YYYY-MM-DD"
        )} 04:00:00 PM EST. ${tenQOrKBasedOnIsQ4} data filed on ${moment(
          filingFilingDate
        ).format("YYYY-MM-DD")}. Share price is ${moment(
          foundStockPrice?.date
        ).diff(
          moment(filingFilingDate),
          "days"
        )} days after ${tenQOrKBasedOnIsQ4} filing.`
      );
    }
  });
}
