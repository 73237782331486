import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import ButtonWithCircularProgress from "../ButtonWithCircularProgress";

import { createMetricFinDataTableAction } from "../../actions/metricFinDataTablesActions";

import FinDataSelectInput from "../FinDataSelectInput";
import FinDataExpand from "../FinDataExpand";

const useStyles = makeStyles((theme) => ({
  createNewMetricFinDataTable: {
    backgroundColor: "white",
    padding: "8px",
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  subTitle: {
    fontWeight: "bold",
  },
}));

function CreateNewMetricFinDataTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Is Saving New MetricFinDataTable
  const [isSaving, setIsSaving] = useState(false);

  // State variables used for creating a new MetricFinDataTable
  const [showExpandedView, setShowExpandedView] = useState(false);

  const [selectedMetricId, setSelectedMetricId] = useState("");
  const [selectedFinDataTableId, setSelectedFinDataTableId] = useState("");

  const metricsLookup = useSelector((state) => state.metrics.metricsLookup);
  const finDataTablesLookup = useSelector(
    (state) => state.finDataTables.finDataTablesLookup
  );

  const handleCreateNewMetricFinDataTable = async () => {
    setIsSaving(true);

    const createMetricFinDataTableInput = {
      metricId: selectedMetricId,
      finDataTableId: selectedFinDataTableId,
    };

    try {
      await createMetricFinDataTableAction(
        dispatch,
        createMetricFinDataTableInput
      );
      setIsSaving(false);
      setShowExpandedView(false);
      setSelectedMetricId("");
    } catch (error) {
      setIsSaving(false);
    }
  };

  return (
    <div className={classes.createNewMetricFinDataTable}>
      <FinDataExpand
        title="Create MetricFinDataTable (Optional)"
        isExpandedView={showExpandedView}
        setIsExpandedView={setShowExpandedView}
      />

      {showExpandedView && (
        <>
          <FinDataSelectInput
            lookupObject={metricsLookup}
            id="metric"
            selectedId={selectedMetricId}
            setSelectedId={setSelectedMetricId}
            inputLabel="metricId"
            style={{ marginTop: "12px", minWidth: 120, width: "100%" }}
            renderMenuItem={(metric) => {
              return metric.metric;
            }}
          />
          <FinDataSelectInput
            lookupObject={finDataTablesLookup}
            id="finDatableTable"
            selectedId={selectedFinDataTableId}
            setSelectedId={setSelectedFinDataTableId}
            inputLabel="finDataTableId"
            style={{ marginTop: "12px", minWidth: 120, width: "100%" }}
            renderMenuItem={(finDataTable) => {
              return finDataTable.key;
            }}
          />

          <div style={{ marginTop: "12px" }}>
            <ButtonWithCircularProgress
              onClick={handleCreateNewMetricFinDataTable}
              disabled={!selectedMetricId || !selectedFinDataTableId}
              isSaving={isSaving}
              buttonText="Create New MetricFinDataTable"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default CreateNewMetricFinDataTable;
