class FinDataTableHighlightedCell {
  /**
   * @param {string} value
   * @param {string} cellAddress
   * @param {Object} metadata
   * @param {boolean} isSourceCell
   * @param {string} color - hex color
   */
  constructor(
    value,
    cellAddress,
    metadata,
    isSourceCell = false,
    color = null
  ) {
    this.value = value;
    this.cellAddress = cellAddress;
    this.metadata = metadata;
    this.isSourceCell = isSourceCell;
    this.color = color;
  }
}

export default FinDataTableHighlightedCell;
