import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { HashLink as Link } from "react-router-hash-link";
import routes from "../../routes";
import { determineFiscalQuarterFromQuarterEndDate } from "../../helpers/dateHelpers";
import { FILINGS_BUCKET_DEV, FILINGS_BUCKET_PROD } from "../../constants";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  // Add styles for the CircularProgress here if needed
});

function ViewFiling(props) {
  const classes = useStyles();
  const filing = props.filing;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const companiesLookup = useSelector(
    (state) => state.companies.companiesLookup
  );
  const company = companiesLookup[filing.companyId];
  const [isLoading, setIsLoading] = useState(true);
  const [filingContent, setFilingContent] = useState("");

  let [fiscalQuarter, fiscalYear] = determineFiscalQuarterFromQuarterEndDate(
    filing.reportDate,
    company.fiscalYearEnd
  );

  useEffect(() => {
    const fetchFiling = async () => {
      const key = `${filing.S3Key}`;
      const response = await fetch(
        `https://${
          process.env.NODE_ENV !== "production"
            ? FILINGS_BUCKET_DEV
            : FILINGS_BUCKET_PROD
        }.s3.us-east-1.amazonaws.com/${key}`,
        {
          headers: {
            "Content-Security-Policy":
              "default-src 'none'; img-src 'self'; script-src 'self'; style-src 'self'; frame-ancestors 'none'; base-uri 'none'; form-action 'none'",
          },
        }
      );

      let data = await response.text();

      // Remove the <TYPE>, <SEQUENCE>, <FILENAME>, and <DESCRIPTION> lines
      data = data.replace(/<(TYPE|SEQUENCE|FILENAME|DESCRIPTION)>[^<]+\s/g, "");

      setFilingContent(data);
      setIsLoading(false);
    };

    fetchFiling();
  }, [filing]);

  return (
    <div key={filing.id}>
      {searchParams.has("source") &&
        searchParams.get("source") === "filingsLink" && (
          <Link to={routes.SEC_FILINGS} className={classes.linkStyle}>
            Back to all filings
          </Link>
        )}
      {searchParams.has("source") &&
        searchParams.get("source") === "datumLink" && (
          <Link to={routes.SEC_FILING_DATA} className={classes.linkStyle}>
            Back to SEC filings data
          </Link>
        )}
      <h3>
        {`${filing.symbol} - ${
          company.registrantName
        } - Q${fiscalQuarter} ${fiscalYear} ${filing.form} - ${moment(
          filing.filingDate
        ).format("YYYY-MM-DD")} - Fiscal Year Ends ${company.fiscalYearEnd}`}
      </h3>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: filingContent }} />
      )}
    </div>
  );
}

export default ViewFiling;
