import gql from "graphql-tag";
import {
  CompanyFragment,
  CompanyFinDataTableMetricOrderFragment,
  DatumFragment,
  DatumArchiveFragment,
  EarningsReleaseDateFragment,
  EarningsTranscriptFragment,
  FinancialStatementFragment,
  FinancialStatementTypeFragment,
  FinDataTableFragment,
  MetricFragment,
  MetricFinDataTableFragment,
  TenQOrKFragment,
  XBRLTagFragment,
  XBRLTagTypeFragment,
  XSDFileFragment,
} from "./fragments";

// ---------------- LIST ALL QUERIES ----------

export const listCompanysQuery = /* GraphQL */ gql`
  ${CompanyFragment}
  query LIST_COMPANIES(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...CompanyFragment
      }
      nextToken
    }
  }
`;

export const listCompanyFinDataTableMetricOrdersQuery = /* GraphQL */ gql`
  ${CompanyFinDataTableMetricOrderFragment}
  query LIST_COMPANY_FIN_DATA_TABLE_METRIC_ORDERS(
    $filter: ModelCompanyFinDataTableMetricOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyFinDataTableMetricOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...CompanyFinDataTableMetricOrderFragment
      }
      nextToken
    }
  }
`;

export const listDataQuery =
  /* GraphQL */
  gql`
    ${DatumFragment}
    query ListData(
      $filter: ModelDatumFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listData(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...DatumFragment
        }
        nextToken
      }
    }
  `;

export const listDatumArchivesQuery = /* GraphQL */ gql`
  ${DatumArchiveFragment}
  query LIST_EARNINGS_RELEASE_DATES(
    $filter: ModelDatumArchiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatumArchives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...DatumArchiveFragment
      }
      nextToken
    }
  }
`;

export const listEarningsReleaseDatesQuery = /* GraphQL */ gql`
  ${EarningsReleaseDateFragment}
  query LIST_EARNINGS_RELEASE_DATES(
    $filter: ModelEarningsReleaseDateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEarningsReleaseDates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...EarningsReleaseDateFragment
      }
      nextToken
    }
  }
`;

export const listEarningsTranscriptsQuery =
  /* GraphQL */
  gql`
    ${EarningsTranscriptFragment}
    query ListEarningsTranscripts(
      $filter: ModelEarningsTranscriptFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEarningsTranscripts(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...EarningsTranscriptFragment
        }
        nextToken
      }
    }
  `;

export const listFinancialStatementsQuery = gql`
  ${FinancialStatementFragment}
  query LIST_FINANCIAL_STATEMENTS(
    $filter: ModelFinancialStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinancialStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...FinancialStatementFragment
      }
      nextToken
    }
  }
`;

export const listFinancialStatementTypesQuery = gql`
  ${FinancialStatementTypeFragment}
  query LIST_FINANCIAL_STATEMENT_TYPES(
    $filter: ModelFinancialStatementTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinancialStatementTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...FinancialStatementTypeFragment
      }
      nextToken
    }
  }
`;

export const listFinDataTablesQuery = gql`
  ${FinDataTableFragment}
  query LIST_FinDataTableS(
    $filter: ModelFinDataTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinDataTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...FinDataTableFragment
      }
      nextToken
    }
  }
`;

export const listMetricsQuery = gql`
  ${MetricFragment}
  query LIST_METRICS(
    $filter: ModelMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...MetricFragment
      }
      nextToken
    }
  }
`;

export const listMetricFinDataTablesQuery = gql`
  ${MetricFinDataTableFragment}
  query LIST_MetricFinDataTableS(
    $filter: ModelMetricFinDataTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetricFinDataTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...MetricFinDataTableFragment
      }
      nextToken
    }
  }
`;

export const listTenQOrKsQuery = gql`
  ${TenQOrKFragment}
  query LIST_TENQORKS(
    $filter: ModelTenQOrKFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenQOrKS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...TenQOrKFragment
      }
      nextToken
    }
  }
`;

export const listXbrlTagsQuery = gql`
  ${XBRLTagFragment}
  query LIST_XBRL_TAGS(
    $filter: ModelXBRLTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listXBRLTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...XBRLTagFragment
      }
      nextToken
    }
  }
`;

export const listXbrlTagTypesQuery = gql`
  ${XBRLTagTypeFragment}
  query LIST_XBRL_TAG_TYPES(
    $filter: ModelXBRLTagTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listXBRLTagTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...XBRLTagTypeFragment
      }
      nextToken
    }
  }
`;

export const listXsdFilesQuery = gql`
  ${XSDFileFragment}
  query LIST_XBRL_TAG_TYPES(
    $filter: ModelXSDFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listXSDFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...XSDFileFragment
      }
      nextToken
    }
  }
`;

// ---------------- LIST BY GSI QUERIES ----------

export const listDataByEarningsTranscript = gql`
  ${DatumFragment}
  query ListDataByEarningsTranscript(
    $earningsTranscriptId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listDataByEarningsTranscript(
      earningsTranscriptId: $earningsTranscriptId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...DatumFragment
      }
      nextToken
    }
  }
`;

export const listDataByCompany = gql`
  ${DatumFragment}
  query ListDataByCompany($companyId: ID!, $limit: Int, $nextToken: String) {
    listDataByCompany(
      companyId: $companyId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...DatumFragment
      }
      nextToken
    }
  }
`;

export const listEarningsReleaseDatesByCompany = gql`
  ${EarningsReleaseDateFragment}
  query listEarningsReleaseDatesByCompany(
    $companyId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listEarningsReleaseDatesByCompany(
      companyId: $companyId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...EarningsReleaseDateFragment
      }
      nextToken
    }
  }
`;

export const listDataByMetric = gql`
  ${DatumFragment}
  query ListDataByMetric($metricId: ID!, $limit: Int, $nextToken: String) {
    listDataByMetric(
      metricId: $metricId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...DatumFragment
      }
      nextToken
    }
  }
`;

export const listEarningsTranscriptsByCompany = gql`
  ${EarningsTranscriptFragment}
  query ListEarningsTranscriptsByCompany(
    $companyId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listEarningsTranscriptsByCompany(
      companyId: $companyId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...EarningsTranscriptFragment
      }
      nextToken
    }
  }
`;

// --------------- GET QUERIES -----------

export const getCompanyQuery = gql`
  ${CompanyFragment}
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      ...CompanyFragment
    }
  }
`;

export const getCompanyFinDataTableMetricOrderQuery = gql`
  ${CompanyFinDataTableMetricOrderFragment}
  query GetCompanyFinDataTableMetricOrder($id: ID!) {
    getCompanyFinDataTableMetricOrder(id: $id) {
      ...CompanyFinDataTableMetricOrderFragment
    }
  }
`;

export const getDatumQuery = gql`
  ${DatumFragment}
  query GetDatum($id: ID!) {
    getDatum(id: $id) {
      ...DatumFragment
    }
  }
`;

export const getDatumArchiveQuery = gql`
  ${DatumArchiveFragment}
  query GetDatumArchive($id: ID!) {
    getDatumArchive(id: $id) {
      ...DatumArchiveFragment
    }
  }
`;

export const getEarningsReleaseDateQuery = gql`
  ${EarningsReleaseDateFragment}
  query GetEarningsReleaseDate($id: ID!) {
    getEarningsReleaseDate(id: $id) {
      ...EarningsReleaseDateFragment
    }
  }
`;

export const getEarningsTranscriptQuery = gql`
  ${EarningsTranscriptFragment}
  query GetEarningsTranscript($id: ID!) {
    getEarningsTranscript(id: $id) {
      ...EarningsTranscriptFragment
    }
  }
`;

export const getFinancialStatementQuery = gql`
  ${FinancialStatementFragment}
  query GetFinancialStatement($id: ID!) {
    getFinancialStatement(id: $id) {
      ...FinancialStatementFragment
    }
  }
`;

export const getFinancialStatementTypeQuery = gql`
  ${FinancialStatementTypeFragment}
  query GetFinancialStatementType($id: ID!) {
    getFinancialStatementType(id: $id) {
      ...FinancialStatementTypeFragment
    }
  }
`;

export const getFinDataTableQuery = gql`
  ${FinDataTableFragment}
  query GetFinDataTable($id: ID!) {
    getFinDataTable(id: $id) {
      ...FinDataTableFragment
    }
  }
`;

export const getMetricQuery = gql`
  ${MetricFragment}
  query GetMetric($id: ID!) {
    getMetric(id: $id) {
      ...MetricFragment
    }
  }
`;

export const getMetricFinDataTableQuery = gql`
  ${MetricFinDataTableFragment}
  query GetMetricFinDataTable($id: ID!) {
    getMetricFinDataTable(id: $id) {
      ...MetricFinDataTableFragment
    }
  }
`;

export const getTenQOrKQuery = gql`
  ${TenQOrKFragment}
  query GetTenQOrK($id: ID!) {
    getTenQOrK(id: $id) {
      ...TenQOrKFragment
    }
  }
`;

export const getXbrlTagQuery = gql`
  ${XBRLTagFragment}
  query GetXBRLTag($id: ID!) {
    getXBRLTag(id: $id) {
      ...XBRLTagFragment
    }
  }
`;

export const getXbrlTagTypeQuery = gql`
  ${XBRLTagTypeFragment}
  query GetXBRLTagType($id: ID!) {
    getXBRLTagType(id: $id) {
      ...XBRLTagTypeFragment
    }
  }
`;

export const getXsdFileQuery = gql`
  ${XSDFileFragment}
  query GetXSDFile($id: ID!) {
    getXSDFile(id: $id) {
      ...XSDFileFragment
    }
  }
`;
