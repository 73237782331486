import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  selectHumanReviewedConfidentlyExtractedSecFilingsDataForCompany,
  selectCompaniesWithAtLeastOneCompanyFinDataTableMetricOrder,
} from "../../store/selectors";
import { FIN_DATA_TABLE_DISPLAY_ORDER } from "../../constants";
import {
  getStockPriceDataForCompany,
  getStockPriceHistoryForCompany,
} from "../../gql/gqlHelperQueriesLayer";
import CircularProgress from "@material-ui/core/CircularProgress";

// Sub-components
import FinDataTableForCompany from "./FinDataTableForCompany";
import FinDataSelectInput from "../FinDataSelectInput";

const useStyles = makeStyles({ dataContainer: { paddingBottom: 10 } });

function SecFilingData(props) {
  const filteredCompaniesLookup = useSelector(
    selectCompaniesWithAtLeastOneCompanyFinDataTableMetricOrder
  );

  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedCompanyStockPriceData, setSelectedCompanyStockPriceData] =
    useState(null);
  const [
    selectedCompanyStockPriceHistory,
    setSelectedCompanyStockPriceHistory,
  ] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Separate useEffect for initializing selectedCompanyId
  useEffect(() => {
    if (!selectedCompanyId && Object.keys(filteredCompaniesLookup).length > 0) {
      const firstCompanyId = Object.values(filteredCompaniesLookup)[0].id;
      setSelectedCompanyId(firstCompanyId);
    }
  }, [filteredCompaniesLookup, selectedCompanyId]);

  // Separate useEffect for fetching data
  useEffect(() => {
    async function fetchData(companyId) {
      if (!companyId) return;

      setIsLoading(true);
      const symbol = filteredCompaniesLookup[companyId].symbol;

      try {
        const stockPriceData = await getStockPriceDataForCompany(symbol);

        setSelectedCompanyStockPriceData(stockPriceData);

        const stockPriceHistoryData = await getStockPriceHistoryForCompany(
          symbol
        );
        if (stockPriceHistoryData && stockPriceHistoryData.response) {
          setSelectedCompanyStockPriceHistory(
            JSON.parse(stockPriceHistoryData.response)
          );
        } else {
          setSelectedCompanyStockPriceHistory(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData(selectedCompanyId);
  }, [selectedCompanyId, filteredCompaniesLookup]);

  const humanReviewedConfidentlyExtractedSecFilingsDataForCompany = useSelector(
    (state) =>
      selectHumanReviewedConfidentlyExtractedSecFilingsDataForCompany(
        state,
        selectedCompanyId
      )
  );
  const finDataTablesLookup = useSelector(
    (state) => state.finDataTables.finDataTablesLookup
  );

  const finDataTablesOrdered = FIN_DATA_TABLE_DISPLAY_ORDER.map((table_key) => {
    const finDataTable = Object.values(finDataTablesLookup).find((value) => {
      return value.key === table_key;
    });
    return finDataTable;
  });

  const renderFinDataTable = (finDataTable) => {
    return (
      <div style={{ paddingBottom: "12px" }}>
        <FinDataTableForCompany
          finDataTable={finDataTable}
          selectedCompanyId={selectedCompanyId}
          selectedCompanyStockPriceData={selectedCompanyStockPriceData}
          selectedCompanyStockPriceHistory={selectedCompanyStockPriceHistory}
        />
      </div>
    );
  };

  return (
    <div>
      <div style={{ fontWeight: "bold" }}>Select a company:</div>
      <div style={{ marginTop: "4px", marginBottom: "24px" }}>
        <FinDataSelectInput
          id="selectedCompany"
          lookupObject={filteredCompaniesLookup}
          renderMenuItem={(company) => {
            return `${company.symbol} - ${company.registrantName}`;
          }}
          selectedId={selectedCompanyId}
          setSelectedId={setSelectedCompanyId}
        />
      </div>

      <div>
        {isLoading ? (
          <CircularProgress />
        ) : (
          finDataTablesOrdered.map((table) => {
            return <div key={table}>{renderFinDataTable(table)}</div>;
          })
        )}
      </div>
    </div>
  );
}

export default SecFilingData;
