import actionTypes from "../actions/actionTypes";

const initialState = {
  datumsLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setDatums(state, datumsArray) {
  return {
    ...state,
    datumsLookup: datumsArray.reduce((accum, currValue) => {
      accum[currValue.id] = currValue;
      return accum;
    }, {}),
  };
}

function submitHumanReviewOfDatum(state, updatedDatum) {
  let updatedState = {
    ...state,
    datumsLookup: {
      ...state.datumsLookup,
      [updatedDatum.id]: updatedDatum,
    },
  };

  return updatedState;
}

function createDatum(state, createdDatum) {
  let updatedState = {
    ...state,
    datumsLookup: {
      ...state.datumsLookup,
      [createdDatum.id]: createdDatum,
    },
  };

  return updatedState;
}

// -----------------------------------------------------
// Reducer

function transcriptsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_DATUMS:
      return setDatums(state, action.payload);
    case actionTypes.UPDATE_DATUM:
      return submitHumanReviewOfDatum(state, action.payload);
    case actionTypes.CREATE_DATUM:
      return createDatum(state, action.payload);
    default:
      return state;
  }
}

export default transcriptsReducer;
