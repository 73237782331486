import actionTypes from "../actions/actionTypes";

const initialState = {
  xbrlTagTypesLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setXBRLTagTypes(state, xbrlTagTypesArray) {
  const xbrlTagTypesLookup = xbrlTagTypesArray.reduce((accum, currValue) => {
    accum[currValue.id] = currValue;
    return accum;
  }, {});
  return { ...state, xbrlTagTypesLookup };
}

// -----------------------------------------------------
// Reducer

function xbrlTagTypesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_XBRL_TAG_TYPES:
      return setXBRLTagTypes(state, action.payload);

    default:
      return state;
  }
}

export default xbrlTagTypesReducer;
