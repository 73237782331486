import {
  TIME_PERIOD_INTERVAL_MONTH_TO_DATE_DB_ENUM,
  TIME_PERIOD_INTERVAL_QUARTER_TO_DATE_DB_ENUM,
  TIME_PERIOD_INTERVAL_YEAR_TO_DATE_DB_ENUM,
  MAP_TIME_PERIOD_INTERVAL_DB_ENUM_TO_PLAIN_ENGLISH,
  TIME_PERIOD_INTERVAL_QUARTERS_DB_ENUM,
  TIME_PERIOD_INTERVAL_MONTHS_DB_ENUM,
  TIME_PERIOD_INTERVAL_YEARS_DB_ENUM,
} from "../constants";
import { determineFiscalQuarterFromQuarterEndDate } from "./dateHelpers";
import moment from "moment";
import { getInnerContextXmlIdForMultipleDatums } from "../gql/gqlHelperQueriesLayer";

// ------------------------------------ DATUM HELPERS ------------------------------------

export const determineTimePeriodLanguageForDatum = (
  datum,
  earningsTranscript,
  company
) => {
  const timePeriodInterval = datum.timePeriodInterval;
  const timePeriodValue = datum.timePeriodValue;
  const timePeriodEndDate = datum.timePeriodEndDate;

  if (datum.isSnapshot) {
    return `as of ${timePeriodEndDate}`;
  }

  if (
    timePeriodInterval !== TIME_PERIOD_INTERVAL_MONTH_TO_DATE_DB_ENUM &&
    timePeriodInterval !== TIME_PERIOD_INTERVAL_QUARTER_TO_DATE_DB_ENUM &&
    timePeriodInterval !== TIME_PERIOD_INTERVAL_YEAR_TO_DATE_DB_ENUM
  ) {
    if (timePeriodInterval === TIME_PERIOD_INTERVAL_QUARTERS_DB_ENUM) {
      if (timePeriodValue === 1) {
        const transcriptMetadata = `${earningsTranscript.symbol} - ${
          company.registrantName
        } - Q${earningsTranscript.quarter} ${
          earningsTranscript.year
        } earnings transcript - ${moment(earningsTranscript.date).format(
          "YYYY-MM-DD"
        )} - Fiscal Year Ends ${company.fiscalYearEnd}`;
        const arrayOfTranscriptMetadata = transcriptMetadata.split(" - ");
        const fiscalYearEndDateInfo =
          arrayOfTranscriptMetadata[arrayOfTranscriptMetadata.length - 1];
        const fiscalYearEndDateInfoArray = fiscalYearEndDateInfo.split(" ");
        const fiscalYearEndDate =
          fiscalYearEndDateInfoArray[fiscalYearEndDateInfoArray.length - 1];

        const [fiscalQuarter, fiscalYear] =
          determineFiscalQuarterFromQuarterEndDate(
            timePeriodEndDate,
            fiscalYearEndDate
          );

        return `for Q${fiscalQuarter} ${fiscalYear}`;
      }
    } else if (timePeriodInterval === TIME_PERIOD_INTERVAL_MONTHS_DB_ENUM) {
      if (timePeriodValue === 1) {
        return `for ${moment(timePeriodEndDate).format("MMMM YYYY")}`;
      }
    } else if (timePeriodInterval === TIME_PERIOD_INTERVAL_YEARS_DB_ENUM) {
      if (timePeriodValue === 1) {
        return `for ${moment(timePeriodEndDate).format("YYYY")}`;
      }
    }
  }

  return `for the${timePeriodValue ? ` ${timePeriodValue} ` : " "}${
    MAP_TIME_PERIOD_INTERVAL_DB_ENUM_TO_PLAIN_ENGLISH[timePeriodInterval]
  } ending ${timePeriodEndDate}`;
};

export const updateReactRouterHashLinkMetasInFinDataTable = async (
  finDataTable
) => {
  // Collect all datumIds that need to be updated.
  let datumIdsArray = [];
  let cellReferencesArray = [];

  for (let rowIndex = 0; rowIndex < finDataTable.data.length; rowIndex++) {
    for (
      let colIndex = 0;
      colIndex < finDataTable.data[rowIndex].length;
      colIndex++
    ) {
      let cell = finDataTable.data[rowIndex][colIndex];
      if (
        cell.reactRouterHashLinkMeta &&
        cell.reactRouterHashLinkMeta.to &&
        cell.reactRouterHashLinkMeta.to.hash &&
        cell.metadata &&
        cell.metadata.datumId
      ) {
        datumIdsArray.push(cell.metadata.datumId);
        cellReferencesArray.push({ rowIndex, colIndex }); // Store references to cells for later update.
      }
    }
  }

  // Get the xmlIds for all datumIdsArray in a single batch request.
  const getInnerContextXmlIdForMultipleDatumsResponse =
    await getInnerContextXmlIdForMultipleDatums(datumIdsArray);

  // Update the finDataTable cells based on the response.
  getInnerContextXmlIdForMultipleDatumsResponse.innerContextXmlIds.forEach(
    (xmlId, index) => {
      const { rowIndex, colIndex } = cellReferencesArray[index];
      let cell = finDataTable.data[rowIndex][colIndex];
      if (cell.reactRouterHashLinkMeta && cell.reactRouterHashLinkMeta.to) {
        cell.reactRouterHashLinkMeta.to.hash = xmlId;
      }
    }
  );

  return finDataTable;
};
