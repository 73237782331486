import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import { COGNITO_USER_GROUP_ADMIN } from "../../constants";
import FinDataSelectInput from "../FinDataSelectInput";
import {
  createCompanyFinDataTableMetricOrdersLookupForCompany,
  selectMetricsInUseForCompany,
} from "../../store/selectors";
import { FIN_DATA_TABLE_DISPLAY_ORDER } from "../../constants";

// Sub-components
import ManageCompanyFinDataTableMetricOrders from "./ManageCompanyFinDataTableMetricOrders";
import ViewCompanyMetrics from "./ViewCompanyMetrics";
import ViewCompanyMetricsForFinDataTable from "./ViewCompanyMetricsForFinDataTable";
import CreateNewCompanyFinDataTableMetricOrder from "../SimpleDynamoDBForms/CreateNewCompanyFinDataTableMetricOrder";
import UpdateCompanyFinDataTableMetricOrder from "../SimpleDynamoDBForms/UpdateCompanyFinDataTableMetricOrder";

const useStyles = makeStyles({});

function CompanyMetricReview(props) {
  const classes = useStyles();
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );
  const history = useHistory();

  // Lookups
  const companiesLookup = useSelector(
    (state) => state.companies.companiesLookup
  );
  const finDataTablesLookup = useSelector(
    (state) => state.finDataTables.finDataTablesLookup
  );

  // State variables
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  // Sub-lookups
  const companyFinDataTableMetricOrdersLookupForSelectedCompany = useSelector(
    (state) => {
      return createCompanyFinDataTableMetricOrdersLookupForCompany(
        state,
        selectedCompanyId
      );
    }
  );

  const metricsInUseForCompanyLookup = useSelector((state) => {
    return selectMetricsInUseForCompany(state, selectedCompanyId);
  });

  useEffect(() => {
    if (
      !(
        authenticatedUser &&
        authenticatedUser.groups &&
        Array.isArray(authenticatedUser.groups) &&
        authenticatedUser.groups.length > 0 &&
        authenticatedUser.groups.includes(COGNITO_USER_GROUP_ADMIN)
      )
    ) {
      history.push(routes.HOME);
      return;
    }
  }, [authenticatedUser, history]);

  return (
    <div>
      <div style={{ marginBottom: "24px" }}>
        <FinDataSelectInput
          inputLabel="Company"
          id="selectedCompany"
          lookupObject={companiesLookup}
          renderMenuItem={(company) => {
            return `${company.symbol} - ${company.registrantName}`;
          }}
          selectedId={selectedCompanyId}
          setSelectedId={setSelectedCompanyId}
          style={{ marginTop: "12px", minWidth: 120 }}
        />
      </div>
      {selectedCompanyId && (
        <div>
          <div style={{ marginBottom: "36px" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                marginBottom: "15px",
              }}
            >
              {"COMPANY_LEVEL"}
            </div>
            <div
              style={{
                marginTop: "24px",
              }}
            >
              <div
                style={{
                  textDecoration: "underline",
                  marginBottom: "4px",
                }}
              >
                View Company SEC Filing Metrics
              </div>
              <ViewCompanyMetrics
                metricsInUseForCompanyLookup={metricsInUseForCompanyLookup}
              />
            </div>
            <CreateNewCompanyFinDataTableMetricOrder
              companyId={selectedCompanyId}
            />
            <UpdateCompanyFinDataTableMetricOrder
              companyId={selectedCompanyId}
            />
          </div>
          <div>
            {FIN_DATA_TABLE_DISPLAY_ORDER.map((finDataTableKey) => {
              return (
                <div style={{ marginBottom: "36px" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "15px",
                    }}
                  >
                    {finDataTableKey}
                  </div>
                  <div
                    style={{
                      marginTop: "24px",
                    }}
                  >
                    <div
                      style={{
                        textDecoration: "underline",
                        marginBottom: "4px",
                      }}
                    >
                      Manage CompanyFinDataTableMetricOrders
                    </div>
                    <ManageCompanyFinDataTableMetricOrders
                      finDataTableKey={finDataTableKey}
                      companyFinDataTableMetricOrdersLookupForSelectedCompany={
                        companyFinDataTableMetricOrdersLookupForSelectedCompany
                      }
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "24px",
                    }}
                  >
                    <div
                      style={{
                        textDecoration: "underline",
                        marginBottom: "4px",
                      }}
                    >
                      View Company Metrics for FinDataTable
                    </div>
                    <ViewCompanyMetricsForFinDataTable
                      finDataTableKey={finDataTableKey}
                      finDataTablesLookup={finDataTablesLookup}
                      metricsInUseForCompanyLookup={
                        metricsInUseForCompanyLookup
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default CompanyMetricReview;
