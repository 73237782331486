import React from "react";

const TableHeader = ({
  headerGroups,
  columnWidthsState,
  startResizingColumn,
  handleColumnDoubleClick,
  classes,
  rowHeightsState,
  startResizingRow,
  handleHeaderRowDoubleClick,
  defaultColumnWidth,
  firstRowHeight,
}) => {
  return (
    <thead>
      {headerGroups.map((headerGroup, headerGroupIndex) => (
        <tr
          {...headerGroup.getHeaderGroupProps()}
          key={headerGroupIndex}
          style={{
            height: `${rowHeightsState[0] || firstRowHeight}px`,
          }}
        >
          {headerGroup.headers.map((column, columnIndex) => (
            <th
              className={`${classes.resizableTh} ${classes.nonBoldHeader}`}
              {...column.getHeaderProps()}
              style={{
                width: `${
                  columnWidthsState[columnIndex] || defaultColumnWidth
                }px`,
                paddingRight: "4px",
                paddingLeft: "4px",
                textDecoration: "underline",
                height: `${firstRowHeight}px`,
                border: "1px solid black",
                // Remove the top border for the first header cell only
                borderTop:
                  columnIndex === 0 && headerGroupIndex === 0
                    ? "none"
                    : columnIndex > 0 && headerGroupIndex === 0
                    ? "2px solid black"
                    : "1px solid black",
                // Remove the left border for the first header cell only
                borderLeft:
                  columnIndex === 0 && headerGroupIndex === 0
                    ? "none"
                    : "1px solid black",
                // Remove the top border for the first header cell only
                borderRight:
                  columnIndex === headerGroup.headers.length - 1 &&
                  headerGroupIndex === 0
                    ? "2px solid black"
                    : "1px solid black",
              }}
              key={columnIndex}
            >
              {columnIndex !== 0 ? column.render("Header") : null}

              <div
                className={classes.resizeHandle}
                onDoubleClick={() => handleColumnDoubleClick(columnIndex)}
                onMouseDown={(e) =>
                  startResizingColumn(
                    columnIndex,
                    columnWidthsState[columnIndex],
                    e.clientX
                  )
                }
              />

              <div
                className={classes.rowResizeHandle}
                onDoubleClick={() =>
                  handleHeaderRowDoubleClick(headerGroupIndex)
                }
                onMouseDown={(e) =>
                  startResizingRow(
                    headerGroupIndex,
                    rowHeightsState[headerGroupIndex],
                    e.clientY
                  )
                }
              />
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
