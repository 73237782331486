import { COGNITO_USER_GROUP_ADMIN } from "../constants";

export const determineIsUserAdmin = (userGroups) => {
  if (
    userGroups &&
    Array.isArray(userGroups) &&
    userGroups.length > 0 &&
    userGroups.includes(COGNITO_USER_GROUP_ADMIN)
  ) {
    return true;
  } else {
    return false;
  }
};
