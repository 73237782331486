import gql from "graphql-tag";
import {
  CompanyFragment_user,
  CompanyFinDataTableMetricOrderFragment_user,
  DatumFragment_SecFiling_user,
  DatumFragment_EarningsTranscript_user,
  EarningsReleaseDateFragment_user,
  EarningsTranscriptFragment_user,
  FinancialStatementFragment_user,
  FinancialStatementTypeFragment_user,
  FinDataTableFragment_user,
  MetricFragment_user,
  MetricFinDataTableFragment_user,
  TenQOrKFragment_user,
  XBRLTagFragment_user,
  XBRLTagTypeFragment_user,
  XSDFileFragment_user,
} from "./userFragments";

// ---------------- LIST ALL QUERIES ----------

export const listCompanysQuery_user = /* GraphQL */ gql`
  ${CompanyFragment_user}
  query LIST_COMPANIES(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...CompanyFragment_user
      }
      nextToken
    }
  }
`;

export const listCompanyFinDataTableMetricOrdersQuery_user = /* GraphQL */ gql`
  ${CompanyFinDataTableMetricOrderFragment_user}
  query LIST_COMPANY_FIN_DATA_TABLE_METRIC_ORDERS(
    $filter: ModelCompanyFinDataTableMetricOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyFinDataTableMetricOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...CompanyFinDataTableMetricOrderFragment_user
      }
      nextToken
    }
  }
`;

export const listSecFilingDataQuery_user =
  /* GraphQL */
  gql`
    ${DatumFragment_SecFiling_user}
    query ListData(
      $filter: ModelDatumFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listData(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...DatumFragment_SecFiling_user
        }
        nextToken
      }
    }
  `;

export const listEarningsTranscriptDataQuery_user =
  /* GraphQL */
  gql`
    ${DatumFragment_EarningsTranscript_user}
    query ListData(
      $filter: ModelDatumFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listData(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...DatumFragment_EarningsTranscript_user
        }
        nextToken
      }
    }
  `;

// Note: DatumArchives are not queriable by "user" types

export const listEarningsReleaseDatesQuery_user = /* GraphQL */ gql`
  ${EarningsReleaseDateFragment_user}
  query LIST_EARNINGS_RELEASE_DATES(
    $filter: ModelEarningsReleaseDateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEarningsReleaseDates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...EarningsReleaseDateFragment_user
      }
      nextToken
    }
  }
`;

export const listEarningsTranscriptsQuery_user =
  /* GraphQL */
  gql`
    ${EarningsTranscriptFragment_user}
    query ListEarningsTranscripts(
      $filter: ModelEarningsTranscriptFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEarningsTranscripts(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...EarningsTranscriptFragment_user
        }
        nextToken
      }
    }
  `;

export const listFinancialStatementsQuery_user = gql`
  ${FinancialStatementFragment_user}
  query LIST_FINANCIAL_STATEMENTS(
    $filter: ModelFinancialStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinancialStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...FinancialStatementFragment_user
      }
      nextToken
    }
  }
`;

export const listFinancialStatementTypesQuery_user = gql`
  ${FinancialStatementTypeFragment_user}
  query LIST_FINANCIAL_STATEMENT_TYPES(
    $filter: ModelFinancialStatementTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinancialStatementTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...FinancialStatementTypeFragment_user
      }
      nextToken
    }
  }
`;

export const listFinDataTablesQuery_user = gql`
  ${FinDataTableFragment_user}
  query LIST_FinDataTableS(
    $filter: ModelFinDataTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinDataTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...FinDataTableFragment_user
      }
      nextToken
    }
  }
`;

export const listMetricsQuery_user = gql`
  ${MetricFragment_user}
  query LIST_METRICS(
    $filter: ModelMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...MetricFragment_user
      }
      nextToken
    }
  }
`;

export const listMetricFinDataTablesQuery_user = gql`
  ${MetricFinDataTableFragment_user}
  query LIST_MetricFinDataTableS(
    $filter: ModelMetricFinDataTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetricFinDataTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...MetricFinDataTableFragment_user
      }
      nextToken
    }
  }
`;

export const listTenQOrKsQuery_user = gql`
  ${TenQOrKFragment_user}
  query LIST_TENQORKS(
    $filter: ModelTenQOrKFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenQOrKS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...TenQOrKFragment_user
      }
      nextToken
    }
  }
`;

export const listXbrlTagsQuery_user = gql`
  ${XBRLTagFragment_user}
  query LIST_XBRL_TAGS(
    $filter: ModelXBRLTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listXBRLTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...XBRLTagFragment_user
      }
      nextToken
    }
  }
`;

export const listXbrlTagTypesQuery_user = gql`
  ${XBRLTagTypeFragment_user}
  query LIST_XBRL_TAG_TYPES(
    $filter: ModelXBRLTagTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listXBRLTagTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...XBRLTagTypeFragment_user
      }
      nextToken
    }
  }
`;

export const listXsdFilesQuery_user = gql`
  ${XSDFileFragment_user}
  query LIST_XBRL_TAG_TYPES(
    $filter: ModelXSDFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listXSDFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...XSDFileFragment_user
      }
      nextToken
    }
  }
`;

// --------------- GET QUERIES -----------

export const getCompanyQuery_user = gql`
  ${CompanyFragment_user}
  query GetCompany($id: ID!) {
    getCompany_user(id: $id) {
      ...CompanyFragment_user
    }
  }
`;

export const getCompanyFinDataTableMetricOrderQuery_user = gql`
  ${CompanyFinDataTableMetricOrderFragment_user}
  query GetCompanyFinDataTableMetricOrder($id: ID!) {
    getCompanyFinDataTableMetricOrder_user(id: $id) {
      ...CompanyFinDataTableMetricOrderFragment_user
    }
  }
`;

// Note: DatumArchives are not queriable by "user" types

export const getEarningsReleaseDateQuery_user = gql`
  ${EarningsReleaseDateFragment_user}
  query GetEarningsReleaseDate($id: ID!) {
    getEarningsReleaseDate_user(id: $id) {
      ...EarningsReleaseDateFragment_user
    }
  }
`;

export const getEarningsTranscriptQuery_user = gql`
  ${EarningsTranscriptFragment_user}
  query GetEarningsTranscript($id: ID!) {
    getEarningsTranscript_user(id: $id) {
      ...EarningsTranscriptFragment_user
    }
  }
`;

export const getFinancialStatementQuery_user = gql`
  ${FinancialStatementFragment_user}
  query GetFinancialStatement($id: ID!) {
    getFinancialStatement_user(id: $id) {
      ...FinancialStatementFragment_user
    }
  }
`;

export const getFinancialStatementTypeQuery_user = gql`
  ${FinancialStatementTypeFragment_user}
  query GetFinancialStatementType($id: ID!) {
    getFinancialStatementType_user(id: $id) {
      ...FinancialStatementTypeFragment_user
    }
  }
`;

export const getFinDataTableQuery_user = gql`
  ${FinDataTableFragment_user}
  query GetFinDataTable($id: ID!) {
    getFinDataTable_user(id: $id) {
      ...FinDataTableFragment_user
    }
  }
`;

export const getMetricQuery_user = gql`
  ${MetricFragment_user}
  query GetMetric($id: ID!) {
    getMetric_user(id: $id) {
      ...MetricFragment_user
    }
  }
`;

export const getMetricFinDataTableQuery_user = gql`
  ${MetricFinDataTableFragment_user}
  query GetMetricFinDataTable($id: ID!) {
    getMetricFinDataTable_user(id: $id) {
      ...MetricFinDataTableFragment_user
    }
  }
`;

export const getTenQOrKQuery_user = gql`
  ${TenQOrKFragment_user}
  query GetTenQOrK($id: ID!) {
    getTenQOrK_user(id: $id) {
      ...TenQOrKFragment_user
    }
  }
`;

export const getXbrlTagQuery_user = gql`
  ${XBRLTagFragment_user}
  query GetXBRLTag($id: ID!) {
    getXBRLTag_user(id: $id) {
      ...XBRLTagFragment_user
    }
  }
`;

export const getXbrlTagTypeQuery_user = gql`
  ${XBRLTagTypeFragment_user}
  query GetXBRLTagType($id: ID!) {
    getXBRLTagType_user(id: $id) {
      ...XBRLTagTypeFragment_user
    }
  }
`;

export const getXsdFileQuery_user = gql`
  ${XSDFileFragment_user}
  query GetXSDFile($id: ID!) {
    getXSDFile_user(id: $id) {
      ...XSDFileFragment_user
    }
  }
`;
