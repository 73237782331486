import actionTypes from "./actionTypes";
import {
  listCompanies,
  listCompanies_user,
} from "../gql/gqlHelperQueriesLayer";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllCompanies = async (dispatch, userGroups) => {
  const isUserAdmin = determineIsUserAdmin(userGroups);

  let companies = isUserAdmin
    ? await listCompanies()
    : await listCompanies_user();
  companies = companies.sort((a, b) => {
    if (a.registrantName > b.registrantName) {
      return 1;
    } else if (b.registrantName > a.registrantName) {
      return -1;
    } else {
      return 0;
    }
  });

  dispatch({
    type: actionTypes.LOAD_ALL_COMPANIES,
    payload: companies,
  });
};
