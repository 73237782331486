import React from "react";

function FinDataFootnotesComponent(props) {
  const { footnotesLookup } = props;

  if (Object.keys(footnotesLookup).length === 0) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <div
        style={{
          fontWeight: "bold",
          marginTop: "24px",
          marginBottom: "12px",
          fontSize: "14px",
        }}
      >
        Notes:
      </div>
      {Object.values(footnotesLookup).map((fn) => {
        const footnote = `(${fn?.number}) ${fn?.text}`;
        return (
          <div key={footnote} style={{ fontSize: "14px" }}>
            {footnote}
          </div>
        );
      })}
    </React.Fragment>
  );
}

export default FinDataFootnotesComponent;
