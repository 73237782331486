import React from "react";
import FinDataTableFromLookupObj from "../FinDataTableFromLookupObj";

function ViewFinDataTables({ finDataTablesLookup }) {
  return (
    <FinDataTableFromLookupObj
      lookupObj={finDataTablesLookup}
      columnHeaders={["id", "key"]}
    />
  );
}

export default ViewFinDataTables;
