import actionTypes from "../actions/actionTypes";

const initialState = {
  finDataTablesLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setFinDataTables(state, finDataTablesArray) {
  const finDataTablesLookup = finDataTablesArray.reduce((accum, currValue) => {
    accum[currValue.id] = currValue;
    return accum;
  }, {});
  return { ...state, finDataTablesLookup };
}

function createFinDataTable(state, finDataTable) {
  const finDataTablesLookup = { ...state.FinDataTablesLookup };
  finDataTablesLookup[finDataTable.id] = finDataTable;
  return { ...state, finDataTablesLookup };
}

// -----------------------------------------------------
// Reducer

function FinDataTablesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_FIN_DATA_TABLES:
      return setFinDataTables(state, action.payload);

    case actionTypes.CREATE_FIN_DATA_TABLE:
      return createFinDataTable(state, action.payload);

    default:
      return state;
  }
}

export default FinDataTablesReducer;
