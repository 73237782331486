import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { capitalizeFirstLetterOfString } from "../../helpers/miscHelpers";
import {
  COMPANY_OR_MARKET_DATA_DB_ENUM_MARKET,
  MAP_COMPARISON_PERIODS_DB_ENUM_TO_PLAIN_ENGLISH,
} from "../../constants";
import {
  convertFloatIntoPercentage,
  convertWordIntoALowerCaseWordWithUpperCaseFirstLetter,
} from "../../helpers/miscHelpers";
import { determineTimePeriodLanguageForDatum } from "../../helpers/datumHelpers";
import { HashLink as Link } from "react-router-hash-link";
import LinkIcon from "@material-ui/icons/Link";
import { linkStyle, linkIconStyle } from "../../styles";
import routes from "../../routes";
import { useSelector } from "react-redux";
import { scrollWithOffset } from "../../helpers/scrollHelpers";

const useStyles = makeStyles({
  linkStyle: {
    ...linkStyle,
  },
  linkIconStyle: {
    ...linkIconStyle,
  },
});

function EarningsTranscriptDatum(props) {
  const classes = useStyles();
  const datum = props.datum;
  const metricsLookup = useSelector((state) => state.metrics.metricsLookup);
  const transcriptsLookup = useSelector(
    (state) => state.transcripts.transcriptsLookup
  );
  const companiesLookup = useSelector(
    (state) => state.companies.companiesLookup
  );
  const hasQualifiers =
    datum.qualifiers &&
    Array.isArray(datum.qualifiers) &&
    datum.qualifiers.length > 0;

  // This function handles saving the current scroll position.
  const handleLinkClick = () => {};

  // This effect restores the scroll position
  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem(
      "EarningsTranscriptData-ScrollPosition"
    );

    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
    }
  }, []);

  return (
    <Link
      smooth
      to={{
        pathname: `${routes.insertParamsIntoRoutePath(
          routes.EARNINGS_TRANSCRIPT,
          {
            earningsTranscriptId: datum.earningsTranscriptId,
          }
        )}`,
        hash: `#${datum.id}`,
        search: "?source=datumLink",
      }}
      scroll={scrollWithOffset}
      onClick={handleLinkClick}
      activeStyle={{ color: "black" }}
      className={classes.linkStyle}
    >
      {"- "}
      {hasQualifiers
        ? `${capitalizeFirstLetterOfString(datum.qualifiers.join(", "))}${
            datum.companyOrMarketData === COMPANY_OR_MARKET_DATA_DB_ENUM_MARKET
              ? ", market "
              : ""
          }`
        : ""}
      {hasQualifiers
        ? " " + metricsLookup[datum.metricId].metricPlainEnglish
        : convertWordIntoALowerCaseWordWithUpperCaseFirstLetter(
            metricsLookup[datum.metricId].metricPlainEnglish
          )}{" "}
      {determineTimePeriodLanguageForDatum(
        datum,
        transcriptsLookup[datum.earningsTranscriptId],
        companiesLookup[datum.companyId]
      )}{" "}
      {datum.isGuidance
        ? "is guided to be"
        : datum.timePeriodEndDate > datum.asOfDate
        ? "is predicted to be"
        : "was"}{" "}
      {convertFloatIntoPercentage(datum.value)}{" "}
      {datum.comparisonPeriod
        ? MAP_COMPARISON_PERIODS_DB_ENUM_TO_PLAIN_ENGLISH[
            datum.comparisonPeriod
          ]
        : null}
      <LinkIcon className={classes.linkIconStyle} />
    </Link>
  );
}

export default EarningsTranscriptDatum;
