import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { convertEarningsTranscriptIntoParagraphTagsWithIdsEqualToDataTabDatumIds } from "../../helpers/transcriptHelpers";
import { useSelector } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import routes from "../../routes";
import { selectHumanlyReviedConfidentlyExtractedDatumsForEarningsTranscript } from "../../store/selectors";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({});

function ViewEarningsTranscript(props) {
  const location = useLocation();
  const classes = useStyles();
  const transcript = props.transcript;

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    // Only scroll to top if the user did not come from an EarningsTranscriptDatum link
    if (
      !searchParams.has("source") ||
      searchParams.get("source") !== "datumLink"
    ) {
      window.scrollTo(0, 0);
    }
  }, []);

  const humanReviewedConfidentlyExtractedDatumsForTranscriptLookup =
    useSelector((state) =>
      selectHumanlyReviedConfidentlyExtractedDatumsForEarningsTranscript(
        state,
        transcript
      )
    );

  const companiesLookup = useSelector(
    (state) => state.companies.companiesLookup
  );
  const company = companiesLookup[transcript.companyId];

  return (
    <div key={transcript.id}>
      {searchParams.has("source") &&
        searchParams.get("source") === "transcriptsLink" && (
          <Link to={routes.EARNINGS_TRANSCRIPTS} className={classes.linkStyle}>
            Back to all transcripts
          </Link>
        )}
      {searchParams.has("source") &&
        searchParams.get("source") === "datumLink" && (
          <Link
            to={routes.EARNINGS_TRANSCRIPT_DATA}
            className={classes.linkStyle}
          >
            Back to all earnings transcript data
          </Link>
        )}
      <h3>
        {`${transcript.symbol} - ${company.registrantName} - Q${
          transcript.quarter
        } ${transcript.year} earnings transcript - ${moment(
          transcript.date
        ).format("YYYY-MM-DD")} - Fiscal Year Ends ${company.fiscalYearEnd}`}
      </h3>
      <div style={{ whiteSpace: "pre-line" }}>
        {convertEarningsTranscriptIntoParagraphTagsWithIdsEqualToDataTabDatumIds(
          transcript.content,
          Object.values(
            humanReviewedConfidentlyExtractedDatumsForTranscriptLookup
          )
        )}
      </div>
    </div>
  );
}

export default ViewEarningsTranscript;
