class ReactRouterHashLinkMeta {
  /**
   * @param {object} to
   * @param {function} scroll
   * @param {function} onClick
   */
  constructor(to, scroll, onClick, activeStyle, className) {
    this.to = to;
    this.scroll = scroll;
    this.onClick = onClick;
  }
}

export default ReactRouterHashLinkMeta;
