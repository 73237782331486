import React, { useState, useEffect, useRef } from "react";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import ButtonWithCircularProgress from "../ButtonWithCircularProgress";

import { createXBRLTagAction } from "../../actions/xbrlTagsActions";

import FinDataSelectInput from "../FinDataSelectInput";
import FinDataExpand from "../FinDataExpand";

const useStyles = makeStyles((theme) => ({
  createNewXbrlTag: {
    backgroundColor: "white",
    padding: "8px",
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  subTitle: {
    fontWeight: "bold",
  },
}));

function CreateNewXbrlTag(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Is Saving New XbrlTag
  const [isSavingNewXbrlTag, setIsSavingNewXbrlTag] = useState(false);

  // State variables used for creating a new XbrlTag
  const [showXbrlTagFields, setShowXbrlTagFields] = useState(false);
  const [newXbrlTag, setNewXbrlTag] = useState("");
  const [newXbrlTagPlainEnglish, setNewXbrlTagPlainEnglish] = useState("");
  const [selectedXbrlTagTypeId, setSelectedXbrlTagTypeId] = useState("");

  const xbrlTagTypesLookup = useSelector(
    (state) => state.xbrlTagTypes.xbrlTagTypesLookup
  );

  const handleCreateXbrlTag = async () => {
    setIsSavingNewXbrlTag(true);

    const createXbrlTagInput = {
      tag: newXbrlTag,
      tagPlainEnglish: newXbrlTagPlainEnglish,
      xbrlTagTypeId: selectedXbrlTagTypeId,
    };

    try {
      await createXBRLTagAction(dispatch, createXbrlTagInput);
      setIsSavingNewXbrlTag(false);
      setShowXbrlTagFields(false);
      setNewXbrlTag("");
      setSelectedXbrlTagTypeId("");
    } catch (error) {
      setIsSavingNewXbrlTag(false);
    }
  };

  return (
    <div className={classes.createNewXbrlTag}>
      <FinDataExpand
        title="Create New XBRL Tag (Optional)"
        isExpandedView={showXbrlTagFields}
        setIsExpandedView={setShowXbrlTagFields}
      />

      {showXbrlTagFields && (
        <>
          <TextField
            id="newXbrlTag"
            label="New XBRL Tag"
            value={newXbrlTag}
            onChange={(e) => setNewXbrlTag(e.target.value)}
            style={{ marginTop: "12px", width: "100%" }}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Note: should be in UpperCamelCase
          </div>
          <TextField
            id="newXbrlTagPlainEnglish"
            label="New XBRL Tag Plain English"
            value={newXbrlTagPlainEnglish}
            onChange={(e) => setNewXbrlTagPlainEnglish(e.target.value)}
            style={{ marginTop: "12px", width: "100%" }}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Note: default to lower-case excluding acronyms
          </div>
          <FinDataSelectInput
            lookupObject={xbrlTagTypesLookup}
            id="xbrl-tag-type"
            selectedId={selectedXbrlTagTypeId}
            setSelectedId={setSelectedXbrlTagTypeId}
            inputLabel="XBRL Tag Type"
            style={{ marginTop: "12px", minWidth: 120, width: "100%" }}
            renderMenuItem={(xbrlTagType) => {
              return xbrlTagType.type;
            }}
          />

          <div style={{ marginTop: "12px" }}>
            <ButtonWithCircularProgress
              onClick={handleCreateXbrlTag}
              disabled={
                !newXbrlTag || !newXbrlTagPlainEnglish || !selectedXbrlTagTypeId
              }
              isSaving={isSavingNewXbrlTag}
              buttonText="Create New XbrlTag"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default CreateNewXbrlTag;
