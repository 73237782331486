import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

function ButtonWithCircularProgress({
  onClick,
  isSaving,
  buttonText,
  disabled,
}) {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      disabled={disabled}
    >
      {isSaving ? <CircularProgress /> : buttonText}
    </Button>
  );
}

export default ButtonWithCircularProgress;
