import { createStore, combineReducers, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import transcripts from "./transcripts";
import datums from "./datums";
import auth from "./auth";
import filings from "./filings";
import metrics from "./metrics";
import financialStatements from "./financialStatements";
import financialStatementTypes from "./financialStatementTypes";
import xbrlTags from "./xbrlTags";
import xbrlTagTypes from "./xbrlTagTypes";
import companies from "./companies";
import finDataTables from "./finDataTables";
import companyFinDataTableMetricOrders from "./companyFinDataTableMetricOrders";
import metricFinDataTables from "./metricFinDataTables";

const reducer = combineReducers({
  transcripts,
  datums,
  auth,
  filings,
  metrics,
  finDataTables,
  financialStatements,
  financialStatementTypes,
  xbrlTags,
  xbrlTagTypes,
  companies,
  companyFinDataTableMetricOrders,
  metricFinDataTables,
});

let middleware = [thunkMiddleware];
if (process.env.NODE_ENV !== "production") {
  middleware = [...middleware, createLogger({ collapsed: true })];
}

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
