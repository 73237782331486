import actionTypes from "../actions/actionTypes";

const initialState = {
  financialStatementsLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setFinancialStatements(state, financialStatementsArray) {
  const financialStatementsLookup = financialStatementsArray.reduce(
    (accum, currValue) => {
      accum[currValue.id] = currValue;
      return accum;
    },
    {}
  );
  return { ...state, financialStatementsLookup };
}

function createFinancialStatement(state, financialStatement) {
  const financialStatementsLookup = { ...state.financialStatementsLookup };
  financialStatementsLookup[financialStatement.id] = financialStatement;
  return { ...state, financialStatementsLookup };
}

// -----------------------------------------------------
// Reducer

function financialStatementsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_FINANCIAL_STATEMENTS:
      return setFinancialStatements(state, action.payload);

    case actionTypes.CREATE_FINANCIAL_STATEMENT:
      return createFinancialStatement(state, action.payload);

    default:
      return state;
  }
}

export default financialStatementsReducer;
