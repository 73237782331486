import actionTypes from "./actionTypes";
import { listFilings, listFilings_user } from "../gql/gqlHelperQueriesLayer";
import moment from "moment";
import { v4 } from "uuid";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllFilings = async (dispatch, userGroups) => {
  const isUserAdmin = determineIsUserAdmin(userGroups);
  let filings = isUserAdmin ? await listFilings() : await listFilings_user();

  filings.sort((a, b) => {
    return (
      moment(b.filingDate).toDate().getTime() -
      moment(a.filingDate).toDate().getTime()
    );
  });

  filings = filings.map((filing) => {
    return {
      ...filing,
      randomUuid: v4(),
    };
  });

  dispatch({
    type: actionTypes.LOAD_ALL_FILINGS,
    payload: filings,
  });
};

// This function is used to force a re-render of the filing when a datum has been updated
// In order to achieve this, we will add a field called randomUuid to each filing
// and every time we want to force a re-render we will update the randomUuid, which will
// force the app to re-grab the filing from S3 when viewing the filing
export const updateFiling = async (dispatch, filing) => {
  dispatch({
    type: actionTypes.UPDATE_FILING,
    payload: filing,
  });
};
