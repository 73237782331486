import actionTypes from "./actionTypes";
import {
  listData,
  listData_user,
  getDatum,
  getFiling,
} from "../gql/gqlHelperQueriesLayer";
import { updateDatum, createDatum } from "../gql/gqlHelperMutationsLayer";
import { v4 } from "uuid";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllDatums = async (dispatch, userGroups) => {
  const isUserAdmin = determineIsUserAdmin(userGroups);
  let datums = isUserAdmin ? await listData() : await listData_user();

  dispatch({
    type: actionTypes.LOAD_ALL_DATUMS,
    payload: datums,
  });
};

export const updateDatumAction = async (
  dispatch,
  updatedDatum,
  earningsTranscriptId,
  tenQOrKId
) => {
  const updatedDatumOnDynamo = await updateDatum(updatedDatum);

  let updatedFiling;
  if (updatedDatumOnDynamo.tenQOrKId) {
    updatedFiling = await getFiling(updatedDatumOnDynamo.tenQOrKId);
    updatedFiling.tenQOrKId = v4();
  }

  dispatch({
    type: actionTypes.UPDATE_DATUM,
    payload: updatedDatumOnDynamo,
  });

  if (updatedDatumOnDynamo.tenQOrKId) {
    dispatch({
      type: actionTypes.UPDATE_FILING,
      payload: updatedFiling,
    });
  }

  return updatedDatumOnDynamo;
};

export const createDatumAction = async (dispatch, createDatumInput) => {
  const createdDatumResponse = await createDatum(createDatumInput);
  const createdDatumOnDynamo = await getDatum(createdDatumResponse.id);

  dispatch({
    type: actionTypes.CREATE_DATUM,
    payload: createdDatumOnDynamo,
  });

  return createdDatumOnDynamo;
};
