import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateMetricAction } from "../../actions/metricsActions";

// Components
import FinDataExpand from "../FinDataExpand";
import ButtonWithCircularProgress from "../ButtonWithCircularProgress";
import UpdateMetricFormula from "./UpdateMetricFormula";

const useStyles = makeStyles((theme) => ({
  updateMetric: {
    backgroundColor: "white",
    padding: "8px",
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  subTitle: {
    fontWeight: "bold",
  },
}));

function UpdateMetric(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Is Saving New Metric
  const [isSavingMetric, setIsSavingMetric] = useState(false);

  // State variables used for updating a new Metric
  const [showMetricFields, setShowMetricFields] = useState(false);
  const [metricId, setMetricId] = useState("");
  const [metric, setMetric] = useState("");
  const [metricPlainEnglish, setMetricPlainEnglish] = useState("");
  const [metricValueType, setMetricValueType] = useState("");
  const [metricType, setMetricType] = useState("");
  const [metricFormula, setMetricFormula] = useState("");

  const metricsLookup = useSelector((state) => state.metrics.metricsLookup);

  useEffect(() => {
    if (!metricId || !metricsLookup) {
      return;
    }

    if (Object.keys(metricsLookup).includes(metricId)) {
      setMetric(metricsLookup[metricId].metric);
      setMetricPlainEnglish(metricsLookup[metricId].metricPlainEnglish);
      setMetricValueType(metricsLookup[metricId].valueType);
      setMetricType(metricsLookup[metricId].type);
      setMetricFormula(metricsLookup[metricId].formula);
    }
  }, [metricId, metricsLookup]);

  const handleUpdateMetric = async () => {
    setIsSavingMetric(true);

    const updateMetricInput = {
      id: metricId,
      metric: metric,
      metricPlainEnglish: metricPlainEnglish,
      valueType: metricValueType,
      type: metricType,
      formula: metricFormula,
    };

    try {
      await updateMetricAction(dispatch, updateMetricInput);
      setIsSavingMetric(false);
      setShowMetricFields(false);
      setMetric("");
      setMetricValueType("");
    } catch (error) {
      setIsSavingMetric(false);
    }
  };

  return (
    <div className={classes.updateMetric}>
      <FinDataExpand
        title="Update Metric (Optional)"
        isExpandedView={showMetricFields}
        setIsExpandedView={setShowMetricFields}
      />

      {showMetricFields && (
        <>
          <TextField
            id="metricId"
            label="Metric ID"
            value={metricId}
            onChange={(e) => setMetricId(e.target.value)}
            style={{ marginTop: "12px", width: "100%" }}
          />
          <TextField
            id="metric"
            label="Metric"
            value={metric}
            onChange={(e) => setMetric(e.target.value)}
            style={{ marginTop: "12px", width: "100%" }}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Note: should be in SCREAMING_SNAKE_CASE
          </div>
          <TextField
            id="metricPlainEnglish"
            label="New Metric Plain English"
            value={metricPlainEnglish}
            onChange={(e) => setMetricPlainEnglish(e.target.value)}
            style={{ marginTop: "12px", width: "100%" }}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Note: default to lower-case excluding acronyms
          </div>
          <TextField
            id="valueType"
            label="Value Type"
            value={metricValueType}
            onChange={(e) => setMetricValueType(e.target.value)}
            className={classes.formControl}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Note: value type options are PERCENTAGE, NUMBER, and USD
          </div>
          <TextField
            id="type"
            label="Type"
            value={metricType}
            onChange={(e) => setMetricType(e.target.value)}
            className={classes.setMetricFormula}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Note: type options are SEC_FILING, API, COMPUTED, or
            EARNINGS_TRANSCRIPT
          </div>
          <TextField
            id="formula"
            label="Formula"
            value={metricFormula}
            onChange={(e) => setMetricFormula(e.target.value)}
            className={classes.formControl}
          />
          {props.showUpdateMetricFormula && (
            <UpdateMetricFormula
              oldMetricFormula={metricFormula}
              onUpdate={setMetricFormula}
            />
          )}

          <div style={{ marginTop: "12px" }}>
            <ButtonWithCircularProgress
              onClick={handleUpdateMetric}
              disabled={!metric || !metricPlainEnglish || !metricValueType}
              isSaving={isSavingMetric}
              buttonText="Update Metric"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default UpdateMetric;
