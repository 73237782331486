import { GQLHelper } from "../helpers/gqlHelper";
import {
  // Update mutations
  updateCompanyFinDataTableMetricOrderMutation,
  updateDatumMutation,
  updateMetricMutation,
  // Create mutations
  createCompanyFinDataTableMetricOrderMutation,
  createDatumMutation,
  createFinancialStatementMutation,
  createFinDataTableMutation,
  createMetricMutation,
  createMetricFinDataTableMutation,
  createXbrlTagMutation,
} from "./mutations";

// -------------- UPDATE MUTATIONS ------------------

export const updateCompanyFinDataTableMetricOrder = async (input) => {
  return await GQLHelper.Instance.executeMutation(
    updateCompanyFinDataTableMetricOrderMutation,
    { input: input }
  );
};

export const updateDatum = async (input) => {
  return await GQLHelper.Instance.executeMutation(updateDatumMutation, {
    input: input,
  });
};

export const updateMetric = async (input) => {
  return await GQLHelper.Instance.executeMutation(updateMetricMutation, {
    input: input,
  });
};

// -------------- CREATE MUTATIONS ------------------

export const createCompanyFinDataTableMetricOrder = async (input) => {
  return await GQLHelper.Instance.executeMutation(
    createCompanyFinDataTableMetricOrderMutation,
    { input: input }
  );
};

export const createDatum = async (input) => {
  return await GQLHelper.Instance.executeMutation(createDatumMutation, {
    input: input,
  });
};

export const createFinancialStatement = async (input) => {
  return await GQLHelper.Instance.executeMutation(
    createFinancialStatementMutation,
    { input: input }
  );
};

export const createFinDataTable = async (input) => {
  return await GQLHelper.Instance.executeMutation(createFinDataTableMutation, {
    input: input,
  });
};

export const createMetric = async (input) => {
  return await GQLHelper.Instance.executeMutation(createMetricMutation, {
    input: input,
  });
};

export const createMetricFinDataTable = async (input) => {
  return await GQLHelper.Instance.executeMutation(
    createMetricFinDataTableMutation,
    { input: input }
  );
};

export const createXBRLTag = async (input) => {
  return await GQLHelper.Instance.executeMutation(createXbrlTagMutation, {
    input: input,
  });
};
