export function generateRandomHexcodeColorString() {
  // Initialize a color string with '#'
  let color = "#";
  // Define all possible characters in a hex code
  const characters = "0123456789ABCDEF";
  // Loop 6 times to generate 6 characters
  for (let i = 0; i < 6; i++) {
    // Append a random character from the characters string to color
    color += characters[Math.floor(Math.random() * 16)];
  }
  // Return the generated color string
  return color;
}
