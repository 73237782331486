import React, { useState, useEffect, useMemo } from "react";
import { useTable } from "react-table";
import { makeStyles, createStyles } from "@material-ui/core";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { DARK_BLUE_COLOR, DARK_GREEN_COLOR } from "../colors";
import ExportMenu from "./FinDataTableComponentComponents/ExportMenu";
import { colorizeCellInnerContent } from "../helpers/finDataTableComponentHelpers";
import TableHeader from "./FinDataTableComponentComponents/TableHeader";
import TableRow from "./FinDataTableComponentComponents/TableRow";
import { calculateTextWidth } from "../helpers/textHelpers";
import createColumns from "./FinDataTableComponentComponents/createColumns";

const useStyles = makeStyles((theme) =>
  createStyles({
    resizableTh: {
      position: "relative",
      textDecoration: "underline",
    },
    superscript: {
      verticalAlign: "top",
      fontSize: "0.75rem", // Explicitly smaller font size
      position: "relative",
      top: "-0.5em", // Adjust as needed for better alignment
      marginLeft: "2px", // Space between the text and the superscript
    },
    resizableTd: {
      position: "relative",
    },
    resizeHandle: {
      position: "absolute",
      top: 0,
      right: 0,
      width: 5,
      height: "100%",
      cursor: "ew-resize",
      zIndex: 1,
    },
    rowResizeHandle: {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: 5,
      cursor: "ns-resize",
      zIndex: 1,
    },
    rightAlignedCell: {
      textAlign: "right",
    },
    nonBoldHeader: {
      fontWeight: "normal",
    },
    clickableBlueCell: {
      color: DARK_BLUE_COLOR,
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    greenTextCell: {
      color: DARK_GREEN_COLOR,
    },
    clickableCell: {
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  })
);

/**
 * A table component that displays financial data. It is a wrapper around the react-table library.
 * @param {FinDataTable} finDataTable
 */
const FinDataTableComponent = ({ finDataTable }) => {
  const classes = useStyles();

  const isHighlightedCell = (rowIndex, columnIndex) => {
    if (finDataTable.checkIfCellIsHighlighted(rowIndex, columnIndex)) {
    }

    return finDataTable.checkIfCellIsHighlighted(rowIndex, columnIndex);
  };

  const isHighlightedSourceCell = (rowIndex, columnIndex) => {
    return finDataTable.checkIfCellIsHighlightedSource(rowIndex, columnIndex);
  };

  const defaultColumnWidth = 100;
  const defaultRowHeight = 25;
  const firstColumnWidth = 200;
  const firstRowHeight = 25;
  const defaultLineHeight = 20;

  const [wrapText, setWrapText] = useState(false);
  const [resizingColumn, setResizingColumn] = useState(null);
  const [resizingRow, setResizingRow] = useState(null);
  const [columnWidthsState, setColumnWidthsState] = useState(
    finDataTable.columnWidths ||
      new Array(finDataTable.columnHeaders.length + 1).fill(null)
  );
  const [rowHeightsState, setRowHeights] = useState(
    finDataTable.rowHeights ||
      new Array(finDataTable.rowHeaders.length + 1).fill(null)
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const getFootnoteText = (cellAddress) => {
    const footnote = finDataTable.footnotesLookup[cellAddress];
    // Apply inline style for the <sup> tag to adjust the font size
    return footnote ? (
      <sup style={{ fontSize: "11px" }}>{`(${footnote.number})`}</sup>
    ) : (
      ""
    );
  };

  const columns = useMemo(
    () =>
      createColumns({
        finDataTable,
        getFootnoteText, // Ensure this function is defined or imported
        isHighlightedCell, // Ensure this function is defined or imported
        colorizeCellInnerContent, // Ensure this function is defined or imported
        classes,
      }),
    [
      finDataTable.columnHeaders,
      finDataTable.footnotesLookup,
      classes.clickableBlueCell,
    ]
  );

  const startResizingColumn = (columnIndex, initialWidth, clientX) => {
    setResizingColumn({ columnIndex, initialWidth, initialClientX: clientX });
  };

  const startResizingRow = (rowIndex, initialHeight, clientY) => {
    setResizingRow({ rowIndex, initialHeight, initialClientY: clientY });
  };

  const stopResizing = () => {
    setResizingColumn(null);
    setResizingRow(null);
  };

  const tableData = useMemo(() => {
    return finDataTable.rowHeaders.map((rowHeaderCell, rowIndex) => {
      let rowData = {
        rowHeader: {
          value: rowHeaderCell.value,
          cellAddress: rowHeaderCell.cellAddress,
          metadata: rowHeaderCell.metadata,
        },
      };
      finDataTable.data[rowIndex].forEach((cellData, colIndex) => {
        // Retain the entire cellData object
        rowData[`col${colIndex}`] = cellData;
      });
      return rowData;
    });
  }, [finDataTable.rowHeaders, finDataTable.data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });

  // Handle export to CSV
  const exportToCSV = () => {
    const csvData = [];

    // Add title and subtitle if they exist
    if (finDataTable.tableTitle) csvData.push([finDataTable.tableTitle]);
    if (finDataTable.tableSubTitle) csvData.push([finDataTable.tableSubTitle]);

    // Add column headers
    const headers = [""].concat(
      finDataTable.columnHeaders.map((columnHeaders) => columnHeaders.value)
    ); // Add an empty string for the rowHeader column
    csvData.push(headers);

    // Add data rows
    finDataTable.data.forEach((row, rowIndex) => {
      const rowData = [
        finDataTable.rowHeaders[rowIndex].value,
        ...row.map((row) => row.value),
      ];
      csvData.push(rowData);
    });

    // Export to CSV
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.csv";
    a.click();
  };

  // Handle export to Excel
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [];

    // Add title and subtitle if they exist
    if (finDataTable.tableTitle) wsData.push([finDataTable.tableTitle]);
    if (finDataTable.tableSubTitle) wsData.push([finDataTable.tableSubTitle]);

    // Add column headers
    const headers = [""].concat(
      finDataTable.columnHeaders.map((columnHeaders) => columnHeaders.value)
    ); // Add an empty string for the rowHeader column
    wsData.push(headers);

    // Add data rows
    finDataTable.data.forEach((row, rowIndex) => {
      const rowData = [
        finDataTable.rowHeaders[rowIndex].value,
        ...row.map((row) => row.value),
      ];
      wsData.push(rowData);
    });

    // Create worksheet and append to workbook
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "table_data.xlsx");
  };

  // UseEffect for initial column widths and row heights
  useEffect(() => {
    const initialColumnWidths = [
      firstColumnWidth,
      ...Array(finDataTable.columnHeaders.length).fill(defaultColumnWidth),
    ];

    setColumnWidthsState((prevWidths) =>
      initialColumnWidths.map((defaultWidth, index) =>
        finDataTable.columnWidths && finDataTable.columnWidths[index]
          ? finDataTable.columnWidths[index]
          : defaultWidth
      )
    );

    const initialRowHeights = [
      firstRowHeight,
      ...Array(finDataTable.rowHeaders.length).fill(defaultRowHeight),
    ];

    setRowHeights((prevHeights) =>
      initialRowHeights.map((defaultHeight, index) =>
        finDataTable.rowHeights && finDataTable.rowHeights[index]
          ? finDataTable.rowHeights[index]
          : defaultHeight
      )
    );
  }, [
    finDataTable.columnHeaders.length,
    finDataTable.rowHeaders.length,
    finDataTable.columnWidths,
    finDataTable.rowHeights,
    firstColumnWidth,
    defaultColumnWidth,
    firstRowHeight,
    defaultRowHeight,
  ]);

  // Use Effect for resizing columns and rows
  useEffect(() => {
    const handleMouseMove = (event) => {
      if (resizingColumn) {
        const { columnIndex, initialWidth, initialClientX } = resizingColumn;
        const newWidth = Math.max(
          initialWidth + (event.clientX - initialClientX),
          10
        ); // Adding a minimum width
        setColumnWidthsState((prevWidths) => {
          const newWidths = [...prevWidths];
          newWidths[columnIndex] = newWidth;
          return newWidths;
        });
      } else if (resizingRow) {
        const { rowIndex, initialHeight, initialClientY } = resizingRow;
        const newHeight = Math.max(
          initialHeight + (event.clientY - initialClientY),
          10
        ); // Adding a minimum height
        setRowHeights((prevHeights) => {
          const newHeights = [...prevHeights];
          newHeights[rowIndex] = newHeight;
          return newHeights;
        });
      }
    };

    const handleMouseUp = () => {
      stopResizing();
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [resizingColumn, resizingRow]);

  // Open dropdown
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Toggle wrap text
  const toggleWrapText = () => {
    setWrapText(!wrapText);
    handleClose();
  };

  const handleColumnDoubleClick = (columnIndex, event) => {
    // Calculate max width of cell contents
    const maxWidthContent = rows.reduce((max, row) => {
      const cellContent = row.cells[columnIndex].value;
      const cellWidth = calculateTextWidth(cellContent);

      return Math.max(max, cellWidth);
    }, 0);

    // Calculate width of the column header
    const columnHeader = finDataTable.columnHeaders[columnIndex - 1]; // Adjust index as needed
    const columnHeaderWidth = calculateTextWidth(columnHeader);

    // Determine the new width for the column
    const newWidth = Math.max(maxWidthContent, columnHeaderWidth) + 10; // +10 for some padding

    // Update state with new width
    setColumnWidthsState((prevWidths) => {
      const newWidths = [...prevWidths];
      newWidths[columnIndex] = newWidth;
      return newWidths;
    });
  };

  const handleRowDoubleClick = (rowIndex) => {
    const maxHeight = rows[rowIndex - 1].cells.reduce((max, cell) => {
      const cellContent = cell.value;
      const lines = splitTextToLines(
        cellContent,
        columnWidthsState[cell.column.id] || defaultColumnWidth
      );
      const cellHeight = lines.length * defaultLineHeight;
      return Math.max(max, cellHeight);
    }, 0);

    setRowHeights((prevHeights) => {
      const newHeights = [...prevHeights];
      newHeights[rowIndex] = maxHeight;
      return newHeights;
    });
  };

  const handleHeaderRowDoubleClick = (event) => {
    const maxHeight = headerGroups[0].headers.reduce((max, cell, cellIdx) => {
      const cellContent = cell.Header;
      const lines = splitTextToLines(
        cellContent,
        columnWidthsState[cellIdx] || defaultColumnWidth
      );
      const cellHeight = lines.length * defaultLineHeight;
      return Math.max(max, cellHeight);
    }, 0);

    setRowHeights((prevHeights) => {
      const newHeights = [...prevHeights];
      newHeights[0] = maxHeight;
      return newHeights;
    });
  };

  function splitTextToLines(text, maxWidth) {
    const words = text.split(" ");
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = calculateTextWidth(currentLine + " " + word);
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  }

  const noTableDataAvailable =
    (finDataTable.data.length === 0 || finDataTable.data[0].length === 0) &&
    finDataTable.columnHeaders.length === 0 &&
    finDataTable.rowHeaders.length === 0;

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {finDataTable.tableTitle && (
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>
          {finDataTable.tableTitle}
        </div>
      )}
      {finDataTable.tableSubTitle && (
        <div style={{ fontSize: "14px", fontStyle: "italic" }}>
          {finDataTable.tableSubTitle}
        </div>
      )}
      <ExportMenu
        onExportToCSV={exportToCSV}
        onExportToExcel={exportToExcel}
        onToggleWrapText={toggleWrapText}
        wrapText={wrapText}
      />
      {noTableDataAvailable ? (
        <div style={{ paddingTop: "8px" }}>No table data available</div>
      ) : (
        <table
          {...getTableProps()}
          style={{ borderSpacing: "0", paddingTop: "8px" }}
        >
          <TableHeader
            headerGroups={headerGroups}
            columnWidthsState={columnWidthsState}
            startResizingColumn={startResizingColumn}
            handleColumnDoubleClick={handleColumnDoubleClick}
            classes={classes}
            rowHeightsState={rowHeightsState}
            startResizingRow={startResizingRow}
            handleHeaderRowDoubleClick={handleHeaderRowDoubleClick}
            defaultColumnWidth={defaultColumnWidth}
            firstRowHeight={firstRowHeight}
          />
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              return (
                <TableRow
                  key={rowIndex}
                  row={row}
                  rows={rows}
                  rowIndex={rowIndex}
                  prepareRow={prepareRow}
                  columnWidthsState={columnWidthsState}
                  defaultColumnWidth={defaultColumnWidth}
                  rowHeightsState={rowHeightsState}
                  defaultRowHeight={defaultRowHeight}
                  classes={classes}
                  startResizingColumn={startResizingColumn}
                  handleColumnDoubleClick={handleColumnDoubleClick}
                  startResizingRow={startResizingRow}
                  handleRowDoubleClick={handleRowDoubleClick}
                  wrapText={wrapText}
                  isHighlightedCell={isHighlightedCell}
                  isHighlightedSourceCell={isHighlightedSourceCell}
                  finDataTable={finDataTable}
                />
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FinDataTableComponent;
