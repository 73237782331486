import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  subTitle: {
    fontWeight: "bold",
  },
}));

function FinDataExpand({ title, isExpandedView, setIsExpandedView }) {
  const classes = useStyles();

  return (
    <div
      className={classes.formControl}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={() => setIsExpandedView(!isExpandedView)}
    >
      <div className={classes.subTitle}>{title}</div>
      <div style={{ marginLeft: "10px" }}>
        {isExpandedView ? (
          <ExpandLess color="secondary" />
        ) : (
          <ExpandMore color="secondary" />
        )}
      </div>
    </div>
  );
}

export default FinDataExpand;
