// -------------------------------- MISC JS HELPERS ------------------------------------

export function determineDifferentKeysBetweenTwoObjects(object1, object2) {
  const arrayOfAllObjectKeys = [
    ...new Set(Object.keys(object1).concat(Object.keys(object2))),
  ];

  return arrayOfAllObjectKeys.filter((key) => {
    let value1 = object1[key];
    let value2 = object2[key];

    return JSON.stringify(value1) !== JSON.stringify(value2);
  });
}

export function convertWordIntoALowerCaseWordWithUpperCaseFirstLetter(word) {
  const returnString =
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  return returnString;
}

export function lowerCaseOnlyFirstLetterOfWord(word) {
  const returnString = word.charAt(0).toLowerCase() + word.slice(1);
  return returnString;
}

export function capitalizeFirstLetterOfString(word) {
  const returnString = word.charAt(0).toUpperCase() + word.slice(1);
  return returnString;
}

export function convertFloatIntoPercentage(float) {
  return `${(float * 100).toLocaleString()}%`;
}
