import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { PRIMARY_COLOR } from "../colors";

const useStyles = makeStyles((theme) => ({
  sidePanel: {
    position: "fixed",
    top: theme.spacing(8), // 8 * 8px (default spacing) = 64px
    right: 0,
    bottom: 0,
    width: "40%",
    overflow: "auto",
    backgroundColor: `${PRIMARY_COLOR}`,
    padding: theme.spacing(2),
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "start", // Align items to the start/left
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Add box-shadow for an elevated effect
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    marginTop: "-17px", // Offset for padding in IconButton
    marginRight: "-17px",
  },
}));

function SidePanel({ isOpen, onClose, children }) {
  const classes = useStyles();
  if (!isOpen) {
    return null;
  }

  return (
    <div className={classes.sidePanel}>
      <IconButton onClick={onClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      {children}
    </div>
  );
}

export default SidePanel;
