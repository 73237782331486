import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";

import FinDataTableComponent from "../FinDataTableComponent";
import FinDataFootnotesComponent from "../FinDataFootnotesComponent";

import { FIN_DATA_TABLE_CAP_TABLE } from "../../constants";
import { updateReactRouterHashLinkMetasInFinDataTable } from "../../helpers/datumHelpers";
import { fillOutApiAndComputedDataForCapTable } from "../../helpers/capTableHelpers";
import { createFinDataTableClassInstanceUsingFinDataTableIdAndCompanyId } from "../../store/selectors";

function FinDataTableForCompany(props) {
  const {
    finDataTable,
    selectedCompanyId,
    selectedCompanyStockPriceData,
    selectedCompanyStockPriceHistory,
  } = props;

  const [renderKey, setRenderKey] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const metricsLookup = useSelector((state) => state.metrics.metricsLookup);
  const filingsLookup = useSelector((state) => state.filings.filingsLookup);
  const finDataTableClass = useSelector((state) =>
    createFinDataTableClassInstanceUsingFinDataTableIdAndCompanyId(
      state,
      finDataTable.id,
      selectedCompanyId,
      finDataTable.name
    )
  );

  const [highlightedCell, setHighlightedCell] = useState(
    finDataTable.highlightedCell
  );
  const [highlightedSourceCells, setHighlightedSourceCells] = useState(
    finDataTable.highlightedSourceCells
  );

  // Refs for deep comparison
  const finDataTableRef = useRef(finDataTable);
  const finDataTableClassRef = useRef(finDataTableClass);

  useEffect(() => {
    // Only update the refs if the objects are different based on deep comparison
    if (!_.isEqual(finDataTableRef.current, finDataTable)) {
      finDataTableRef.current = finDataTable;
    }

    if (!_.isEqual(finDataTableClassRef.current, finDataTableClass)) {
      finDataTableClassRef.current = finDataTableClass;
    }

    const updateData = async () => {
      setIsLoading(true);
      if (finDataTable.key === FIN_DATA_TABLE_CAP_TABLE) {
        const updatedData = fillOutApiAndComputedDataForCapTable(
          finDataTableClassRef.current,
          selectedCompanyStockPriceData,
          selectedCompanyStockPriceHistory,
          metricsLookup,
          filingsLookup,
          updateHighlightedCell,
          updateHighlightedSourceCells
        );
        const finalData = await updateReactRouterHashLinkMetasInFinDataTable(
          updatedData
        );

        setUpdatedFinDataTableClass(finalData);
      }
      setIsLoading(false);
    };

    updateData();
  }, [
    selectedCompanyStockPriceData,
    selectedCompanyStockPriceHistory,
    metricsLookup,
    filingsLookup,
    // Add only primitive types or values that don't need deep comparison here
  ]);

  const [updatedFinDataTableClass, setUpdatedFinDataTableClass] =
    useState(finDataTableClass);
  const hasFootnotes =
    updatedFinDataTableClass?.footnotesLookup &&
    Object.keys(updatedFinDataTableClass.footnotesLookup).length > 0;

  // Pass updateHighlightedCell to wherever needed, perhaps through props or context

  // Add a method to update highlightedCell
  const updateHighlightedCell = (newHighlightedCell) => {
    setHighlightedCell(newHighlightedCell);
  };

  // Add a method to update highlightedCell
  const updateHighlightedSourceCells = (newHighlightedSourceCells) => {
    setHighlightedSourceCells(newHighlightedSourceCells);
  };

  // Add useEffect for Escape key logic
  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === "Escape") {
        setHighlightedCell(null);
        setHighlightedSourceCells([]);
        updatedFinDataTableClass.clearHighlightedCell();
        updatedFinDataTableClass.clearHighlightedSourceCells();
        setRenderKey((prevKey) => prevKey + 1);
      }
    };

    document.addEventListener("keydown", handleEscapeKeyPress);

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, []); // Empty dependency array means this effect runs only on mount and unmount

  return (
    <React.Fragment>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <FinDataTableComponent
            finDataTable={updatedFinDataTableClass}
            key={renderKey}
          />
          {hasFootnotes && (
            <div style={{ marginTop: "10px" }}>
              <FinDataFootnotesComponent
                footnotesLookup={updatedFinDataTableClass.footnotesLookup}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default FinDataTableForCompany;
