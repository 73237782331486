export const REPLACE_COMMAS_STRING = "%REPLACEDCOMMAS%";
export const PROMPT_STOP_SEQUENCE = " ___CONCLUDE_PROMPT___";
export const COMPLETION_STOP_SEQUENCE = " ###END#COMPLETION###";

export const STICKY_HEADER = `Extract data for the provided metric from the provided transcript metadata (metadata about the transcript that contains the sentence and paragraph that contain the prospective data), sentence (the sentence that contains the prospective data), and paragraph (the paragraph that contains the sentence that contains the prospective data). I would like the extracted data to describe the data value (as a float), whether or not the data is a snapshot, the time period interval of the data (e.g. days, months, quarters, years, month-to-date, quarter-to-date, and year-to-date), the time period value (i.e. the number of time period intervals), the time period end date of the data, the company that is reporting the data, any qualifiers related to that data, whether or not the data represents guidance by the company's management; the degree of your confidence in determining the time period (interval, value, and end-date) expressed as a percentage; the as-of date of the data, whether the data is for the company or for the market, and the comparison period of the data (if applicable).`;

export const UNABALE_TO_CONFIDENTLY_EXTRACT_DATA_COMPLETION =
  "I was not able to confidently extract data for the provided metric from the provided transcript metadata, sentence, and paragraph.";

export const ABLE_TO_CONFIDENTLY_EXTRACT_DATA_COMPLETION_FIRST_SENTENCE =
  "I was able to confidently extract data for the provided metric from the provided transcript metadata, sentence, and paragraph.";

// ------------------------- TIME PERIOD INTERVALS  -----------------------
export const TIME_PERIOD_INTERVAL_DAYS_PLAIN_ENGLISH = "days";
export const TIME_PERIOD_INTERVAL_MONTHS_PLAIN_ENGLISH = "months";
export const TIME_PERIOD_INTERVAL_QUARTERS_PLAIN_ENGLISH = "quarters";
export const TIME_PERIOD_INTERVAL_YEARS_PLAIN_ENGLISH = "years";
export const TIME_PERIOD_INTERVAL_MONTH_TO_DATE_PLAIN_ENGLISH = "month-to-date";
export const TIME_PERIOD_INTERVAL_QUARTER_TO_DATE_PLAIN_ENGLISH =
  "quarter-to-date";
export const TIME_PERIOD_INTERVAL_YEAR_TO_DATE_PLAIN_ENGLISH = "year-to-date";

export const TIME_PERIOD_INTERVAL_DAYS_DB_ENUM = "DAYS";
export const TIME_PERIOD_INTERVAL_MONTHS_DB_ENUM = "MONTHS";
export const TIME_PERIOD_INTERVAL_QUARTERS_DB_ENUM = "QUARTERS";
export const TIME_PERIOD_INTERVAL_YEARS_DB_ENUM = "YEARS";
export const TIME_PERIOD_INTERVAL_MONTH_TO_DATE_DB_ENUM = "MONTH_TO_DATE";
export const TIME_PERIOD_INTERVAL_QUARTER_TO_DATE_DB_ENUM = "QUARTER_TO_DATE";
export const TIME_PERIOD_INTERVAL_YEAR_TO_DATE_DB_ENUM = "YEAR_TO_DATE";

export const MAP_TIME_PERIOD_INTERVAL_PLAIN_ENGLISH_TO_DB_ENUM = {
  [TIME_PERIOD_INTERVAL_DAYS_PLAIN_ENGLISH]: TIME_PERIOD_INTERVAL_DAYS_DB_ENUM,
  [TIME_PERIOD_INTERVAL_MONTHS_PLAIN_ENGLISH]:
    TIME_PERIOD_INTERVAL_MONTHS_DB_ENUM,
  [TIME_PERIOD_INTERVAL_QUARTERS_PLAIN_ENGLISH]:
    TIME_PERIOD_INTERVAL_QUARTERS_DB_ENUM,
  [TIME_PERIOD_INTERVAL_YEARS_PLAIN_ENGLISH]:
    TIME_PERIOD_INTERVAL_YEARS_DB_ENUM,
  [TIME_PERIOD_INTERVAL_MONTH_TO_DATE_PLAIN_ENGLISH]:
    TIME_PERIOD_INTERVAL_MONTH_TO_DATE_DB_ENUM,
  [TIME_PERIOD_INTERVAL_QUARTER_TO_DATE_PLAIN_ENGLISH]:
    TIME_PERIOD_INTERVAL_QUARTER_TO_DATE_DB_ENUM,
  [TIME_PERIOD_INTERVAL_YEAR_TO_DATE_PLAIN_ENGLISH]:
    TIME_PERIOD_INTERVAL_YEAR_TO_DATE_DB_ENUM,
};

export const MAP_TIME_PERIOD_INTERVAL_DB_ENUM_TO_PLAIN_ENGLISH = {
  [TIME_PERIOD_INTERVAL_DAYS_DB_ENUM]: TIME_PERIOD_INTERVAL_DAYS_PLAIN_ENGLISH,
  [TIME_PERIOD_INTERVAL_MONTHS_DB_ENUM]:
    TIME_PERIOD_INTERVAL_MONTHS_PLAIN_ENGLISH,
  [TIME_PERIOD_INTERVAL_QUARTERS_DB_ENUM]:
    TIME_PERIOD_INTERVAL_QUARTERS_PLAIN_ENGLISH,
  [TIME_PERIOD_INTERVAL_YEARS_DB_ENUM]:
    TIME_PERIOD_INTERVAL_YEARS_PLAIN_ENGLISH,
  [TIME_PERIOD_INTERVAL_MONTH_TO_DATE_DB_ENUM]:
    TIME_PERIOD_INTERVAL_MONTH_TO_DATE_PLAIN_ENGLISH,
  [TIME_PERIOD_INTERVAL_QUARTER_TO_DATE_DB_ENUM]:
    TIME_PERIOD_INTERVAL_QUARTER_TO_DATE_PLAIN_ENGLISH,
  [TIME_PERIOD_INTERVAL_YEAR_TO_DATE_DB_ENUM]:
    TIME_PERIOD_INTERVAL_YEAR_TO_DATE_PLAIN_ENGLISH,
};

// ------------------------- METRICS  -----------------------
export const METRIC_OCCUPANCY_PLAIN_ENGLISH = "occupancy";
export const METRIC_RENT_GROWTH_PLAIN_ENGLISH = "rent growth";

export const trackedMetrics = [
  // Finished scraping
  METRIC_OCCUPANCY_PLAIN_ENGLISH,
  METRIC_RENT_GROWTH_PLAIN_ENGLISH,

  // TOP IDEAS
  // "occupancy growth"

  // OTHER IDEAS
  // "rent",
  // "cap rate",
  // "NOI margin",
  // "net operating income margin",

  // OTHER OTHER IDEAS
  // "NOI growth",
  // "net operating income growth",
  // "NOI",
  // "net operating income",
  // "rent per unit",
  // "rent per SF",
];

// ------------------------- COMPANY OR MARKET DATA DB ENUM -----------------------
export const COMPANY_OR_MARKET_DATA_DB_ENUM_COMPANY = "COMPANY";
export const COMPANY_OR_MARKET_DATA_DB_ENUM_MARKET = "MARKET";

// ------------------------- COMPARISON PERIODS DB ENUM -----------------------

export const COMPARISON_PERIODS_MOM_DB_ENUM = "MoM";
export const COMPARISON_PERIODS_YOY_DB_ENUM = "YoY";
export const COMPARISON_PERIODS_QOQ_DB_ENUM = "QoQ";
export const COMPARISON_PERIODS_LEASE_OVER_LEASE_DB_ENUM = "LEASE_OVER_LEASE";

export const COMPARISON_PERIODS_MOM_PLAIN_ENGLISH = "month-over-month";
export const COMPARISON_PERIODS_YOY_PLAIN_ENGLISH = "year-over-year";
export const COMPARISON_PERIODS_QOQ_PLAIN_ENGLISH = "quarter-over-quarter";
export const COMPARISON_PERIODS_LEASE_OVER_LEASE_PLAIN_ENGLISH =
  "lease-over-lease";

export const MAP_COMPARISON_PERIODS_DB_ENUM_TO_PLAIN_ENGLISH = {
  [COMPARISON_PERIODS_MOM_DB_ENUM]: COMPARISON_PERIODS_MOM_PLAIN_ENGLISH,
  [COMPARISON_PERIODS_YOY_DB_ENUM]: COMPARISON_PERIODS_YOY_PLAIN_ENGLISH,
  [COMPARISON_PERIODS_QOQ_DB_ENUM]: COMPARISON_PERIODS_QOQ_PLAIN_ENGLISH,
  [COMPARISON_PERIODS_LEASE_OVER_LEASE_DB_ENUM]:
    COMPARISON_PERIODS_LEASE_OVER_LEASE_PLAIN_ENGLISH,
};

export const MAP_COMPARISON_PERIODS_PLAIN_ENGLISH_TO_DB_ENUM = {
  [COMPARISON_PERIODS_MOM_PLAIN_ENGLISH]: COMPARISON_PERIODS_MOM_DB_ENUM,
  [COMPARISON_PERIODS_YOY_PLAIN_ENGLISH]: COMPARISON_PERIODS_YOY_DB_ENUM,
  [COMPARISON_PERIODS_QOQ_PLAIN_ENGLISH]: COMPARISON_PERIODS_QOQ_DB_ENUM,
  [COMPARISON_PERIODS_LEASE_OVER_LEASE_PLAIN_ENGLISH]:
    COMPARISON_PERIODS_LEASE_OVER_LEASE_DB_ENUM,
};

// --------------- COGNITO -------------
export const COGNITO_USER_GROUP_ADMIN = "admin";
export const AUTH_MODE_API_KEY = "API_KEY";
export const AUTH_MODE_AMAZON_COGNITO_USER_POOLS = "AMAZON_COGNITO_USER_POOLS";

// --------------- S3 --------------
export const FILINGS_BUCKET_DEV =
  "authcra99b749856a974566a9085b7b6263d837135757-dev";
export const FILINGS_BUCKET_PROD =
  "authcra99b749856a974566a9085b7b6263d837161819-prod";

// --------------- FIN DATA TABLES --------------------
export const FIN_DATA_TABLE_CAP_TABLE = "CAP_TABLE";
export const FIN_DATA_TABLE_INCOME_STATEMENT = "INCOME_STATEMENT";
export const FIN_DATA_TABLE_BALANCE_SHEET = "BALANCE_SHEET";
export const FIN_DATA_TABLE_CASH_FLOW = "CASH_FLOW";
export const FIN_DATA_TABLE_VALUATION_METRICS = "VALUATION_METRICS";
export const FIN_DATA_TABLE_DISPLAY_ORDER = [
  FIN_DATA_TABLE_CAP_TABLE,
  // FIN_DATA_TABLE_INCOME_STATEMENT,
  // FIN_DATA_TABLE_BALANCE_SHEET,
  // FIN_DATA_TABLE_CASH_FLOW,
  // FIN_DATA_TABLE_VALUATION_METRICS,
];

// ----------------- METRICS -------------------

// Metric Types
export const METRIC_TYPE_COMPUTED = "COMPUTED";
export const METRIC_TYPE_API = "API";
export const METRIC_TYPE_SEC_FILING = "SEC_FILING";
export const METRIC_TYPE_EARNINGS_TRANSCRIPT = "EARNINGS_TRANSCRIPT";

// API Metrics
export const METRIC_API_COMMON_STOCK_SHARE_PRICE = "COMMON_STOCK_SHARE_PRICE";

// Computed Metrics
export const METRIC_COMPUTED_EQUITY_MARKET_CAP_NOT_DILUTED =
  "EQUITY_MARKET_CAP_NOT_DILUTED";
export const METRIC_COMPUTED_EQUITY_MARKET_CAP_FULLY_DILUTED =
  "EQUITY_MARKET_CAP_FULLY_DILUTED";
export const METRIC_COMPUTED_FULLY_DILUTED_SHARES_OUTSTANDING =
  "FULLY_DILUTED_SHARES_OUTSTANDING";
export const METRIC_COMPUTED_OPTIONS_EXERCISABLE_DILUTION =
  "OPTIONS_EXERCISABLE_DILUTION";

// Operations Lookup
export const METRIC_FORMULA_OPERATION_SUM = "SUM";
export const METRIC_FORMULA_OPERATION_PRODUCT = "PRODUCT";
export const METRIC_FORMULA_OPERATION_NEGATIVE = "NEGATIVE";
export const METRIC_FORMULA_OPERATION_RECIPROCAL = "RECIPROCAL";
export const METRIC_FORMULA_OPERATION_MAX = "MAX";
export const METRIC_FORMULA_OPERATION_MIN = "MIN";
export const METRIC_FORMULA_OPERATION_LOOKUP = {
  [METRIC_FORMULA_OPERATION_SUM]: METRIC_FORMULA_OPERATION_SUM,
  [METRIC_FORMULA_OPERATION_PRODUCT]: METRIC_FORMULA_OPERATION_PRODUCT,
  [METRIC_FORMULA_OPERATION_NEGATIVE]: METRIC_FORMULA_OPERATION_NEGATIVE,
  [METRIC_FORMULA_OPERATION_RECIPROCAL]: METRIC_FORMULA_OPERATION_RECIPROCAL,
  [METRIC_FORMULA_OPERATION_MAX]: METRIC_FORMULA_OPERATION_MAX,
  [METRIC_FORMULA_OPERATION_MIN]: METRIC_FORMULA_OPERATION_MIN,
};

// Value Types
export const METRIC_VALUE_TYPE_PERCENTAGE = "PERCENTAGE";
export const METRIC_VALUE_TYPE_USD = "USD";
export const METRIC_VALUE_TYPE_NUMBER = "NUMBER";

// --------------- APP CONSTANTS --------------------
export const NOT_APPLICABLE = "N/A";

// --------------- DYNAMO MODELS --------------------

export const MODEL_COMPANY = "Company";
export const MODEL_COMPANY_FIN_DATA_TABLE_METRIC_ORDER =
  "CompanyFinDataTableMetricOrder";
export const MODEL_DATUM = "Datum";
export const MODEL_DATUM_ARCHIVE = "DatumArchive";
export const MODEL_EARNINGS_RELEASE_DATE = "EarningsReleaseDate";
export const MODEL_EARNINGS_TRANSCRIPT = "EarningsTranscript";
export const MODEL_FINANCIAL_STATEMENT = "FinancialStatement";
export const MODEL_FINANCIAL_STATEMENT_TYPE = "FinancialStatementType";
export const MODEL_FIN_DATA_TABLE = "FinDataTable";
export const MODEL_METRIC = "Metric";
export const MODEL_METRIC_FIN_DATA_TABLE = "MetricFinDataTable";
export const MODEL_TEN_Q_OR_K = "TenQOrK";
export const MODEL_XBRL_TAG = "XBRLTag";
export const MODEL_XBRL_TAG_TYPE = "XBRLTagType";
export const MODEL_XSD_FILE = "XSDFile";
