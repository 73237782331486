import actionTypes from "../actions/actionTypes";

const initialState = {
  metricsLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setMetrics(state, metricsArray) {
  const metricsLookup = metricsArray.reduce((accum, currValue) => {
    accum[currValue.id] = currValue;
    return accum;
  }, {});
  return { ...state, metricsLookup };
}

function createMetric(state, metric) {
  const metricsLookup = { ...state.metricsLookup };
  metricsLookup[metric.id] = metric;
  return { ...state, metricsLookup };
}

function updateMetric(state, metric) {
  const metricsLookup = { ...state.metricsLookup };
  metricsLookup[metric.id] = metric;
  return { ...state, metricsLookup };
}

// -----------------------------------------------------
// Reducer

function metricsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_METRICS:
      return setMetrics(state, action.payload);

    case actionTypes.CREATE_METRIC:
      return createMetric(state, action.payload);

    case actionTypes.UPDATE_METRIC:
      return updateMetric(state, action.payload);

    default:
      return state;
  }
}

export default metricsReducer;
