import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AuthHelper from "../../helpers/authHelper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../routes";
import { setAuthenticatedUser } from "../../actions/authActions";
import { Hub } from "aws-amplify";
import AuthUser from "../../helpers/authUser";
import { useSelector } from "react-redux";
import { SECONDARY_COLOR } from "../../colors";

const useStyles = makeStyles({
  mustConfirmEmailToLoginMessage: {
    marginBottom: 10,
    fontSize: 14,
  },
  field: {
    marginTop: 10,
    marginBottom: 10,
    display: "block",
  },
  button: {
    marginTop: 10,
    marginBottom: 10,
  },
  errorMessage: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14,
  },
  resendCodeContainer: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14,
    display: "flex",
  },
  resendCodePreamble: {
    fontSize: 14,
  },
  resendCode: {
    marginLeft: 5,
    textDecoration: "underline",
    color: SECONDARY_COLOR,
    fontSize: 14,
    cursor: "pointer",
  },
});

const CONFIRM_EMAIL_SUCCESS = "SUCCESS";

/**
 * COMPONENT
 */
const ConfirmEmail = (props) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState(null);
  const [genericConfirmEmailError, setGenericConfirmEmailError] =
    useState(null);
  const [mustConfirmEmailToLoginMessage, setMustConfirmEmailToLoginMessage] =
    useState(null);
  const [isConfirmingEmail, setIsConfirmingEmail] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  useEffect(() => {
    if (authenticatedUser) {
      history.push(routes.HOME);
      return;
    }
  }, [authenticatedUser, history]);

  async function listenToAutoSignInEvent() {
    Hub.listen("auth", async ({ payload }) => {
      setIsSigningIn(true);
      const { event } = payload;
      if (event === "autoSignIn") {
        const signInResult = payload.data;
        if (signInResult) {
          const authUser = new AuthUser(
            signInResult.attributes.sub,
            signInResult.username,
            signInResult.signInUserSession.accessToken.payload["cognito:groups"]
          );
          await setAuthenticatedUser(dispatch, authUser);
        }
        setIsSigningIn(false);
      } else if (event === "autoSignIn_failure") {
        setConfirmEmailError(
          "Email has been confirmed but we encountered an error logging you in. Please manually navigate to the login page and try again."
        );
        setIsConfirmingEmail(false);
        setIsSigningIn(false);
      }
    });
  }

  useEffect(() => {
    const checkLocation = async () => {
      if (
        location &&
        location.state &&
        Array.isArray(location.state) &&
        location.state.length > 0
      ) {
        if (location.state[0].username) {
          setUsername(location.state[0].username);
          if (
            location.state[0].cameFrom &&
            location.state[0].cameFrom.pathname &&
            location.state[0].cameFrom.pathname === routes.LOGIN
          ) {
            try {
              await AuthHelper.Instance().resendConfirmationCode(
                location.state[0].username
              );
            } catch (err) {
              setConfirmEmailError(err.message);
            }
            setMustConfirmEmailToLoginMessage(
              "You must confirm your email in order to login. Please check your an email for a new confirmation code that we just sent"
            );
          }
        }
      }
    };
    checkLocation();
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsConfirmingEmail(true);

    let confirmEmailResponse;
    try {
      confirmEmailResponse = await AuthHelper.Instance().confirmSignUp(
        username,
        confirmationCode
      );
      setConfirmEmailError(null);
    } catch (err) {
      setConfirmEmailError(err.message);
    }

    if ((confirmEmailResponse = CONFIRM_EMAIL_SUCCESS)) {
      await listenToAutoSignInEvent();
    } else {
      setGenericConfirmEmailError(
        "Encountered an error confirming your email. Please try again"
      );
      setIsConfirmingEmail(false);
    }
  };

  const handleResendCode = async (event) => {
    event.preventDefault();
    try {
      await AuthHelper.Instance().resendConfirmationCode(username);
    } catch (err) {
      setConfirmEmailError(err.message);
    }
  };

  return isConfirmingEmail || isSigningIn ? (
    <CircularProgress />
  ) : (
    <div>
      <form onSubmit={handleSubmit} name={"confirm email"}>
        {!!mustConfirmEmailToLoginMessage ? (
          <Typography className={classes.mustConfirmEmailToLoginMessage}>
            {mustConfirmEmailToLoginMessage}
          </Typography>
        ) : null}
        <div>
          <TextField
            label="Confirmation code"
            variant="outlined"
            name="confirmEmail"
            type="confirmEmail"
            className={classes.field}
            onChange={(e) => setConfirmationCode(e.target.value)}
          />
        </div>
        <div>
          <Button
            type="submit"
            variant="outlined"
            className={classes.button}
            onClick={handleSubmit}
          >
            Confirm email
          </Button>

          <div className={classes.resendCodeContainer}>
            <Typography className={classes.resendCodePreamble}>
              Didn't receive a code?{" "}
            </Typography>
            <Typography
              className={classes.resendCode}
              onClick={handleResendCode}
            >
              Resend code
            </Typography>
          </div>

          {confirmEmailError ? (
            <Typography className={classes.errorMessage}>
              Failed email conirmation. Error message: {confirmEmailError}
            </Typography>
          ) : genericConfirmEmailError ? (
            <Typography className={classes.errorMessage}>
              Failed email conirmation. Error message:{" "}
              {genericConfirmEmailError}
            </Typography>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default ConfirmEmail;
