import { gql } from "graphql-tag";
import {
  CompanyFragment,
  CompanyFinDataTableMetricOrderFragment,
  DatumFragment,
  DatumArchiveFragment,
  EarningsReleaseDateFragment,
  EarningsTranscriptFragment,
  FinancialStatementFragment,
  FinancialStatementTypeFragment,
  FinDataTableFragment,
  MetricFragment,
  MetricFinDataTableFragment,
  TenQOrKFragment,
  XBRLTagFragment,
  XBRLTagTypeFragment,
  XSDFileFragment,
} from "./fragments";

// --------------------- CREATE MUTATIONS --------------------------

export const createCompanyMutation = gql`
  ${CompanyFragment}
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      ...CompanyFragment
    }
  }
`;

export const createCompanyFinDataTableMetricOrderMutation = gql`
  ${CompanyFinDataTableMetricOrderFragment}
  mutation CreateCompanyFinDataTableMetricOrder(
    $input: CreateCompanyFinDataTableMetricOrderInput!
  ) {
    createCompanyFinDataTableMetricOrder(input: $input) {
      ...CompanyFinDataTableMetricOrderFragment
    }
  }
`;

export const createDatumMutation = gql`
  ${DatumFragment}
  mutation CreateDatum($input: CreateDatumInput!) {
    createDatum(input: $input) {
      ...DatumFragment
    }
  }
`;

export const createDatumArchiveMutation = gql`
  ${DatumArchiveFragment}
  mutation CreateDatumArchive($input: CreateDatumArchiveInput!) {
    createDatumArchive(input: $input) {
      ...DatumArchiveFragment
    }
  }
`;

export const createEarningsReleaseDateMutation = gql`
  ${EarningsReleaseDateFragment}
  mutation CreateEarningsReleaseDate($input: CreateEarningsReleaseDateInput!) {
    createEarningsReleaseDate(input: $input) {
      ...EarningsReleaseDateFragment
    }
  }
`;

export const createEarningsTranscriptMutation = gql`
  ${EarningsTranscriptFragment}
  mutation CreateEarningsTranscript($input: CreateEarningsTranscriptInput!) {
    createEarningsTranscript(input: $input) {
      ...EarningsTranscriptFragment
    }
  }
`;

export const createFinancialStatementMutation = gql`
  ${FinancialStatementFragment}
  mutation CreateFinancialStatement($input: CreateFinancialStatementInput!) {
    createFinancialStatement(input: $input) {
      ...FinancialStatementFragment
    }
  }
`;

export const createFinancialStatementTypeMutation = gql`
  ${FinancialStatementTypeFragment}
  mutation CreateFinancialStatementType(
    $input: CreateFinancialStatementTypeInput!
  ) {
    createFinancialStatementType(input: $input) {
      ...FinancialStatementTypeFragment
    }
  }
`;

export const createFinDataTableMutation = gql`
  ${FinDataTableFragment}
  mutation CreateFinDataTable($input: CreateFinDataTableInput!) {
    createFinDataTable(input: $input) {
      ...FinDataTableFragment
    }
  }
`;

export const createMetricMutation = gql`
  ${MetricFragment}
  mutation CreateMetric($input: CreateMetricInput!) {
    createMetric(input: $input) {
      ...MetricFragment
    }
  }
`;

export const createMetricFinDataTableMutation = gql`
  ${MetricFinDataTableFragment}
  mutation CreateMetricFinDataTable($input: CreateMetricFinDataTableInput!) {
    createMetricFinDataTable(input: $input) {
      ...MetricFinDataTableFragment
    }
  }
`;

export const createTenQOrKMutation = gql`
  ${TenQOrKFragment}
  mutation CreateTenQOrK($input: CreateTenQOrKInput!) {
    createTenQOrK(input: $input) {
      ...TenQOrKFragment
    }
  }
`;

export const createXbrlTagMutation = gql`
  ${XBRLTagFragment}
  mutation CreateXBRLTag($input: CreateXBRLTagInput!) {
    createXBRLTag(input: $input) {
      ...XBRLTagFragment
    }
  }
`;

export const createXbrlTagTypeMutation = gql`
  ${XBRLTagTypeFragment}
  mutation CreateXBRLTagType($input: CreateXBRLTagTypeInput!) {
    createXBRLTagType(input: $input) {
      ...XBRLTagTypeFragment
    }
  }
`;

export const createXsdFileMutation = gql`
  ${XSDFileFragment}
  mutation CreateXSDFile($input: CreateXSDFileInput!) {
    createXSDFile(input: $input) {
      ...XSDFileFragment
    }
  }
`;

// --------------------- UPDATE MUTATIONS ----------------

export const updateCompanyMutation = gql`
  ${CompanyFragment}
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      ...CompanyFragment
    }
  }
`;

export const updateCompanyFinDataTableMetricOrderMutation = gql`
  ${CompanyFinDataTableMetricOrderFragment}
  mutation UpdateCompanyFinDataTableMetricOrder(
    $input: UpdateCompanyFinDataTableMetricOrderInput!
  ) {
    updateCompanyFinDataTableMetricOrder(input: $input) {
      ...CompanyFinDataTableMetricOrderFragment
    }
  }
`;

export const updateDatumMutation = gql`
  ${DatumFragment}
  mutation UpdateDatum($input: UpdateDatumInput!) {
    updateDatum(input: $input) {
      ...DatumFragment
    }
  }
`;

export const updateDatumArchiveMutation = gql`
  ${DatumArchiveFragment}
  mutation UpdateDatumArchive($input: UpdateDatumArchiveInput!) {
    updateDatumArchive(input: $input) {
      ...DatumArchiveFragment
    }
  }
`;

export const updateEarningsReleaseDateMutation = gql`
  ${EarningsReleaseDateFragment}
  mutation UpdateEarningsReleaseDate($input: UpdateEarningsReleaseDateInput!) {
    updateEarningsReleaseDate(input: $input) {
      ...EarningsReleaseDateFragment
    }
  }
`;

export const updateEarningsTranscriptMutation = gql`
  ${EarningsTranscriptFragment}
  mutation UpdateEarningsTranscript($input: UpdateEarningsTranscriptInput!) {
    updateEarningsTranscript(input: $input) {
      ...EarningsTranscriptFragment
    }
  }
`;

export const updateFinancialStatementMutation = gql`
  ${FinancialStatementFragment}
  mutation UpdateFinancialStatement($input: UpdateFinancialStatementInput!) {
    updateFinancialStatement(input: $input) {
      ...FinancialStatementFragment
    }
  }
`;

export const updateFinancialStatementTypeMutation = gql`
  ${FinancialStatementTypeFragment}
  mutation UpdateFinancialStatementType(
    $input: UpdateFinancialStatementTypeInput!
  ) {
    updateFinancialStatementType(input: $input) {
      ...FinancialStatementTypeFragment
    }
  }
`;

export const updateFinDataTableMutation = gql`
  ${FinDataTableFragment}
  mutation UpdateFinDataTable($input: UpdateFinDataTableInput!) {
    updateFinDataTable(input: $input) {
      ...FinDataTableFragment
    }
  }
`;

export const updateMetricMutation = gql`
  ${MetricFragment}
  mutation UpdateMetric($input: UpdateMetricInput!) {
    updateMetric(input: $input) {
      ...MetricFragment
    }
  }
`;

export const updateMetricFinDataTableMutation = gql`
  ${MetricFinDataTableFragment}
  mutation UpdateMetricFinDataTable($input: UpdateMetricFinDataTableInput!) {
    updateMetricFinDataTable(input: $input) {
      ...MetricFinDataTableFragment
    }
  }
`;

export const updateTenQOrKMutation = gql`
  ${TenQOrKFragment}
  mutation UpdateTenQOrK($input: UpdateTenQOrKInput!) {
    updateTenQOrK(input: $input) {
      ...TenQOrKFragment
    }
  }
`;

export const updateXbrlTagMutation = gql`
  ${XBRLTagFragment}
  mutation UpdateXBRLTag($input: UpdateXBRLTagInput!) {
    updateXBRLTag(input: $input) {
      ...XBRLTagFragment
    }
  }
`;

export const updateXbrlTagTypeMutation = gql`
  ${XBRLTagTypeFragment}
  mutation UpdateXBRLTagType($input: UpdateXBRLTagTypeInput!) {
    updateXBRLTagType(input: $input) {
      ...XBRLTagTypeFragment
    }
  }
`;

export const updateXsdFileMutation = gql`
  ${XSDFileFragment}
  mutation UpdateXSDFile($input: UpdateXSDFileInput!) {
    updateXSDFile(input: $input) {
      ...XSDFileFragment
    }
  }
`;

// --------------------- DELETE MUTATIONS ----------------

export const deleteCompanyMutation = gql`
  ${CompanyFragment}
  mutation DeleteCompany($input: DeleteCompanyInput!) {
    deleteCompany(input: $input) {
      ...CompanyFragment
    }
  }
`;

export const deleteCompanyFinDataTableMetricOrderMutation = gql`
  ${CompanyFinDataTableMetricOrderFragment}
  mutation DeleteCompanyFinDataTableMetricOrder(
    $input: DeleteCompanyFinDataTableMetricOrderInput!
  ) {
    deleteCompanyFinDataTableMetricOrder(input: $input) {
      ...CompanyFinDataTableMetricOrderFragment
    }
  }
`;

export const deleteDatumMutation = gql`
  ${DatumFragment}
  mutation DeleteDatum($input: DeleteDatumInput!) {
    deleteDatum(input: $input) {
      ...DatumFragment
    }
  }
`;

export const deleteDatumArchiveMutation = gql`
  ${DatumArchiveFragment}
  mutation DeleteDatumArchive($input: DeleteDatumArchiveInput!) {
    deleteDatumArchive(input: $input) {
      ...DatumArchiveFragment
    }
  }
`;

export const deleteEarningsReleaseDateMutation = gql`
  ${EarningsReleaseDateFragment}
  mutation DeleteEarningsReleaseDate($input: DeleteEarningsReleaseDateInput!) {
    deleteEarningsReleaseDate(input: $input) {
      ...EarningsReleaseDateFragment
    }
  }
`;

export const deleteEarningsTranscriptMutation = gql`
  ${EarningsTranscriptFragment}
  mutation DeleteEarningsTranscript($input: DeleteEarningsTranscriptInput!) {
    deleteEarningsTranscript(input: $input) {
      ...EarningsTranscriptFragment
    }
  }
`;

export const deleteFinancialStatementMutation = gql`
  ${FinancialStatementFragment}
  mutation DeleteFinancialStatement($input: DeleteFinancialStatementInput!) {
    deleteFinancialStatement(input: $input) {
      ...FinancialStatementFragment
    }
  }
`;

export const deleteFinancialStatementTypeMutation = gql`
  ${FinancialStatementTypeFragment}
  mutation DeleteFinancialStatementType(
    $input: DeleteFinancialStatementTypeInput!
  ) {
    deleteFinancialStatementType(input: $input) {
      ...FinancialStatementTypeFragment
    }
  }
`;

export const deleteFinDataTableMutation = gql`
  ${FinDataTableFragment}
  mutation DeleteFinDataTable($input: DeleteFinDataTableInput!) {
    deleteFinDataTable(input: $input) {
      ...FinDataTableFragment
    }
  }
`;

export const deleteMetricMutation = gql`
  ${MetricFragment}
  mutation DeleteMetric($input: DeleteMetricInput!) {
    deleteMetric(input: $input) {
      ...MetricFragment
    }
  }
`;

export const deleteMetricFinDataTableMutation = gql`
  ${MetricFinDataTableFragment}
  mutation DeleteMetricFinDataTable($input: DeleteMetricFinDataTableInput!) {
    deleteMetricFinDataTable(input: $input) {
      ...MetricFinDataTableFragment
    }
  }
`;

export const deleteTenQOrKMutation = gql`
  ${TenQOrKFragment}
  mutation DeleteTenQOrK($input: DeleteTenQOrKInput!) {
    deleteTenQOrK(input: $input) {
      ...TenQOrKFragment
    }
  }
`;

export const deleteXbrlTagMutation = gql`
  ${XBRLTagFragment}
  mutation DeleteXBRLTag($input: DeleteXBRLTagInput!) {
    deleteXBRLTag(input: $input) {
      ...XBRLTagFragment
    }
  }
`;

export const deleteXbrlTagTypeMutation = gql`
  ${XBRLTagTypeFragment}
  mutation DeleteXBRLTagType($input: DeleteXBRLTagTypeInput!) {
    deleteXBRLTagType(input: $input) {
      ...XBRLTagTypeFragment
    }
  }
`;

export const deleteXsdFileMutation = gql`
  ${XSDFileFragment}
  mutation DeleteXSDFile($input: DeleteXSDFileInput!) {
    deleteXSDFile(input: $input) {
      ...XSDFileFragment
    }
  }
`;
