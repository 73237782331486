// Constants
import {
  METRIC_FORMULA_OPERATION_SUM,
  METRIC_FORMULA_OPERATION_PRODUCT,
  METRIC_FORMULA_OPERATION_NEGATIVE,
  METRIC_FORMULA_OPERATION_RECIPROCAL,
  METRIC_FORMULA_OPERATION_MAX,
  METRIC_FORMULA_OPERATION_MIN,
  NOT_APPLICABLE,
} from "../constants";
import { generateRandomHexcodeColorString } from "./colorHelpers";

// Classes
import FinDataTableCell from "../classes/FinDataTableCell";
import FinDataTableHighlightedCell from "../classes/FinDataTableHighlightedCell";

// Helpers
import { formatMetric } from "./formatNumberHelpers";

// ---------- Main export function ----------

export function updateFinDataTableForComputedMetric(
  finDataTable,
  updatedData,
  selectedCompanyStockPriceData,
  selectedCompanyStockPriceHistory,
  rowIdx,
  rowHeader,
  metricsLookup,
  filingsLookup,
  updateHighlightedCell,
  updateHighlightedSourceCells
) {
  const metric = metricsLookup[rowHeader?.metadata?.metricId];

  finDataTable.columnHeaders.forEach((columnHeader, columnIdx) => {
    const metricFormula = rowHeader?.metadata?.metricFormula;
    const metricsPlusDatumValueUsedInFormulaLookup = {};

    const metricIdsAndRowIndexesUsedInFormula =
      gatherAllMetricIdsAndRowIndexesFromMetricFormula(
        metricFormula,
        finDataTable
      );

    // Fill out the metricsPlusDatumValueUsedInFormulaLookup
    metricIdsAndRowIndexesUsedInFormula.forEach(({ metricId, rowIndex }) => {
      let datumValue;
      try {
        const tableEntry = updatedData[rowIndex][columnIdx]?.value;
        datumValue = parseStringAsNumberIfPossible(tableEntry);
      } catch (err) {
        datumValue = 0; // Fallback to 0 if there's an issue parsing
      }

      metricsPlusDatumValueUsedInFormulaLookup[metricId] = {
        ...metricsLookup[metricId],
        datumValue: datumValue,
      };
    });

    const {
      metric: calculatedMetric,
      metricFormulaString: calculatedMetricFormulaString,
      highlightedSourceCells: highlightedSourceCellsSimple,
    } = calculateMetricAndMetricFormulaStringAndHighlightedSourceCellsUsingMetricFormula(
      finDataTable,
      metricFormula,
      metricsPlusDatumValueUsedInFormulaLookup,
      metricIdsAndRowIndexesUsedInFormula,
      // Adding +1 for the rowHeaders
      columnIdx + 1
    );

    // Log the calculated metric and its formula string

    const highlightedSourceCells =
      convertHighlightedSourceCellsSimpleToHighlightedSourceCellClassInstances(
        highlightedSourceCellsSimple
      );

    // Convert indices to Excel-style cell address for updating and logging

    // Adding +1 for the rowHeaders
    const columnAddress =
      finDataTable.transformNumericColumnIndexToAlphanumeric(columnIdx + 1);
    // Adding +1 for the columnHeaders
    const rowAddress = finDataTable.transformNumericRowIndexToAlphanumeric(
      parseInt(rowIdx) + 1
    );
    const cellAddress = `${columnAddress}${rowAddress}`;

    // Update the cell with the calculated metric and possibly include formula string if needed
    updatedData[rowIdx][columnIdx] = new FinDataTableCell(
      formatMetric(calculatedMetric, metric?.valueType),
      calculatedMetricFormulaString, // Assuming you have a way to display this or want to store it for later use
      undefined,
      () => {
        finDataTable.highlightedCell = new FinDataTableHighlightedCell(
          undefined,
          cellAddress,
          {
            calculatedMetricFormulaString: calculatedMetricFormulaString,
          } // Assuming this supports showing the formula string on highlight or another mechanism
        );
        finDataTable.highlightedSourceCells = highlightedSourceCells;
        updateHighlightedCell(cellAddress);
        updateHighlightedSourceCells(highlightedSourceCells);
      }
    );
  });
}

function calculateMetricAndMetricFormulaStringAndHighlightedSourceCellsUsingMetricFormula(
  finDataTable,
  metricFormula,
  metricsPlusDataUsedInFormulaLookup,
  metricIdsAndRowIndexesUsedInFormula,
  columnIdx
) {
  let highlightedSourceCells = []; // Initialize the array to collect highlighted cell addresses

  function parseAndCalculate(formula, depth = 0, parentOperation = "") {
    if (typeof formula === "number") {
      return {
        value: formula,
        formulaString: formula.toString(),
        highlightedCells: [],
      };
    }

    if (typeof formula === "string") {
      const metricData = metricsPlusDataUsedInFormulaLookup[formula];
      const rowIndex =
        metricIdsAndRowIndexesUsedInFormula.find(
          (mi) => mi.metricId === formula
        )?.rowIndex ?? -1;
      // Convert indices to Excel-style addresses
      const columnAddress =
        finDataTable.transformNumericColumnIndexToAlphanumeric(columnIdx);
      const rowAddress = finDataTable.transformNumericRowIndexToAlphanumeric(
        rowIndex + 1
      ); // Adjusting for Excel's 1-based indexing
      const formulaPart =
        rowIndex !== -1 ? `${columnAddress}${rowAddress}` : NOT_APPLICABLE;
      if (rowIndex !== -1) highlightedSourceCells.push(formulaPart); // Collect cell address if valid
      return {
        value: metricData ? metricData.datumValue : NOT_APPLICABLE,
        formulaString:
          metricData && metricData.datumValue !== NOT_APPLICABLE
            ? formulaPart
            : NOT_APPLICABLE,
        highlightedCells: rowIndex !== -1 ? [formulaPart] : [],
      };
    }

    // The rest remains unchanged, handling the operation cases as before, with the addition of collecting highlightedCells
    const operation = Object.keys(formula)[0];
    const operands = formula[operation];
    let operandValues = operands.map((op) =>
      parseAndCalculate(op, depth + 1, operation)
    );

    let operationResult;
    let addParentheses =
      parentOperation &&
      parentOperation !== operation &&
      operation !== METRIC_FORMULA_OPERATION_NEGATIVE &&
      operation !== METRIC_FORMULA_OPERATION_RECIPROCAL;

    switch (operation) {
      // Example for METRIC_FORMULA_OPERATION_SUM:
      case METRIC_FORMULA_OPERATION_SUM:
        operationResult = operandValues.reduce(
          (acc, curr) => ({
            value:
              (acc.value === NOT_APPLICABLE ? 0 : acc.value) +
              (curr.value === NOT_APPLICABLE ? 0 : curr.value),
            formulaString: acc.formulaString
              ? `${acc.formulaString}+${curr.formulaString}`
              : curr.formulaString,
            highlightedCells: acc.highlightedCells.concat(
              curr.highlightedCells
            ), // Collect highlightedCells
          }),
          { value: 0, formulaString: "", highlightedCells: [] }
        );
        break;
      case METRIC_FORMULA_OPERATION_PRODUCT:
        operationResult = operandValues.reduce(
          (acc, curr) => ({
            value:
              acc.value === NOT_APPLICABLE || curr.value === NOT_APPLICABLE
                ? NOT_APPLICABLE
                : acc.value * curr.value,
            formulaString: acc.formulaString
              ? `${acc.formulaString}*${curr.formulaString}`
              : curr.formulaString,
            highlightedCells: acc.highlightedCells.concat(
              curr.highlightedCells
            ), // Collect highlightedCells from current operand
          }),
          { value: 1, formulaString: "", highlightedCells: [] }
        );

        // Only add parentheses if necessary due to operation precedence
        if (addParentheses) {
          operationResult.formulaString = `(${operationResult.formulaString})`;
        }
        break;

      case METRIC_FORMULA_OPERATION_NEGATIVE:
        const negOperand = parseAndCalculate(operands[0], depth + 1);
        operationResult = {
          value:
            negOperand.value === NOT_APPLICABLE
              ? NOT_APPLICABLE
              : -negOperand.value,
          formulaString: `(-${negOperand.formulaString})`,
          highlightedCells: negOperand.highlightedCells, // Directly use highlightedCells from the negated operand
        };
        break;

      case METRIC_FORMULA_OPERATION_RECIPROCAL:
        let recOperand = parseAndCalculate(operands[0], depth + 1);
        operationResult = {
          value:
            recOperand.value === NOT_APPLICABLE || recOperand.value === 0
              ? NOT_APPLICABLE
              : 1 / recOperand.value,
          formulaString: `(1/${recOperand.formulaString})`,
          highlightedCells: recOperand.highlightedCells, // Use highlightedCells from the operand for reciprocal calculation
        };
        break;

      case METRIC_FORMULA_OPERATION_MAX:
        operationResult = operandValues.reduce(
          (acc, curr) => {
            // Determine the max value and corresponding formula string and highlightedCells
            if (acc.value === NOT_APPLICABLE) {
              return curr; // If the accumulator is not applicable, use the current value
            } else if (curr.value === NOT_APPLICABLE) {
              return acc; // If the current value is not applicable, keep the accumulator as is
            } else {
              return {
                value: Math.max(acc.value, curr.value),
                formulaString:
                  acc.value >= curr.value
                    ? acc.formulaString
                    : curr.formulaString,
                highlightedCells:
                  acc.value >= curr.value
                    ? acc.highlightedCells
                    : curr.highlightedCells,
              };
            }
          },
          {
            value: NOT_APPLICABLE,
            formulaString: NOT_APPLICABLE,
            highlightedCells: [],
          }
        );

        // Update formulaString to reflect the MAX operation with all operands involved
        operationResult.formulaString = `MAX(${operandValues
          .map((op) => op.formulaString)
          .join(",")})`;
        // Combine all highlightedCells from operands, as they all contribute to determining the max
        operationResult.highlightedCells = operandValues.reduce(
          (acc, curr) => acc.concat(curr.highlightedCells),
          []
        );
        break;

      case METRIC_FORMULA_OPERATION_MIN:
        operationResult = operandValues.reduce(
          (acc, curr) => {
            // Determine the min value and corresponding formula string and highlightedCells
            if (acc.value === NOT_APPLICABLE) {
              return curr; // If the accumulator is not applicable, use the current value
            } else if (curr.value === NOT_APPLICABLE) {
              return acc; // If the current value is not applicable, keep the accumulator as is
            } else {
              return {
                value: Math.min(acc.value, curr.value),
                formulaString:
                  acc.value <= curr.value
                    ? acc.formulaString
                    : curr.formulaString,
                highlightedCells:
                  acc.value <= curr.value
                    ? acc.highlightedCells
                    : curr.highlightedCells,
              };
            }
          },
          {
            value: NOT_APPLICABLE,
            formulaString: NOT_APPLICABLE,
            highlightedCells: [],
          }
        );

        // Update formulaString to reflect the MIN operation with all operands involved
        operationResult.formulaString = `MIN(${operandValues
          .map((op) => op.formulaString)
          .join(",")})`;
        // Combine all highlightedCells from operands, as they all contribute to determining the min
        operationResult.highlightedCells = operandValues.reduce(
          (acc, curr) => acc.concat(curr.highlightedCells),
          []
        );
        break;

      default:
        operationResult = {
          value: NOT_APPLICABLE,
          formulaString: NOT_APPLICABLE,
          highlightedCells: [],
        };
        break;
    }

    return (
      operationResult || {
        value: NOT_APPLICABLE,
        formulaString: NOT_APPLICABLE,
        highlightedCells: [],
      }
    );
  }

  const result = parseAndCalculate(metricFormula);
  highlightedSourceCells = result.highlightedCells; // Collect final highlightedCells
  return {
    metric: result.value,
    metricFormulaString: "=" + result.formulaString,
    highlightedSourceCells, // Return the collected cell addresses involved in the calculation
  };
}

// ---------- Helper functions ----------

// Renamed and updated function to gather both metric IDs and their row indexes
function gatherAllMetricIdsAndRowIndexesFromMetricFormula(
  metricFormula,
  finDataTable
) {
  const metricsAndIndexes = [];

  // Function to recursively extract METRIC_IDs and their row indexes
  function extractIdsAndIndexes(obj) {
    if (Array.isArray(obj)) {
      obj.forEach((element) => {
        extractIdsAndIndexes(element);
      });
    } else if (typeof obj === "object" && obj !== null) {
      Object.values(obj).forEach((value) => {
        if (Array.isArray(value)) {
          extractIdsAndIndexes(value);
        }
      });
    } else if (typeof obj === "string") {
      // Find the row index for the metric ID in finDataTable
      const rowIndex = finDataTable.rowHeaders.findIndex(
        (rowHeader) => rowHeader?.metadata?.metricId === obj
      );
      metricsAndIndexes.push({ metricId: obj, rowIndex });
    }
  }

  extractIdsAndIndexes(metricFormula);
  return metricsAndIndexes;
}

export function parseStringAsNumberIfPossible(tableEntry) {
  const NOT_APPLICABLE = "N/A"; // Assuming this is your NOT_APPLICABLE value

  // Directly return the NOT_APPLICABLE value without further checks
  if (tableEntry === NOT_APPLICABLE) {
    return NOT_APPLICABLE;
  }

  // Remove common formatting characters
  const normalizedEntry = tableEntry.replace(/[$,]/g, "");

  // Use a stricter numeric check before deciding the return value
  if (!isNaN(normalizedEntry) && normalizedEntry.trim() !== "") {
    // The entry is numeric after normalization, parse it as a float
    return parseFloat(normalizedEntry);
  } else {
    // The entry is not numeric or empty after normalization, return as is
    return tableEntry;
  }
}

function convertHighlightedSourceCellsSimpleToHighlightedSourceCellClassInstances(
  highlightedSourceCellsSimple
) {
  return highlightedSourceCellsSimple.map((cellAddress) => {
    const randomColor = generateRandomHexcodeColorString();
    return new FinDataTableHighlightedCell(
      undefined,
      cellAddress,
      undefined,
      true,
      randomColor
    );
  });
}
