import actionTypes from "../actions/actionTypes";

const initialState = {
  transcriptsLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setTranscripts(state, transcriptsArray) {
  const transcriptsLookup = transcriptsArray.reduce((accum, currValue) => {
    accum[currValue.id] = currValue;
    return accum;
  }, {});
  return { ...state, transcriptsLookup };
}

function updateTranscript(state, updatedTranscript) {
  return {
    ...state,
    transcriptsLookup: {
      ...state.transcriptsLookup,
      [updatedTranscript.id]: updatedTranscript,
    },
  };
}

// -----------------------------------------------------
// Reducer

function transcriptsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_TRANSCRIPTS:
      return setTranscripts(state, action.payload);
    case actionTypes.UPDATE_TRANSCRIPT:
      return updateTranscript(state, action.payload);

    default:
      return state;
  }
}

export default transcriptsReducer;
