import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { v4 as uuidv4 } from "uuid";
import TextField from "@material-ui/core/TextField";

// Constants
import { METRIC_FORMULA_OPERATION_LOOKUP } from "../../constants";

// Components
import FinDataSelectInput from "../FinDataSelectInput";
import FinDataExpand from "../FinDataExpand";

const useStyles = makeStyles((theme) => ({
  createNewMetricFormula: {
    backgroundColor: "white",
    padding: "8px",
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  subTitle: {
    fontWeight: "bold",
  },
}));

function CreateNewMetricFormula(props) {
  const classes = useStyles();
  const metricsLookup = useSelector((state) => state.metrics.metricsLookup);

  const [showMetricFormulaFields, setShowMetricFormulaFields] = useState(false);
  const [operationAdded, setOperationAdded] = useState(false);

  // State to manage the formula components
  const [formulaComponents, setFormulaComponents] = useState([]);

  // Function to add a new metric, operation, or number
  const addComponent = (type, parentId = null) => {
    if (type === "number") {
      const numberValue = prompt("Enter a number:"); // Use a prompt to get the number value
      // Parse the input to ensure it is stored as a number
      const parsedValue = parseFloat(numberValue);
      if (!isNaN(parsedValue)) {
        // Check if the parsed value is a valid number
        const newComponent = {
          id: uuidv4(),
          type,
          value: parsedValue,
          parentId,
        }; // Store as a number
        setFormulaComponents([...formulaComponents, newComponent]);
      } else {
        alert("Please enter a valid number."); // Alert if not a valid number
        return; // Exit if no valid number is entered
      }
    } else {
      const newComponent = { id: uuidv4(), type, value: type, parentId };
      setFormulaComponents([...formulaComponents, newComponent]);
    }
    if (type === "operation") {
      setOperationAdded(true);
    }
  };

  // Function to update the value of a component, adjusted for handling number updates
  const updateComponentValue = (id, value) => {
    setFormulaComponents(
      formulaComponents.map((component) => {
        if (component.id === id) {
          // For numbers, ensure the updated value is valid before applying
          if (component.type === "number" && isNaN(value)) {
            return component; // Skip update if the new value is not a valid number
          }
          return { ...component, value };
        }
        return component;
      })
    );
  };

  // Function to handle the creation of the formula JSON
  const handleCreateFormula = () => {
    const formulaJson = buildFormulaJson();
    props.onCreate(formulaJson);

    setShowMetricFormulaFields(false);
  };

  // Recursive function to build each component of the formula
  const buildComponentJson = (componentId) => {
    const component = formulaComponents.find((c) => c.id === componentId);

    if (!component) {
      return null;
    }

    if (component.type === "operation") {
      const children = formulaComponents.filter(
        (c) => c.parentId === componentId
      );
      if (children.length === 0) {
        return null;
      }

      const operationType = component.value.toUpperCase();
      return {
        [operationType]: children.map((child) => buildComponentJson(child.id)),
      };
    } else {
      // Assuming it's a metric
      return component.value; // Metric ID
    }
  };

  // Function to build the formula JSON
  const buildFormulaJson = () => {
    const topParent = formulaComponents.find((c) => c.parentId === null);

    if (!topParent) {
      return {}; // or throw an error if a parent is expected
    }

    // Construct the JSON for the topmost parent
    const formulaJson = buildComponentJson(topParent.id);

    return JSON.stringify(formulaJson);
  };

  // Render formula components, including number inputs
  const renderFormulaComponents = (parentId = null) => {
    return formulaComponents
      .filter((component) => component.parentId === parentId)
      .map((component) => (
        <div key={component.id} style={{ marginBottom: "10px" }}>
          {component.type === "metric" || component.type === "operation" ? (
            <FinDataSelectInput
              lookupObject={
                component.type === "metric"
                  ? metricsLookup
                  : METRIC_FORMULA_OPERATION_LOOKUP
              }
              id={`select-${component.id}`}
              selectedId={component.value}
              setSelectedId={(value) =>
                updateComponentValue(component.id, value)
              }
              inputLabel={component.type === "metric" ? "Metric" : "Operation"}
              renderMenuItem={(option) =>
                component.type === "metric" ? option.metric : option
              }
            />
          ) : (
            <TextField
              label="Number"
              type="number"
              value={component.value}
              onChange={(e) =>
                updateComponentValue(component.id, e.target.value)
              }
              variant="outlined"
              size="small"
              fullWidth
            />
          )}
          <Button
            onClick={() => deleteComponentAndChildren(component.id)}
            style={{ marginLeft: "10px" }}
          >
            <CloseIcon />
          </Button>
          {component.type === "operation" && (
            <div style={{ marginLeft: "20px" }}>
              {renderFormulaComponents(component.id)}
              <Button onClick={() => addComponent("metric", component.id)}>
                + Add Metric
              </Button>
              <Button onClick={() => addComponent("operation", component.id)}>
                + Add Operation
              </Button>
              <Button onClick={() => addComponent("number", component.id)}>
                + Add Number
              </Button>
            </div>
          )}
        </div>
      ));
  };

  // Function to delete a component and its children
  const deleteComponentAndChildren = (componentId) => {
    setFormulaComponents((currentComponents) => {
      const deleteRecursively = (id) => {
        const children = currentComponents.filter(
          (component) => component.parentId === id
        );
        children.forEach((child) => deleteRecursively(child.id));
        return currentComponents.filter((component) => component.id !== id);
      };

      return deleteRecursively(componentId);
    });
  };

  return (
    <div className={classes.createNewMetricFormula}>
      <FinDataExpand
        title="Create New Metric Formula (Optional)"
        isExpandedView={showMetricFormulaFields}
        setIsExpandedView={setShowMetricFormulaFields}
      />

      {showMetricFormulaFields && (
        <>
          {renderFormulaComponents()}
          {!operationAdded && (
            <Button onClick={() => addComponent("operation")}>
              + Add Operation
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateFormula}
          >
            Create Formula
          </Button>
        </>
      )}
    </div>
  );
}

export default CreateNewMetricFormula;
