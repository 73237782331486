import actionTypes from "./actionTypes";
import {
  listEarningsTranscripts,
  listEarningsTranscripts_user,
} from "../gql/gqlHelperQueriesLayer";
import {
  decode_utf8,
  addAdditionalLineBreakToEarningsTranscripts,
} from "../helpers/transcriptHelpers";
import moment from "moment";
import { COGNITO_USER_GROUP_ADMIN } from "../constants";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllTranscripts = async (dispatch, userGroups) => {
  let isUserAdmin = determineIsUserAdmin(userGroups);
  let earningsTranscripts = isUserAdmin
    ? await listEarningsTranscripts()
    : await listEarningsTranscripts_user();

  earningsTranscripts.sort((a, b) => {
    return (
      moment(b.date).toDate().getTime() - moment(a.date).toDate().getTime()
    );
  });

  earningsTranscripts = earningsTranscripts.map((earningsTranscript) => {
    const updatedData = [];
    if (
      earningsTranscript.data &&
      earningsTranscript.data.items &&
      Array.isArray(earningsTranscript.data.items) &&
      earningsTranscript.data.items.length > 0
    ) {
      earningsTranscript.data.items.forEach((datum) => {
        if (!datum.unableToConfidentlyExtractData && datum.humanReviewed) {
          updatedData.push(datum);
        }
      });
    }

    return {
      ...earningsTranscript,
      content: addAdditionalLineBreakToEarningsTranscripts(
        decode_utf8(earningsTranscript.content)
      ),
      data: [...updatedData],
    };
  });
  dispatch({
    type: actionTypes.LOAD_ALL_TRANSCRIPTS,
    payload: earningsTranscripts,
  });
};
