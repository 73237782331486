import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { createMetricAction } from "../../actions/metricsActions";

// Components
import FinDataExpand from "../FinDataExpand";
import ButtonWithCircularProgress from "../ButtonWithCircularProgress";
import CreateNewMetricFormula from "./CreateNewMetricFormula";

const useStyles = makeStyles((theme) => ({
  createNewMetric: {
    backgroundColor: "white",
    padding: "8px",
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  subTitle: {
    fontWeight: "bold",
  },
}));

function CreateNewMetric(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Is Saving New Metric
  const [isSavingNewMetric, setIsSavingNewMetric] = useState(false);

  // State variables used for creating a new Metric
  const [showMetricFields, setShowMetricFields] = useState(false);
  const [newMetric, setNewMetric] = useState("");
  const [newMetricPlainEnglish, setNewMetricPlainEnglish] = useState("");
  const [newMetricValueType, setNewMetricValueType] = useState("");
  const [newMetricType, setNewMetricType] = useState("");
  const [newMetricFormula, setNewMetricFormula] = useState("");

  const handleCreateMetric = async () => {
    setIsSavingNewMetric(true);

    const createMetricInput = {
      metric: newMetric,
      metricPlainEnglish: newMetricPlainEnglish,
      valueType: newMetricValueType,
      type: newMetricType,
      formula: newMetricFormula,
    };

    try {
      await createMetricAction(dispatch, createMetricInput);
      setIsSavingNewMetric(false);
      setShowMetricFields(false);
      setNewMetric("");
      setNewMetricValueType("");
    } catch (error) {
      setIsSavingNewMetric(false);
    }
  };

  return (
    <div className={classes.createNewMetric}>
      <FinDataExpand
        title="Create New Metric (Optional)"
        isExpandedView={showMetricFields}
        setIsExpandedView={setShowMetricFields}
      />

      {showMetricFields && (
        <>
          <TextField
            id="newMetric"
            label="New Metric"
            value={newMetric}
            onChange={(e) => setNewMetric(e.target.value)}
            style={{ marginTop: "12px", width: "100%" }}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Note: should be in SCREAMING_SNAKE_CASE
          </div>
          <TextField
            id="newMetricPlainEnglish"
            label="New Metric Plain English"
            value={newMetricPlainEnglish}
            onChange={(e) => setNewMetricPlainEnglish(e.target.value)}
            style={{ marginTop: "12px", width: "100%" }}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Note: default to lower-case excluding acronyms
          </div>
          <TextField
            id="valueType"
            label="Value Type"
            value={newMetricValueType}
            onChange={(e) => setNewMetricValueType(e.target.value)}
            className={classes.formControl}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Note: value type options are PERCENTAGE, NUMBER, and USD
          </div>
          <TextField
            id="type"
            label="Type"
            value={newMetricType}
            onChange={(e) => setNewMetricType(e.target.value)}
            className={classes.formControl}
          />
          <div
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "italic",
            }}
          >
            Note: type options are SEC_FILING, API, COMPUTED, or
            EARNINGS_TRANSCRIPT
          </div>
          <TextField
            id="formula"
            label="Formula"
            value={newMetricFormula}
            onChange={(e) => setNewMetricFormula(e.target.value)}
            className={classes.formControl}
          />
          {props.showCreateNewMetricFormula && (
            <CreateNewMetricFormula onCreate={setNewMetricFormula} />
          )}

          <div style={{ marginTop: "12px" }}>
            <ButtonWithCircularProgress
              onClick={handleCreateMetric}
              disabled={
                !newMetric || !newMetricPlainEnglish || !newMetricValueType
              }
              isSaving={isSavingNewMetric}
              buttonText="Create New Metric"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default CreateNewMetric;
