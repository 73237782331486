import React from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import SecFilingData from "./Data/SecFilingData";
import EarningsTranscriptData from "./Data/EarningsTranscriptData";
import EarningsTranscripts from "./Transcripts/EarningsTranscripts";
import AdminDatumReview from "./AdminDatumReview/AdminDatumReview";
import CompanyMetricReview from "./CompanyMetricReview/CompanyMetricReview";
import AdminMetricReview from "./AdminMetricReview/AdminMetricReview";
import LogIn from "./Auth/LogIn";
import SignUp from "./Auth/SignUp";
import ConfirmEmail from "./Auth/ConfirmEmail";
import About from "./About/About";
import Contact from "./Contact/Contact";
import SecFilings from "./Filings/SecFilings";

import routes from "../routes";

/**
 * COMPONENT
 */
function Routes() {
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  return (
    <div>
      <Switch>
        <Route exact path={routes.HOME} component={SecFilingData} />
        <Route path={routes.SEC_FILING_DATA} component={SecFilingData} />
        <Route
          path={routes.EARNINGS_TRANSCRIPT_DATA}
          component={EarningsTranscriptData}
        />
        <Route
          path={routes.EARNINGS_TRANSCRIPTS}
          component={EarningsTranscripts}
        />
        <Route path={routes.SEC_FILINGS} component={SecFilings} />
        <Route path={routes.ADMIN_DATUM_REVIEW} component={AdminDatumReview} />
        <Route
          path={routes.COMPANY_METRIC_REVIEW}
          component={CompanyMetricReview}
        />
        <Route
          path={routes.ADMIN_METRIC_REVIEW}
          component={AdminMetricReview}
        />

        <Route path={routes.LOGIN} component={LogIn} />
        <Route path={routes.SIGNUP} component={SignUp} />
        <Route path={routes.CONFIRM_EMAIL} component={ConfirmEmail} />
        <Route path={routes.ABOUT} component={About} />
        <Route path={routes.CONTACT} component={Contact} />
        <Redirect to={routes.HOME} />
      </Switch>
    </div>
  );
}

// The `withRouter` wrapper makes sure that updates are not blocked
// when the url changes
export default withRouter(Routes);
