import actionTypes from "../actions/actionTypes";

const initialState = {
  financialStatementTypesLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setFinancialStatementTypes(state, financialStatementTypesArray) {
  const financialStatementTypesLookup = financialStatementTypesArray.reduce(
    (accum, currValue) => {
      accum[currValue.id] = currValue;
      return accum;
    },
    {}
  );
  return { ...state, financialStatementTypesLookup };
}

// -----------------------------------------------------
// Reducer

function financialStatementTypesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_FINANCIAL_STATEMENT_TYPES:
      return setFinancialStatementTypes(state, action.payload);

    default:
      return state;
  }
}

export default financialStatementTypesReducer;
