import {
  NOT_APPLICABLE,
  METRIC_VALUE_TYPE_NUMBER,
  METRIC_VALUE_TYPE_PERCENTAGE,
  METRIC_VALUE_TYPE_USD,
} from "../constants";

export function formatMetric(value, valueType = METRIC_VALUE_TYPE_NUMBER) {
  if (!value && typeof value !== "number") return NOT_APPLICABLE;

  try {
    switch (valueType) {
      case METRIC_VALUE_TYPE_PERCENTAGE:
        return formatPercentage(value);
      case METRIC_VALUE_TYPE_USD:
        return formatUSD(value);
      case METRIC_VALUE_TYPE_NUMBER:
        return formatNumber(value);
      default:
        return value;
    }
  } catch (err) {
    return NOT_APPLICABLE;
  }
}

export function formatNumber(value, maxNumValueWithDecimals = 999) {
  if (typeof value !== "number") return NOT_APPLICABLE;

  // Check if the value is above 999
  if (
    value > maxNumValueWithDecimals ||
    Math.abs(value) > maxNumValueWithDecimals
  ) {
    // For values above 999, format the number without any decimal places
    return value.toLocaleString(undefined, { maximumFractionDigits: 0 });
  } else {
    // For values 999 or below, just use toLocaleString without options
    // to keep the default formatting, which may include decimals.
    return value.toLocaleString();
  }
}

export function formatUSD(value, maxNumValueWithDecimals = 999) {
  if (!value) return NOT_APPLICABLE;

  if (value > maxNumValueWithDecimals) {
    return `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
  } else {
    return `$${value.toLocaleString()}`;
  }
}

export function formatPercentage(value) {
  if (!value) return NOT_APPLICABLE;

  return `${(value * 100).toFixed(0)}%`;
}
