import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { v4 as uuidv4 } from "uuid";
import TextField from "@material-ui/core/TextField";

// Constants
import { METRIC_FORMULA_OPERATION_LOOKUP } from "../../constants";

// Components
import FinDataSelectInput from "../FinDataSelectInput";
import FinDataExpand from "../FinDataExpand";

const useStyles = makeStyles((theme) => ({
  updateMetricFormula: {
    backgroundColor: "white",
    padding: "8px",
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  subTitle: {
    fontWeight: "bold",
  },
}));

function UpdateMetricFormula(props) {
  const classes = useStyles();
  const metricsLookup = useSelector((state) => state.metrics.metricsLookup);
  const [showMetricFormulaFields, setShowMetricFormulaFields] = useState(false);
  const [operationAdded, setOperationAdded] = useState(false);
  const [formulaComponents, setFormulaComponents] = useState([]);

  useEffect(() => {
    if (props.oldMetricFormula) {
      parseAndSetFormulaComponents(JSON.parse(props.oldMetricFormula));
    }
  }, [props.oldMetricFormula]);

  const parseAndSetFormulaComponents = (formula, parentId = null) => {
    Object.entries(formula).forEach(([operation, components]) => {
      const operationId = uuidv4();
      addComponentToState(operationId, "operation", operation, parentId);

      components.forEach((component) => {
        if (typeof component === "string") {
          // Metric ID
          addComponentToState(uuidv4(), "metric", component, operationId);
        } else {
          // Nested operation
          parseAndSetFormulaComponents(component, operationId);
        }
      });
    });
  };

  const addComponentToState = (id, type, value, parentId) => {
    setFormulaComponents((prevComponents) => [
      ...prevComponents,
      { id, type, value, parentId },
    ]);
    if (type === "operation") {
      setOperationAdded(true);
    }
  };

  // Function to add a new metric, operation, or number
  const addComponent = (type, parentId = null) => {
    let value = type;
    if (type === "number") {
      const numberValue = prompt("Enter a number:"); // Use a prompt to get the number value
      if (numberValue !== null) {
        value = numberValue;
      } else {
        return; // Exit if no value is entered
      }
    }
    const newComponent = { id: uuidv4(), type, value, parentId };
    setFormulaComponents([...formulaComponents, newComponent]);
    if (type === "operation") {
      setOperationAdded(true);
    }
  };

  // Improved approach for adding numbers, ensuring they are saved as numbers in the JSON
  const addNumberComponent = (parentId = null) => {
    let numberValue = prompt("Enter a number:");
    if (numberValue !== null) {
      const parsedValue = parseFloat(numberValue);
      if (!isNaN(parsedValue)) {
        const newComponent = {
          id: uuidv4(),
          type: "number",
          value: parsedValue, // Ensure the value is stored as a number
          parentId,
        };
        setFormulaComponents((prevComponents) => [
          ...prevComponents,
          newComponent,
        ]);
      } else {
        alert("Please enter a valid number.");
      }
    }
  };

  // Function to update the value of a component
  const updateComponentValue = (id, value) => {
    setFormulaComponents(
      formulaComponents.map((component) => {
        if (component.id === id) {
          return { ...component, value };
        }
        return component;
      })
    );
  };

  // Function to handle the update of the formula JSON
  const handleUpdateFormula = () => {
    const formulaJson = buildFormulaJson();
    props.onUpdate(formulaJson);

    setShowMetricFormulaFields(false);
  };

  // Recursive function to build each component of the formula
  const buildComponentJson = (componentId) => {
    const component = formulaComponents.find((c) => c.id === componentId);

    if (!component) {
      return null;
    }

    if (component.type === "operation") {
      const children = formulaComponents.filter(
        (c) => c.parentId === componentId
      );
      if (children.length === 0) {
        return null;
      }

      const operationType = component.value.toUpperCase();
      return {
        [operationType]: children.map((child) => buildComponentJson(child.id)),
      };
    } else {
      // Assuming it's a metric
      return component.value; // Metric ID
    }
  };

  // Function to build the formula JSON
  const buildFormulaJson = () => {
    const topParent = formulaComponents.find((c) => c.parentId === null);

    if (!topParent) {
      return {}; // or throw an error if a parent is expected
    }

    // Construct the JSON for the topmost parent
    const formulaJson = buildComponentJson(topParent.id);

    return JSON.stringify(formulaJson);
  };

  // Extend renderFormulaComponents to handle number components
  const renderFormulaComponents = (parentId = null) => {
    return formulaComponents
      .filter((component) => component.parentId === parentId)
      .map((component) => (
        <div key={component.id}>
          {component.type === "number" ? (
            <TextField
              label="Number"
              type="number"
              value={component.value}
              onChange={(e) =>
                updateComponentValue(component.id, e.target.value)
              }
              margin="normal"
            />
          ) : (
            <FinDataSelectInput
              lookupObject={
                component.type === "metric"
                  ? metricsLookup
                  : METRIC_FORMULA_OPERATION_LOOKUP
              }
              id={`select-${component.id}`}
              selectedId={component.value}
              setSelectedId={(value) =>
                updateComponentValue(component.id, value)
              }
              inputLabel={component.type === "metric" ? "Metric" : "Operation"}
              renderMenuItem={(option) =>
                component.type === "metric" ? option.metric : option
              }
            />
          )}
          <CloseIcon
            onClick={() => deleteComponentAndChildren(component.id)}
            style={{ cursor: "pointer" }}
          />
          {component.type === "operation" && (
            <div style={{ marginLeft: 20 }}>
              {renderFormulaComponents(component.id)}
              <Button onClick={() => addComponent("metric", component.id)}>
                + Add Metric
              </Button>
              <Button onClick={() => addComponent("operation", component.id)}>
                + Add Operation
              </Button>
              <Button onClick={() => addNumberComponent()}>+ Add Number</Button>
            </div>
          )}
        </div>
      ));
  };

  // Function to delete a component and its children
  const deleteComponentAndChildren = (componentId) => {
    // Check if the component being deleted is the top-level operation
    const isTopLevelOperation = !formulaComponents.find(
      (component) => component.id === componentId
    ).parentId;

    setFormulaComponents((currentComponents) => {
      const deleteRecursively = (id) => {
        const children = currentComponents.filter(
          (component) => component.parentId === id
        );
        children.forEach((child) => deleteRecursively(child.id));
        return currentComponents.filter((component) => component.id !== id);
      };

      return deleteRecursively(componentId);
    });

    // If the deleted component is the top-level operation, set operationAdded to false
    if (isTopLevelOperation) {
      setOperationAdded(false);
    }
  };

  return (
    <div className={classes.updateMetricFormula}>
      <FinDataExpand
        title="Update Metric Formula (Optional)"
        isExpandedView={showMetricFormulaFields}
        setIsExpandedView={setShowMetricFormulaFields}
      />

      {showMetricFormulaFields && (
        <>
          {renderFormulaComponents()}
          {!operationAdded && (
            <Button onClick={() => addComponent("operation")}>
              + Add Operation
            </Button>
          )}
          <Button onClick={() => addComponent("metric")}>+ Add Metric</Button>
          <Button onClick={() => addNumberComponent()}>+ Add Number</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateFormula}
          >
            Update Formula
          </Button>
        </>
      )}
    </div>
  );
}

export default UpdateMetricFormula;
