import actionTypes from "./actionTypes";
import { listXBRLTags, listXBRLTags_user } from "../gql/gqlHelperQueriesLayer";
import { createXBRLTag } from "../gql/gqlHelperMutationsLayer";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllXBRLTags = async (dispatch, userGroups) => {
  const isUserAdmin = determineIsUserAdmin(userGroups);

  let xbrlTags = isUserAdmin
    ? await listXBRLTags()
    : // Harcoding to an empty array for now, can change if needed
      [];

  dispatch({
    type: actionTypes.LOAD_ALL_XBRL_TAGS,
    payload: xbrlTags,
  });
};

export const createXBRLTagAction = async (dispatch, createXBRLTagInput) => {
  const createdXBRLTagResponse = await createXBRLTag(createXBRLTagInput);

  dispatch({
    type: actionTypes.CREATE_XBRL_TAG,
    payload: createdXBRLTagResponse,
  });

  return createdXBRLTagResponse;
};
