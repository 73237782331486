import React from "react";

// Classes
import FinDataTableCell from "../../classes/FinDataTableCell";
import FinDataTable from "../../classes/FinDataTable";

// Sub-components
import FinDataTableComponent from "../FinDataTableComponent";

function ViewMetricFinDataTables({
  metricFinDataTablesLookup,
  metricsLookup,
  finDataTablesLookup,
}) {
  const columnHeaders = [
    new FinDataTableCell("id"),
    new FinDataTableCell("metric.metric"),
    new FinDataTableCell("finDataTable.key"),
  ];

  // Prepare data as a 2D array for the table
  const data = Object.values(metricFinDataTablesLookup).map((row) => {
    return columnHeaders.map((header) => {
      let value;
      if (header === "id") {
        value = row?.[header];
      } else if (header === "metric.metric") {
        value = metricsLookup[row.metricId]?.metric;
      } else {
        // In this case it is finDataTable
        value = finDataTablesLookup[row.finDataTableId]?.key;
      }
      return new FinDataTableCell(value);
    });
  });

  const finDataTable = new FinDataTable(
    null,
    null,
    columnHeaders,
    Object.keys(metricFinDataTablesLookup).map(
      (_, index) => new FinDataTableCell(index)
    ),
    data
  );

  return (
    <div>
      <FinDataTableComponent finDataTable={finDataTable} />
    </div>
  );
}

export default ViewMetricFinDataTables;
