import React from "react";
import FinDataTableFromLookupObj from "../FinDataTableFromLookupObj";

function ViewCompanyMetrics({ metricsInUseForCompanyLookup }) {
  return (
    <FinDataTableFromLookupObj
      lookupObj={metricsInUseForCompanyLookup}
      columnHeaders={["id", "metric"]}
    />
  );
}

export default ViewCompanyMetrics;
