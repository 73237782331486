// ExportMenu.js
import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const ExportMenu = ({
  onExportToCSV,
  onExportToExcel,
  onToggleWrapText,
  wrapText,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        marginRight: "-12px",
        marginTop: "-12px",
      }}
    >
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            onExportToCSV();
            handleClose();
          }}
        >
          Download as CSV
        </MenuItem>
        <MenuItem
          onClick={() => {
            onExportToExcel();
            handleClose();
          }}
        >
          Download as Excel
        </MenuItem>
        <MenuItem
          onClick={() => {
            onToggleWrapText();
            handleClose();
          }}
        >
          {wrapText ? "Disable Wrap Text" : "Enable Wrap Text"}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ExportMenu;
