import actionTypes from "./actionTypes";
import { listMetrics, listMetrics_user } from "../gql/gqlHelperQueriesLayer";
import { createMetric, updateMetric } from "../gql/gqlHelperMutationsLayer";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllMetrics = async (dispatch, userGroups) => {
  const isUserAdmin = determineIsUserAdmin(userGroups);

  let metrics = isUserAdmin ? await listMetrics() : await listMetrics_user();

  dispatch({
    type: actionTypes.LOAD_ALL_METRICS,
    payload: metrics,
  });
};

export const createMetricAction = async (dispatch, createMetricInput) => {
  const createdMetricResponse = await createMetric(createMetricInput);

  dispatch({
    type: actionTypes.CREATE_METRIC,
    payload: createdMetricResponse,
  });

  return createdMetricResponse;
};

export const updateMetricAction = async (dispatch, updateMetricInput) => {
  const updatedMetricResponse = await updateMetric(updateMetricInput);

  dispatch({
    type: actionTypes.UPDATE_METRIC,
    payload: updatedMetricResponse,
  });

  return updatedMetricResponse;
};
