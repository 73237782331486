import actionTypes from "./actionTypes";
import {
  listCompanyFinDataTableMetricOrders,
  listCompanyFinDataTableMetricOrders_user,
} from "../gql/gqlHelperQueriesLayer";
import {
  createCompanyFinDataTableMetricOrder,
  updateCompanyFinDataTableMetricOrder,
} from "../gql/gqlHelperMutationsLayer";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllCompanyFinDataTableMetricOrders = async (
  dispatch,
  userGroups
) => {
  const isUserAdmin = determineIsUserAdmin(userGroups);

  let companyFinDataTableMetricOrders = isUserAdmin
    ? await listCompanyFinDataTableMetricOrders()
    : await listCompanyFinDataTableMetricOrders_user();

  dispatch({
    type: actionTypes.LOAD_ALL_COMPANY_FIN_DATA_TABLE_METRIC_ORDERS,
    payload: companyFinDataTableMetricOrders,
  });
};

export const createCompanyFinDataTableMetricOrderAction = async (
  dispatch,
  createCompanyFinDataTableMetricOrderInput
) => {
  const createdCompanyFinDataTableMetricOrderResponse =
    await createCompanyFinDataTableMetricOrder(
      createCompanyFinDataTableMetricOrderInput
    );

  dispatch({
    type: actionTypes.CREATE_COMPANY_FIN_DATA_TABLE_METRIC_ORDER,
    payload: createdCompanyFinDataTableMetricOrderResponse,
  });

  return createdCompanyFinDataTableMetricOrderResponse;
};

export const updateCompanyFinDataTableMetricOrderAction = async (
  dispatch,
  updateCompanyFinDataTableMetricOrderInput
) => {
  const updatedCompanyFinDataTableMetricOrderResponse =
    await updateCompanyFinDataTableMetricOrder(
      updateCompanyFinDataTableMetricOrderInput
    );

  dispatch({
    type: actionTypes.UPDATE_COMPANY_FIN_DATA_TABLE_METRIC_ORDER,
    payload: updatedCompanyFinDataTableMetricOrderResponse,
  });

  return updatedCompanyFinDataTableMetricOrderResponse;
};
