import React, { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import aws_exports from "./aws-exports";
import Routes from "./components/Routes";
import Layout from "./components/Layout";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { setAllTranscripts } from "./actions/transcriptsActions";
import { setAllDatums } from "./actions/datumsActions";
import { setAllFilings } from "./actions/filingsActions";
import { setAllMetrics } from "./actions/metricsActions";
import { setAllFinancialStatements } from "./actions/financialStatementsActions";
import { setAllFinancialStatementTypes } from "./actions/financialStatementTypesActions";
import { setAllXBRLTags } from "./actions/xbrlTagsActions";
import { setAllXBRLTagTypes } from "./actions/xbrlTagTypesActions";
import { setAllCompanies } from "./actions/companiesActions";
import { setAllFinDataTables } from "./actions/finDataTablesActions";
import { setAllCompanyFinDataTableMetricOrders } from "./actions/companyFinDataTableMetricOrdersActions";
import { setAllMetricFinDataTables } from "./actions/metricFinDataTablesActions";

import CircularProgress from "@material-ui/core/CircularProgress";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "./colors";
import AuthHelper from "./helpers/authHelper";
import { setAuthenticatedUser } from "./actions/authActions";

import { COGNITO_USER_GROUP_ADMIN } from "./constants";

Amplify.configure(aws_exports);

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export async function setUpApp(setIsLoading, dispatch) {
  setIsLoading(true);
  let authenticatedUser = await AuthHelper.Instance().getAuthenticatedUser();
  await setAuthenticatedUser(dispatch, authenticatedUser);
  const userGroups =
    authenticatedUser && authenticatedUser.groups
      ? authenticatedUser.groups
      : null;
  await setAllTranscripts(dispatch, userGroups);
  await setAllDatums(dispatch, userGroups);
  await setAllFilings(dispatch, userGroups);
  await setAllMetrics(dispatch, userGroups);
  await setAllFinancialStatements(dispatch, userGroups);
  await setAllFinancialStatementTypes(dispatch, userGroups);
  await setAllCompanies(dispatch, userGroups);
  await setAllFinDataTables(dispatch, userGroups);
  await setAllCompanyFinDataTableMetricOrders(dispatch, userGroups);
  await setAllMetricFinDataTables(dispatch, userGroups);
  await setAllXBRLTags(dispatch, userGroups);
  await setAllXBRLTagTypes(dispatch, userGroups);

  setIsLoading(false);
}

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setUpApp(setIsLoading, dispatch);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout>{isLoading ? <CircularProgress /> : <Routes />}</Layout>
    </ThemeProvider>
  );
}

export default App;
