import ActionTypes from "../actions/actionTypes";

const initialState = {
  authenticatedUser: undefined,
};

function setAuthenticatedUser(state, authenticatedUser) {
  return { ...state, authenticatedUser: authenticatedUser };
}

function resetAuthentication(state) {
  const newState = {};
  Object.entries(state).forEach(([key, value]) => {
    newState[key] = null;
  });
  return newState;
}

function authReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_AUTHENTICATED_USER:
      return setAuthenticatedUser(state, action.payload);
    case ActionTypes.RESET_AUTHENTICATION:
      return resetAuthentication(state);
    default:
      return state;
  }
}

export default authReducer;
