import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { HashLink as Link } from "react-router-hash-link";
import routes from "../../routes";
import { determineFiscalQuarterFromQuarterEndDate } from "../../helpers/dateHelpers";
import { FILINGS_BUCKET_DEV, FILINGS_BUCKET_PROD } from "../../constants";
import { useSelector } from "react-redux";
import { COGNITO_USER_GROUP_ADMIN } from "../../constants";
import { selectDatumForTenQOrK } from "../../store/selectors";
import { useLocation } from "react-router-dom";

import SidePanel from "../SidePanel";
import CreateDatumForm from "./CreateDatumForm";
import CreateFinancialStatementForm from "./CreateFinancialStatementForm";

const useStyles = makeStyles({
  adminFilingReview: {
    maxWidth: "100%",
  },
  content: {
    maxWidth: "100%",
  },
});
function AdminSecFilingReview(props) {
  const classes = useStyles();
  const filing = props.filing;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companiesLookup = useSelector(
    (state) => state.companies.companiesLookup
  );
  const company = companiesLookup[filing.companyId];

  const [isLoading, setIsLoading] = useState(true);
  const [filingContent, setFilingContent] = useState("");
  const [contextMenuPos, setContextMenuPos] = useState({ x: 0, y: 0 });
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);

  // This later becomes the Datum sentence
  const [clickedElementOuterHTML, setClickedElementOuterHTML] = useState("");
  // This later becomes the Datum paragraph
  const [selection, setSelection] = useState(null);

  // This is what we're ultimately scraping
  const [sentence, setSentence] = useState("");
  const [paragraph, setParagraph] = useState("");

  // Financial statement state variables
  const [financialStatementSidePanelOpen, setFinancialStatementSidePanelOpen] =
    useState(false);
  const [financialStatement, setFinancialStatement] = useState("");

  let [fiscalQuarter, fiscalYear] = determineFiscalQuarterFromQuarterEndDate(
    filing.reportDate,
    company.fiscalYearEnd
  );
  const metadata = `${filing.symbol} - ${
    company.registrantName
  } - Q${fiscalQuarter} ${fiscalYear} ${filing.form} - ${moment(
    filing.filingDate
  ).format("YYYY-MM-DD")} - Fiscal Year Ends ${company.fiscalYearEnd}`;

  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );
  const datumsForTenQOrK = useSelector((state) => {
    return selectDatumForTenQOrK(state, filing.id);
  });

  const userIsAdmin =
    authenticatedUser &&
    authenticatedUser.groups &&
    Array.isArray(authenticatedUser.groups) &&
    authenticatedUser.groups.length > 0 &&
    authenticatedUser.groups.includes(COGNITO_USER_GROUP_ADMIN);

  useEffect(() => {
    const fetchFiling = async () => {
      const key = `${filing.S3Key}`;
      const response = await fetch(
        `https://${
          process.env.NODE_ENV !== "production"
            ? FILINGS_BUCKET_DEV
            : FILINGS_BUCKET_PROD
        }.s3.us-east-1.amazonaws.com/${key}`,
        {
          headers: {
            "Content-Security-Policy":
              "default-src 'none'; img-src 'self'; script-src 'self'; style-src 'self'; frame-ancestors 'none'; base-uri 'none'; form-action 'none'",
          },
        }
      );
      let data = await response.text();
      // Remove the <TYPE>, <SEQUENCE>, <FILENAME>, and <DESCRIPTION> lines
      data = data.replace(/<(TYPE|SEQUENCE|FILENAME|DESCRIPTION)>[^<]+\s/g, "");
      setFilingContent(data);
      setIsLoading(false);
    };
    fetchFiling();
  }, [filing]);

  useEffect(() => {
    setIsLoading(false); // Assuming the document is now rendered

    Object.values(datumsForTenQOrK).forEach((datum) => {
      if (
        !datum.innerContextXmlIds ||
        !Array.isArray(datum.innerContextXmlIds) ||
        datum.innerContextXmlIds.length === 0
      ) {
        return;
      }

      datum.innerContextXmlIds.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.backgroundColor = "yellow";
          element.style.setProperty("background-color", "yellow", "important"); // For !important
        }
      });
    });
  }, [filingContent, datumsForTenQOrK]); // Dependency array includes filingContent and datumsForTenQOrK

  const handleContextMenu = (event) => {
    event.preventDefault();

    let selectedHtml = getSelectionHtml();

    if (selectedHtml) {
      const range = window.getSelection().getRangeAt(0);
      const element = range.startContainer.parentElement;
      if (element) {
        setClickedElementOuterHTML(element.outerHTML);
      }

      setContextMenuPos({ x: event.clientX, y: event.clientY });
      setSelection(selectedHtml);
    } else {
      setContextMenuPos(null);
      setSelection(null);
      setClickedElementOuterHTML("");
    }
  };

  const handleMouseDown = (event) => {
    // Close the context menu setContextMenuPos({ x: 0, y: 0 });
    setSelection(null);
  };

  const handleScrapeSentence = async () => {
    setSentence(clickedElementOuterHTML);
    setSidePanelOpen(true);
    setContextMenuPos(null);
  };

  const handleScrapeParagraph = async () => {
    setParagraph(selection);
    setSidePanelOpen(true);
    setContextMenuPos(null);
  };

  const handleScrapeFinancialStatement = async () => {
    setFinancialStatement(selection);
    setFinancialStatementSidePanelOpen(true);
    setContextMenuPos(null);
  };

  const setSidePanelClosed = () => {
    setSidePanelOpen(false);
  };

  const setFinancialStatementSidePanelClosed = () => {
    setFinancialStatementSidePanelOpen(false);
  };

  if (!userIsAdmin) {
    return <div>Not authorized</div>;
  }

  return (
    <div key={filing.id} className={classes.adminFilingReview}>
      {" "}
      {searchParams.has("source") &&
        searchParams.get("source") === "filingsLink" && (
          <Link to={routes.SEC_FILINGS} className={classes.linkStyle}>
            Back to all filings
          </Link>
        )}
      {searchParams.has("source") &&
        searchParams.get("source") === "datumLink" && (
          <Link to={routes.SEC_FILING_DATA} className={classes.linkStyle}>
            Back to SEC filings data
          </Link>
        )}{" "}
      <h3> {metadata} </h3>{" "}
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div
          onContextMenu={handleContextMenu} // No type argument
          onMouseDown={handleMouseDown}
          dangerouslySetInnerHTML={{ __html: filingContent }}
          className={classes.content}
        />
      )}{" "}
      {selection && contextMenuPos && (
        <div
          style={{
            position: "fixed",
            top: contextMenuPos.y,
            left: contextMenuPos.x,
            background: "white",
            border: "1px solid black",
            padding: 5,
            fontSize: 12,
            borderRadius: 5,
            cursor: "pointer",
          }}
        >
          {" "}
          <div onClick={() => handleScrapeSentence(selection)}>
            Scrape sentence
          </div>{" "}
          <div onClick={() => handleScrapeParagraph(selection)}>
            Scrape paragraph
          </div>{" "}
          <div onClick={() => handleScrapeFinancialStatement(selection)}>
            Scrape financial statement
          </div>{" "}
        </div>
      )}{" "}
      <SidePanel isOpen={isSidePanelOpen} onClose={setSidePanelClosed}>
        <CreateDatumForm
          sentence={sentence}
          setSentence={setSentence}
          paragraph={paragraph}
          setParagraph={setParagraph}
          setSidePanelClosed={setSidePanelClosed}
          metadata={metadata}
          filing={filing}
          filingContent={filingContent}
        />
      </SidePanel>
      <SidePanel
        isOpen={financialStatementSidePanelOpen}
        onClose={setFinancialStatementSidePanelClosed}
      >
        <CreateFinancialStatementForm
          textSelection={financialStatement}
          setFinancialStatementSidePanelClosed={
            setFinancialStatementSidePanelClosed
          }
          filing={filing}
          filingContent={filingContent}
        />
      </SidePanel>
    </div>
  );
}

function getSelectionHtml() {
  let html = "";
  if (typeof window.getSelection !== "undefined") {
    const sel = window.getSelection();
    if (sel.rangeCount) {
      const container = document.createElement("div");
      for (let i = 0; i < sel.rangeCount; ++i) {
        container.appendChild(sel.getRangeAt(i).cloneContents());
      }
      html = container.innerHTML;
    }
  }
  return html;
}

export default AdminSecFilingReview;
