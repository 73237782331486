import actionTypes from "./actionTypes";
import {
  listFinancialStatements,
  listFinancialStatements_user,
} from "../gql/gqlHelperQueriesLayer";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllFinancialStatementTypes = async (dispatch, userGroups) => {
  const isUserAdmin = determineIsUserAdmin(userGroups);

  let financialStatementTypes = isUserAdmin
    ? await listFinancialStatements()
    : // Harcoding to an empty array for now, can change if needed
      [];

  dispatch({
    type: actionTypes.LOAD_ALL_FINANCIAL_STATEMENT_TYPES,
    payload: financialStatementTypes,
  });
};
