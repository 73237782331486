import actionTypes from "../actions/actionTypes";

const initialState = {
  companyFinDataTableMetricOrdersLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setCompanyFinDataTableMetricOrders(
  state,
  companyFinDataTableMetricOrdersArray
) {
  const companyFinDataTableMetricOrdersLookup =
    companyFinDataTableMetricOrdersArray.reduce((accum, currValue) => {
      accum[currValue.id] = currValue;
      return accum;
    }, {});
  return { ...state, companyFinDataTableMetricOrdersLookup };
}

function createCompanyFinDataTableMetricOrder(
  state,
  companyFinDataTableMetricOrder
) {
  const companyFinDataTableMetricOrdersLookup = {
    ...state.companyFinDataTableMetricOrdersLookup,
  };
  companyFinDataTableMetricOrdersLookup[companyFinDataTableMetricOrder.id] =
    companyFinDataTableMetricOrder;
  return { ...state, companyFinDataTableMetricOrdersLookup };
}

function updateCompanyFinDataTableMetricOrder(
  state,
  companyFinDataTableMetricOrder
) {
  const companyFinDataTableMetricOrdersLookup = {
    ...state.companyFinDataTableMetricOrdersLookup,
  };
  companyFinDataTableMetricOrdersLookup[companyFinDataTableMetricOrder.id] =
    companyFinDataTableMetricOrder;
  return { ...state, companyFinDataTableMetricOrdersLookup };
}

// -----------------------------------------------------
// Reducer

function companyFinDataTableMetricOrdersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_COMPANY_FIN_DATA_TABLE_METRIC_ORDERS:
      return setCompanyFinDataTableMetricOrders(state, action.payload);

    case actionTypes.CREATE_COMPANY_FIN_DATA_TABLE_METRIC_ORDER:
      return createCompanyFinDataTableMetricOrder(state, action.payload);

    case actionTypes.UPDATE_COMPANY_FIN_DATA_TABLE_METRIC_ORDER:
      return updateCompanyFinDataTableMetricOrder(state, action.payload);

    default:
      return state;
  }
}

export default companyFinDataTableMetricOrdersReducer;
