import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import routes from "../../routes";
import ViewFiling from "./ViewFiling";
import AdminFilingReview from "./AdminFilingReview";
import { HashLink as Link } from "react-router-hash-link";
import LinkIcon from "@material-ui/icons/Link";
import { linkStyle, linkIconStyle } from "../../styles";
import { determineFiscalQuarterFromQuarterEndDate } from "../../helpers/dateHelpers";
import { COGNITO_USER_GROUP_ADMIN } from "../../constants";

const useStyles = makeStyles({
  quarterHeader: {
    marginBottom: 10,
  },
  linkStyle: { ...linkStyle },
  linkIconStyle: { ...linkIconStyle },
});

function SecFilings(props) {
  const classes = useStyles();
  const currentLocation = useLocation();
  const [viewFilingId, setViewFilingId] = useState("");
  const [isViewingFilingUser, setIsViewingFilingUser] = useState(null);
  const [isViewingFilingAdmin, setIsViewingFilingAdmin] = useState(null);
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );
  const userIsAdmin =
    authenticatedUser &&
    authenticatedUser.groups &&
    Array.isArray(authenticatedUser.groups) &&
    authenticatedUser.groups.length > 0 &&
    authenticatedUser.groups.includes(COGNITO_USER_GROUP_ADMIN);

  useEffect(() => {
    const viewFilingMatch = matchPath(currentLocation.pathname, {
      path: routes.SEC_FILING,
    });
    const viewFilingMatchAdmin = matchPath(currentLocation.pathname, {
      path: routes.ADMIN_SEC_FILING_REVIEW,
    });

    if (
      viewFilingMatchAdmin &&
      viewFilingMatchAdmin.isExact &&
      viewFilingMatchAdmin.params &&
      viewFilingMatchAdmin.params.secFilingId
    ) {
      setViewFilingId(viewFilingMatchAdmin.params.secFilingId);
      setIsViewingFilingAdmin(true);
      setIsViewingFilingUser(false);
    } else if (
      viewFilingMatch &&
      viewFilingMatch.isExact &&
      viewFilingMatch.params &&
      viewFilingMatch.params.secFilingId
    ) {
      setViewFilingId(viewFilingMatch.params.secFilingId);
      setIsViewingFilingUser(true);
      setIsViewingFilingAdmin(false);
    } else {
      setViewFilingId("");
      setIsViewingFilingUser(false);
      setIsViewingFilingAdmin(false);
    }
  }, [currentLocation]);

  const filingsLookup = useSelector((state) => state.filings.filingsLookup);
  const companiesLookup = useSelector(
    (state) => state.companies.companiesLookup
  );

  let filingsQuarters = new Set();
  Object.values(filingsLookup).forEach((filing) => {
    const company = companiesLookup[filing.companyId];
    let [fiscalQuarter, fiscalYear] = determineFiscalQuarterFromQuarterEndDate(
      filing.reportDate,
      company.fiscalYearEnd
    );
    filingsQuarters.add(`Q${fiscalQuarter} ${fiscalYear}`);
  });
  filingsQuarters = Array.from(filingsQuarters);
  let filingsByQuarter = {};
  Object.values(filingsLookup).forEach((filing) => {
    const company = companiesLookup[filing.companyId];
    let [fiscalQuarter, fiscalYear] = determineFiscalQuarterFromQuarterEndDate(
      filing.reportDate,
      company.fiscalYearEnd
    );
    let quarter = `Q${fiscalQuarter} ${fiscalYear}`;
    if (filingsByQuarter[quarter] && Array.isArray(filingsByQuarter[quarter])) {
      filingsByQuarter[quarter].push(filing);
    } else {
      filingsByQuarter[quarter] = [filing];
    }
  });

  return (
    <div>
      {viewFilingId && isViewingFilingUser ? (
        <ViewFiling filing={filingsLookup[viewFilingId]} />
      ) : viewFilingId && isViewingFilingAdmin ? (
        <AdminFilingReview filing={filingsLookup[viewFilingId]} />
      ) : (
        <div>
          {filingsQuarters.map((quarter) => (
            <div>
              <h3 className={classes.quarterHeader}>{quarter} filings</h3>
              {filingsByQuarter[quarter] &&
                Array.isArray(filingsByQuarter[quarter]) &&
                filingsByQuarter[quarter].length > 0 &&
                filingsByQuarter[quarter].map((filing) => {
                  const company = companiesLookup[filing.companyId];
                  let [fiscalQuarter, fiscalYear] =
                    determineFiscalQuarterFromQuarterEndDate(
                      filing.reportDate,
                      company.fiscalYearEnd
                    );
                  return (
                    <div>
                      <Link
                        key={filing.id}
                        activeStyle={{ color: "black" }}
                        className={classes.linkStyle}
                        to={{
                          pathname: userIsAdmin
                            ? routes.insertParamsIntoRoutePath(
                                routes.ADMIN_SEC_FILING_REVIEW,
                                {
                                  secFilingId: filing.id,
                                }
                              )
                            : routes.insertParamsIntoRoutePath(
                                routes.SEC_FILING,
                                {
                                  secFilingId: filing.id,
                                }
                              ),
                          search: "?source=filingsLink",
                        }}
                      >
                        {`${filing.symbol} - ${
                          company.registrantName
                        } - Q${fiscalQuarter} ${fiscalYear} ${
                          filing.form
                        } - ${moment(filing.filingDate).format(
                          "YYYY-MM-DD"
                        )} - Fiscal Year Ends ${company.fiscalYearEnd}`}
                        <LinkIcon className={classes.linkIconStyle} />
                      </Link>
                    </div>
                  );
                })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SecFilings;
