import moment from "moment";

// ------------------------------------ DATE HELPERS ------------------------------------

export function determineFiscalQuarter(today, fiscalYearEnd) {
  const fiscalYearEndMonth = fiscalYearEnd.slice(0, 2);
  const fiscalYearEndDay = fiscalYearEnd.slice(3, 5);
  const currentYear = today.substring(0, 4);
  const currentMonth = today.substring(5, 7);
  const currentDay = today.substring(8, 10);

  const mostRecentFiscalYearEndDate = determineMostRecentFiscalYearEndDate(
    fiscalYearEndMonth,
    fiscalYearEndDay,
    currentYear,
    currentMonth,
    currentDay
  );
  const oneQuarterAfterMostRecentFiscalYearEnd = moment(
    mostRecentFiscalYearEndDate
  )
    .add(3, "months")
    .endOf("month")
    .format("YYYY-MM-DD");
  const twoQuartersAfterMostRecentFiscalYearEnd = moment(
    mostRecentFiscalYearEndDate
  )
    .add(6, "months")
    .endOf("month")
    .format("YYYY-MM-DD");
  const threeQuartersAfterMostRecentFiscalYearEnd = moment(
    mostRecentFiscalYearEndDate
  )
    .add(9, "months")
    .endOf("month")
    .format("YYYY-MM-DD");
  const fourQuartersAfterMostRecentFiscalYearEnd = moment(
    mostRecentFiscalYearEndDate
  )
    .add(12, "months")
    .endOf("month")
    .format("YYYY-MM-DD");
  let fiscalQuarter;
  let fiscalYear;

  if (
    moment(today).isAfter(moment(mostRecentFiscalYearEndDate)) &&
    moment(today).isBefore(moment(oneQuarterAfterMostRecentFiscalYearEnd))
  ) {
    fiscalQuarter = "4";
    fiscalYear = mostRecentFiscalYearEndDate.slice(0, 4);
  } else if (
    moment(today).isAfter(moment(oneQuarterAfterMostRecentFiscalYearEnd)) &&
    moment(today).isBefore(moment(twoQuartersAfterMostRecentFiscalYearEnd))
  ) {
    fiscalQuarter = "1";
    fiscalYear = oneQuarterAfterMostRecentFiscalYearEnd.slice(0, 4);
  } else if (
    moment(today).isAfter(moment(twoQuartersAfterMostRecentFiscalYearEnd)) &&
    moment(today).isBefore(moment(threeQuartersAfterMostRecentFiscalYearEnd))
  ) {
    fiscalQuarter = "2";
    fiscalYear = twoQuartersAfterMostRecentFiscalYearEnd.slice(0, 4);
  } else if (
    moment(today).isAfter(moment(threeQuartersAfterMostRecentFiscalYearEnd)) &&
    moment(today).isBefore(moment(fourQuartersAfterMostRecentFiscalYearEnd))
  ) {
    fiscalQuarter = "3";
    fiscalYear = threeQuartersAfterMostRecentFiscalYearEnd.slice(0, 4);
  }

  return [fiscalQuarter, fiscalYear];
}

export function determineFiscalQuarterFromQuarterEndDate(
  quarterEndDate,
  fiscalYearEnd
) {
  const fiscalYearEndMonth = fiscalYearEnd.slice(0, 2);
  const fiscalYearEndDay = fiscalYearEnd.slice(3, 5);
  const quarterEndDateYear = quarterEndDate.substring(0, 4);
  const quarterEndDateMonth = quarterEndDate.substring(5, 7);
  const quarterEndDateDay = quarterEndDate.substring(8, 10);

  let potentialQ4OfCurrentYearEndDate = `${quarterEndDateYear}-${fiscalYearEndMonth}-${fiscalYearEndDay}`;

  // There are three potential scenarios from here.
  // 1. potentialQ4OfCurrentYearEndDate is equal to quarterEndDate
  // 2. potentialQ4OfCurrentYearEndDate is after to quarterEndDate
  // 3. potentialQ4OfCurrentYearEndDate is before quarterEndDate
  // Scenarios 1 and 2 are trivial
  // In scenario 3, we add an additional year to the potentialQ4OfCurrentYearEndDate and move backwards from there

  let fiscalQuarter;
  let fiscalYear;
  let Q4OfCurrentYearEndDate;
  if (potentialQ4OfCurrentYearEndDate === quarterEndDate) {
    fiscalQuarter = "4";
    fiscalYear = quarterEndDateYear;
    return [fiscalQuarter, fiscalYear];
  } else if (potentialQ4OfCurrentYearEndDate > quarterEndDate) {
    fiscalYear = quarterEndDateYear;
    Q4OfCurrentYearEndDate = potentialQ4OfCurrentYearEndDate;
  } else {
    fiscalYear = `${Number(quarterEndDateYear) + 1}`;
    Q4OfCurrentYearEndDate = moment(potentialQ4OfCurrentYearEndDate)
      .add(1, "years")
      .endOf("month")
      .format("YYYY-MM-DD");
  }

  let q3OfFiscalYear = moment(Q4OfCurrentYearEndDate)
    .subtract(3, "months")
    .endOf("month")
    .format("YYYY-MM-DD");
  let q2OfFiscalYear = moment(Q4OfCurrentYearEndDate)
    .subtract(6, "months")
    .endOf("month")
    .format("YYYY-MM-DD");
  let q1OfFiscalYear = moment(Q4OfCurrentYearEndDate)
    .subtract(9, "months")
    .endOf("month")
    .format("YYYY-MM-DD");

  if (quarterEndDate === q3OfFiscalYear) {
    fiscalQuarter = "3";
    return [fiscalQuarter, fiscalYear];
  } else if (quarterEndDate === q2OfFiscalYear) {
    fiscalQuarter = "2";
    return [fiscalQuarter, fiscalYear];
  } else if (quarterEndDate === q1OfFiscalYear) {
    fiscalQuarter = "1";
    return [fiscalQuarter, fiscalYear];
  }

  // In this fallback scenario we simply return the most recent fiscal quarter and year
  // This is not ideal, but it is better than returning an error

  // When quarterEndDate doesn't match any of the expected end dates, determine the most recent fiscal quarter and year.
  if (quarterEndDate < q1OfFiscalYear) {
    fiscalQuarter = "4";
    fiscalYear = `${Number(fiscalYear) - 1}`;
  } else if (quarterEndDate < q2OfFiscalYear) {
    fiscalQuarter = "1";
  } else if (quarterEndDate < q3OfFiscalYear) {
    fiscalQuarter = "2";
  } else {
    fiscalQuarter = "3";
  }

  return [fiscalQuarter, fiscalYear];
}

export const convertFiscalQuarterAndFiscalYearToShortHandString = (
  fiscalQuarter,
  fiscalYear,
  useFullFiscalYear = false
) => {
  return `Q${fiscalQuarter} ${
    useFullFiscalYear ? fiscalYear : `'${fiscalYear.slice(2, 4)}`
  }`;
};

export function determineMostRecentFiscalYearEndDate(
  fiscalYearEndMonth,
  fiscalYearEndDay,
  currentYear,
  currentMonth,
  currentDay
) {
  let mostRecentFiscalYearEndDate = "";
  if (
    Number(currentMonth) > Number(fiscalYearEndMonth) ||
    (Number(currentMonth) === Number(fiscalYearEndMonth) &&
      Number(currentDay) > Number(fiscalYearEndDay))
  ) {
    mostRecentFiscalYearEndDate = moment(
      `${currentYear}-${fiscalYearEndMonth}-${fiscalYearEndDay}`
    ).format("YYYY-MM-DD");
  } else {
    mostRecentFiscalYearEndDate = moment(
      `${Number(currentYear) - 1}-${fiscalYearEndMonth}-${fiscalYearEndDay}`
    ).format("YYYY-MM-DD");
  }
  return mostRecentFiscalYearEndDate;
}
