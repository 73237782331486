import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AuthHelper from "../../helpers/authHelper";
import { setAuthenticatedUser } from "../../actions/authActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import routes from "../../routes";

const useStyles = makeStyles({
  field: {
    marginTop: 10,
    marginBottom: 10,
    display: "block",
  },
  button: {
    marginTop: 10,
    marginBottom: 10,
  },
  errorMessage: {
    fontSize: 14,
  },
});

/**
 * COMPONENT
 */
const SignUp = (props) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [signUpError, setSignUpError] = useState(null);
  const [genericSignUpError, setGenericSignUpError] = useState(null);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSigningUp(true);

    // For now, only phoneNumber is optional
    // In future, may want to make phoneNumber mandatory as well
    if (!username || !password || !email) {
      setSignUpError("Missing required field");
      setIsSigningUp(false);
      return;
    }

    let authUser;
    try {
      authUser = await AuthHelper.Instance().signUp(
        username,
        password,
        email,
        phoneNumber
      );
      setSignUpError(null);
    } catch (err) {
      setSignUpError(err.message);
    }

    setIsSigningUp(false);
    if (authUser && authUser.username) {
      history.push(routes.CONFIRM_EMAIL, [{ username: authUser.username }]);
    } else {
      setGenericSignUpError("Failed to sign up. Please try again.");
    }
  };

  return isSigningUp ? (
    <CircularProgress />
  ) : (
    <div>
      <form onSubmit={handleSubmit} name={"login"}>
        <div>
          <TextField
            label="Username"
            variant="outlined"
            name="username"
            className={classes.field}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <TextField
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            className={classes.field}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            type="email"
            className={classes.field}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <TextField
            label="Phone number"
            variant="outlined"
            name="phoneNumber"
            type="phoneNumber"
            className={classes.field}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div>
          <Button
            type="submit"
            variant="outlined"
            className={classes.button}
            onClick={handleSubmit}
          >
            Sign up
          </Button>
        </div>
        {signUpError ? (
          <Typography className={classes.errorMessage}>
            Failed sign-up. Error message: {signUpError}
          </Typography>
        ) : genericSignUpError ? (
          <Typography className={classes.errorMessage}>
            Failed sign-up. Error message: {genericSignUpError}
          </Typography>
        ) : null}
      </form>
    </div>
  );
};

export default SignUp;
