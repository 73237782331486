import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";
import EarningsTranscriptDatum from "./EarningsTranscriptDatum";
import { selectDatumsGroupedByEarningsTranscript } from "../../store/selectors";

const useStyles = makeStyles({ dataContainer: { paddingBottom: 10 } });

function EarningsTrasncriptData(props) {
  const classes = useStyles();

  const datumGroupedByEarningsTranscript = useSelector(
    selectDatumsGroupedByEarningsTranscript
  );

  const companiesLookup = useSelector(
    (state) => state.companies.companiesLookup
  );

  return (
    <div>
      {Object.values(datumGroupedByEarningsTranscript).map(
        (earningsTranscript) => {
          const transcriptHasExtractedData =
            earningsTranscript &&
            earningsTranscript.data &&
            earningsTranscript.data &&
            Array.isArray(earningsTranscript.data) &&
            earningsTranscript.data.length > 0;
          return transcriptHasExtractedData ? (
            <div key={earningsTranscript.id} className={classes.dataContainer}>
              <h3>
                {`${earningsTranscript.symbol} - ${
                  companiesLookup[earningsTranscript.companyId].registrantName
                } - Q${earningsTranscript.quarter} ${
                  earningsTranscript.year
                } earnings transcript - ${moment(
                  earningsTranscript.date
                ).format("YYYY-MM-DD")} - Fiscal Year Ends ${
                  companiesLookup[earningsTranscript.companyId].fiscalYearEnd
                }`}
              </h3>

              {earningsTranscript.data.map((datum) => (
                <div key={datum.id}>
                  <EarningsTranscriptDatum datum={datum} />
                </div>
              ))}
            </div>
          ) : null;
        }
      )}
    </div>
  );
}

export default EarningsTrasncriptData;
