import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useMediaQuery, useTheme } from "@material-ui/core";

import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DataUsageOutlinedIcon from "@material-ui/icons/DataUsageOutlined";
import Description from "@material-ui/icons/Description";
import Mic from "@material-ui/icons/Mic";
import Info from "@material-ui/icons/Info";
import Email from "@material-ui/icons/Email";
import RateReview from "@material-ui/icons/RateReview";
import DeveloperMode from "@material-ui/icons/DeveloperMode";
import BusinessOutlined from "@material-ui/icons/BusinessOutlined";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import { useHistory, useLocation } from "react-router-dom";
import Lock from "@material-ui/icons/Lock";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import routes from "../routes";

import { setAuthenticatedUser } from "../actions/authActions";
import { useDispatch } from "react-redux";
import AuthHelper from "../helpers/authHelper";

import { COGNITO_USER_GROUP_ADMIN } from "../constants";
import { setUpApp } from "../App";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => {
  return {
    page: {
      width: "100%",
      padding: theme.spacing(3),
    },
    drawer: (isMobile) => ({
      width: isMobile ? 65 : 240,
    }),
    drawerPaper: (isMobile) => ({
      width: isMobile ? 65 : 240,
    }),
    root: {
      display: "flex",
    },
    active: {
      background: "#E7ECEF",
    },
    title: {
      padding: theme.spacing(2),
    },
    appbar: (drawerWidth) => ({
      width: `calc(100% - ${drawerWidth}px)`,
    }),
    toolbar: theme.mixins.toolbar,
    date: (isMobile) => ({
      paddingLeft: isMobile ? 65 : 240,
      flexGrow: 1,
    }),
    appBarOptions: {
      display: "flex",
      flexDirection: "row",
      marginLeft: 10,
      marginRight: 10,
    },
    appBarOptionButtons: {
      whiteSpace: "nowrap",
    },
    icon: {
      minWidth: "30px",
    },
  };
});

function Layout({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const drawerWidth = isMobile ? 60 : 240;
  const classes = useStyles(isMobile);
  const history = useHistory();
  const location = useLocation();
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );
  const [isLoading, setIsLoading] = useState(false);
  const userIsAdmin =
    authenticatedUser &&
    authenticatedUser.groups &&
    Array.isArray(authenticatedUser.groups) &&
    authenticatedUser.groups.length > 0 &&
    authenticatedUser.groups.includes(COGNITO_USER_GROUP_ADMIN);

  const dispatch = useDispatch();

  let unprotectedDrawerItems = [
    {
      title: "SEC Filings",
      titleShort: "SEC",
    },
    {
      text: "SEC Filings Data",
      icon: <AssessmentOutlinedIcon color="secondary" />,
      path: routes.SEC_FILING_DATA,
    },
    {
      text: "SEC Filings",
      icon: <Description color="secondary" />,
      path: routes.SEC_FILINGS,
    },
    {
      title: "Earnings Transcripts",
      titleShort: "Earnings",
    },
    {
      text: "Earnings Transcript Data",
      icon: <DataUsageOutlinedIcon color="secondary" />,
      path: routes.EARNINGS_TRANSCRIPT_DATA,
    },
    {
      text: "Earnings Transcripts",
      icon: <Mic color="secondary" />,

      path: routes.EARNINGS_TRANSCRIPTS,
    },
    {
      title: "About",
      titleShort: "About",
    },
    {
      text: "About",
      icon: <Info color="secondary" />,
      path: routes.ABOUT,
    },
    {
      text: "Contact",
      icon: <Email color="secondary" />,
      path: routes.CONTACT,
    },
  ];

  let protectedDrawerItems = [
    {
      title: "Admin",
      titleShort: "Admin",
    },
    {
      text: "AdminDatumReview",
      icon: <RateReview color="secondary" />,
      path: routes.ADMIN_DATUM_REVIEW,
    },
    {
      text: "CompanyMetricReview",
      icon: <DeveloperMode color="secondary" />,
      path: routes.COMPANY_METRIC_REVIEW,
    },
    {
      text: "AdminMetricReview",
      icon: <BusinessOutlined color="secondary" />,
      path: routes.ADMIN_METRIC_REVIEW,
    },
  ];

  let drawerItems = unprotectedDrawerItems.concat(
    userIsAdmin ? protectedDrawerItems : []
  );

  const loggedOutMenuItems = [
    {
      text: "Login",
      icon: <LockOpenIcon color="secondary" />,
      path: "/login",
    },
    {
      text: "Sign Up",
      icon: <HowToRegIcon color="secondary" />,
      path: "/signup",
    },
  ];

  const handleLogOut = async () => {
    await setAuthenticatedUser(dispatch, null);
    await AuthHelper.Instance().logOut();
    await setUpApp(setIsLoading, dispatch);
    history.push(routes.ROOT);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} elevation={0}>
        <Toolbar>
          {!authenticatedUser ? (
            <Typography className={classes.date}></Typography>
          ) : (
            <Typography className={classes.date}>
              Welcome, {authenticatedUser.username}
            </Typography>
          )}
          <List className={classes.appBarOptions}>
            {!authenticatedUser ? (
              loggedOutMenuItems.map((item) => (
                <ListItem
                  button
                  key={item.text}
                  onClick={() => history.push(item.path)}
                  className={
                    location.pathname === item.path ? classes.active : null
                  }
                >
                  <ListItemIcon className={classes.icon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    className={classes.appBarOptionButtons}
                  ></ListItemText>
                </ListItem>
              ))
            ) : (
              <ListItem button key="Logout" onClick={handleLogOut}>
                <ListItemIcon className={classes.icon}>
                  <Lock color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Logout"></ListItemText>
              </ListItem>
            )}
          </List>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
      >
        <div>
          {isMobile ? (
            <img
              src={`${process.env.PUBLIC_URL}/favicon.ico`}
              alt="logo"
              className={classes.title}
              style={{ height: "30px", width: "30px" }}
            />
          ) : (
            <Typography variant="h5" className={classes.title}>
              findata.ai
            </Typography>
          )}
        </div>

        {/* list / links */}
        <List>
          {drawerItems.map((item) =>
            item.title ? (
              <ListItem key={item.title + "title"}>
                <ListItemText
                  primary={item.title}
                  style={{ textDecoration: "underline" }}
                />
              </ListItem>
            ) : (
              <ListItem
                button
                key={item.text}
                onClick={() => history.push(item.path)}
                className={
                  location.pathname === item.path ? classes.active : null
                }
              >
                <ListItemIcon style={{ minWidth: "35px" }}>
                  {item.icon}
                </ListItemIcon>
                {!isMobile && (
                  <ListItemText
                    primary={item.text}
                    style={{
                      whiteSpace: "normal", // This will ensure the text wraps
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  />
                )}
              </ListItem>
            )
          )}
        </List>
      </Drawer>
      <div className={classes.page}>
        <div className={classes.toolbar}></div>
        {isLoading ? <CircularProgress /> : children}
      </div>
    </div>
  );
}

export default Layout;
