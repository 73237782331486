import gql from "graphql-tag";

export const CompanyFragment = gql`
  fragment CompanyFragment on Company {
    id
    businessAddress
    cik
    exchange
    fiscalYearEnd
    mailingAddress
    registrantName
    sicCode
    sicDescription
    sicGroup
    stateLocation
    stateOfIncorporation
    symbol
    taxIdentificationNumber
  }
`;

export const CompanyFinDataTableMetricOrderFragment = gql`
  fragment CompanyFinDataTableMetricOrderFragment on CompanyFinDataTableMetricOrder {
    id
    companyId
    metricId
    order
    finDataTableId
  }
`;

export const DatumFragment = gql`
  fragment DatumFragment on Datum {
    id
    transcriptMetadata
    sentence
    paragraph
    rawCompletion
    completionError
    unableToConfidentlyExtractData
    value
    isSnapshot
    timePeriodInterval
    timePeriodValue
    timePeriodEndDate
    symbol
    qualifiers
    isGuidance
    timePeriodConfidence
    asOfDate
    companyOrMarketData
    comparisonPeriod
    humanReviewed
    metricId
    earningsTranscriptId
    tenQOrKId
    companyId
    sentenceEarningsTranscriptStartIdx
    sentenceEarningsTranscriptEndIdx
    innerContextXmlIds
    outterContextXmlIds
    xbrlTagId
    financialStatementId
    metricPlainEnglishOverride
  }
`;

export const DatumArchiveFragment = gql`
  fragment DatumArchiveFragment on DatumArchive {
    id
    transcriptMetadata
    sentence
    paragraph
    rawCompletion
    completionError
    unableToConfidentlyExtractData
    value
    isSnapshot
    timePeriodInterval
    timePeriodValue
    timePeriodEndDate
    symbol
    qualifiers
    isGuidance
    timePeriodConfidence
    asOfDate
    companyOrMarketData
    comparisonPeriod
    humanReviewed
    metricId
    earningsTranscriptId
    tenQOrKId
    companyId
    sentenceEarningsTranscriptStartIdx
    sentenceEarningsTranscriptEndIdx
    innerContextXmlIds
    outterContextXmlIds
    xbrlTagId
    financialStatementId
    metricPlainEnglishOverride
  }
`;

export const EarningsReleaseDateFragment = gql`
  fragment EarningsReleaseDateFragment on EarningsReleaseDate {
    id
    date
    exchange
    publicationDate
    symbol
    time
    title
    url
    when
    companyId
    earningsTranscriptId
  }
`;

export const EarningsTranscriptFragment = gql`
  fragment EarningsTranscriptFragment on EarningsTranscript {
    id
    content
    date
    quarter
    symbol
    year
    companyId
    earningsReleaseDateId
  }
`;

export const FinancialStatementFragment = gql`
  fragment FinancialStatementFragment on FinancialStatement {
    id
    tenQOrKId
    financialStatementTypeId
    rawText
    xmlIds
    companyId
  }
`;

export const FinancialStatementTypeFragment = gql`
  fragment FinancialStatementTypeFragment on FinancialStatementType {
    id
    financialStatementType
  }
`;

export const FinDataTableFragment = gql`
  fragment FinDataTableFragment on FinDataTable {
    id
    key
    name
  }
`;

export const MetricFragment = gql`
  fragment MetricFragment on Metric {
    id
    metric
    metricPlainEnglish
    valueType
    type
    formula
  }
`;

export const MetricFinDataTableFragment = gql`
  fragment MetricFinDataTableFragment on MetricFinDataTable {
    id
    metricId
    finDataTableId
  }
`;

export const TenQOrKFragment = gql`
  fragment TenQOrKFragment on TenQOrK {
    id
    accessionNumber
    accessionNumberNoDashes
    filingDate
    reportDate
    form
    primaryDocument
    documentUrl
    reportYear
    reportMonth
    fileName
    S3Key
    symbol
    companyId
    cik
    isEdited
    isEditedAndStyled
    isXBRL
  }
`;

export const XBRLTagFragment = gql`
  fragment XBRLTagFragment on XBRLTag {
    id
    tag
    tagPlainEnglish
    xbrlTagTypeId
  }
`;

export const XBRLTagTypeFragment = gql`
  fragment XBRLTagTypeFragment on XBRLTagType {
    id
    type
  }
`;

export const XSDFileFragment = gql`
  fragment XSDFileFragment on XSDFile {
    id
    tenQOrKId
    S3Key
  }
`;
