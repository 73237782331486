import { trackedMetrics } from "../constants";

// ------------------------------------ TRANSCRIPT HELPERS ------------------------------------

// http://ecmanaut.blogspot.com/2006/07/encoding-decoding-utf8-in-javascript.html
export function decode_utf8(s) {
  try {
    // Lambda function created earnings transcripts are encoded UTF-8
    return decodeURIComponent(escape(s));
  } catch (err) {
    // Manually created earnings transcripts are not encoded UTF-8 and throw an error when trying to be decoded
    return s;
  }
}

// ------------------------------- ADD ADDITIONAL LINE BREAKS TO EARNINGS TRANSCRIPTS -----------

export function addAdditionalLineBreakToEarningsTranscripts(
  earningsTranscript
) {
  let stringIndicesOfLineBreaks =
    determineStringIndicesOfAllOccurrencesOfASubstring(
      earningsTranscript,
      "\n"
    );

  let updatedEarningsTranscript =
    givenAStringAndAnArrayOfStringIndicesInsertAnotherStringImmediatelyAfterEachStringIndex(
      earningsTranscript,
      stringIndicesOfLineBreaks,
      "\n"
    );

  return updatedEarningsTranscript;
}

// Returns an array of the string index of all substrings in a string
// https://stackoverflow.com/questions/3410464/how-to-find-indices-of-all-occurrences-of-one-string-in-another-in-javascript
export function determineStringIndicesOfAllOccurrencesOfASubstring(
  string,
  substring
) {
  let regex = new RegExp(substring, "gi");
  let result,
    indices = [];
  while ((result = regex.exec(string))) {
    indices.push(result.index);
  }

  return indices;
}

// Return an updated string with an insertString inserted after each index
export function givenAStringAndAnArrayOfStringIndicesInsertAnotherStringImmediatelyAfterEachStringIndex(
  string,
  arrayOfStringIndices,
  insertString
) {
  // We go backwards so as to not mess up our list of indices through our insertions
  for (let i = arrayOfStringIndices.length - 1; i >= 0; i--) {
    let stringIndex = arrayOfStringIndices[i];

    string =
      string.slice(0, stringIndex) + insertString + string.slice(stringIndex);
  }
  return string;
}

// ------------------- convertEarningsTranscriptIntoParagraphTagsWithIdsEqualToDataTabDatumIds ----------

export function convertEarningsTranscriptIntoParagraphTagsWithIdsEqualToDataTabDatumIds(
  earningsTranscript,
  data
) {
  let earningsTranscriptLowerCase = earningsTranscript.toLowerCase();

  // https://www.stat.berkeley.edu/~s133/Regexp-a.html
  // https://stackoverflow.com/questions/3903488/javascript-backslash-in-variables-is-causing-an-error#:~:text=In%20order%20to%20output%20a,two%2C%20and%20so%20on).
  // Period represents all characters in a RegExp
  // Additionally, a backslash is an escape character so you need two
  let stringIndicesOfPeriods =
    determineStringIndicesOfAllOccurrencesOfASubstring(
      earningsTranscriptLowerCase,
      "\\."
    );

  let stringIndicesOfQuestionMarks =
    determineStringIndicesOfAllOccurrencesOfASubstring(
      earningsTranscriptLowerCase,
      "\\?"
    );

  let stringIndicesOfEllipses =
    determineStringIndicesOfAllOccurrencesOfASubstring(
      earningsTranscriptLowerCase,
      "…"
    );

  let stringIndicesOfSentenceEnds = [
    ...stringIndicesOfPeriods,
    ...stringIndicesOfQuestionMarks,
    ...stringIndicesOfEllipses,
  ].sort((x, y) => x - y);

  // Next we want to remove all periods that are surrounded on both sides by a number
  stringIndicesOfSentenceEnds =
    givenAStringAndAnArrayOfStringIndicesOfSentenceEndsReturnAnArrayOfStringIndicesOfSentenceEndsNotImmediatelySurroundedOnBothSidesByANumber(
      earningsTranscriptLowerCase,
      stringIndicesOfSentenceEnds
    );

  let arrayOfAllSentencesInATranscript =
    givenAStringAndAnArrayOfAllStringIndicesOfSentenceEndsInAStringConstructAnArrayOfAllSentencesInTheString(
      earningsTranscript,
      stringIndicesOfSentenceEnds
    );

  let arrayOfArrayIndicesOfInsightsInAnArrayOfSentencesInATranscript = data.map(
    (datum) => {
      for (const idx in arrayOfAllSentencesInATranscript) {
        let sentence = arrayOfAllSentencesInATranscript[idx];
        // Replace duplicated line breaks with a single line break
        let sentenceWithBeginningSpaceRemoved = sentence
          .replace(/\n+/g, "\n")
          .slice(1);

        if (sentenceWithBeginningSpaceRemoved === datum.sentence) {
          return idx;
        }
      }
      return -1;
    }
  );

  return (
    <div>
      {arrayOfAllSentencesInATranscript.map((sentence, idx) => {
        if (
          arrayOfArrayIndicesOfInsightsInAnArrayOfSentencesInATranscript.includes(
            `${idx}`
          )
        ) {
          // Remove duplicate line breaks
          let sentenceWithBeginningSpaceRemoved = sentence
            .replace(/\n+/g, "\n")
            .slice(1);
          let datum = data.filter(
            (d) => d.sentence === sentenceWithBeginningSpaceRemoved
          )[0];
          if (datum)
            return (
              <span
                style={{ fontWeight: "bold" }}
                key={`${sentence}${idx}`}
                id={datum.id}
              >
                {sentence}
              </span>
            );
          else {
            return <span key={`${sentence}${idx}`}>{sentence}</span>;
          }
        } else {
          return <span key={`${sentence}${idx}`}>{sentence}</span>;
        }
      })}
    </div>
  );
}

// Returns an array of all string indices of sentence ends
// immediately preceding a tracked metric
export function convertArrayOfAllStringIndicesOfTrackedMetricAndArrayOfAllStringIndicesOfSentenceEndsIntoAnArrayOfAllStringIndicesOfSentenceEndsImmediatelyPrecedingTrackedMetric(
  arrayOfAllStringIndicesOfTrackedMetric,
  arrayOfAllStringIndicesOfSentenceEnds
) {
  let arrayOfAllStringIndicesOfASentenceEndImmediatelyPrecedingAStringIndexOfTrackedMetric =
    [...arrayOfAllStringIndicesOfTrackedMetric];

  arrayOfAllStringIndicesOfASentenceEndImmediatelyPrecedingAStringIndexOfTrackedMetric =
    arrayOfAllStringIndicesOfASentenceEndImmediatelyPrecedingAStringIndexOfTrackedMetric.map(
      (currentStringIndex) => {
        // The strategy will be to find the first index in the arrayOfAllStringIndicesOfSentenceEnds that is greater than the index in question, and then take the element prior to that one
        let firstArrayIndexInArrayOfAllSentenceEndIndicesThatTranslatesToAStringIndexThatIsGreaterThanOurCurrentStringIndex = 0;
        let firstStringIndexInArrayOfAllSentenceEndIndicesThatIsGreaterThanOurCurrentStringIndex =
          arrayOfAllStringIndicesOfSentenceEnds[
            firstArrayIndexInArrayOfAllSentenceEndIndicesThatTranslatesToAStringIndexThatIsGreaterThanOurCurrentStringIndex
          ];

        while (
          firstStringIndexInArrayOfAllSentenceEndIndicesThatIsGreaterThanOurCurrentStringIndex <=
          currentStringIndex
        ) {
          firstArrayIndexInArrayOfAllSentenceEndIndicesThatTranslatesToAStringIndexThatIsGreaterThanOurCurrentStringIndex++;
          firstStringIndexInArrayOfAllSentenceEndIndicesThatIsGreaterThanOurCurrentStringIndex =
            arrayOfAllStringIndicesOfSentenceEnds[
              firstArrayIndexInArrayOfAllSentenceEndIndicesThatTranslatesToAStringIndexThatIsGreaterThanOurCurrentStringIndex
            ];
        }
        // When we exit the for loop, that means we are at a stringIndex that is greater than our currentStringIndex
        // So, we decrease our firstArrayIndexInArrayOfAllSentenceEndIndicesThatTranslatesToAStringIndexThatIsGreaterThanOurCurrentStringIndex variable by 1
        // And we retermine our firstStringIndexInArrayOfAllSentenceEndIndicesThatIsGreaterThanOurCurrentStringIndex based on that newly computed value of our
        // firstArrayIndexInArrayOfAllSentenceEndIndicesThatTranslatesToAStringIndexThatIsGreaterThanOurCurrentStringIndex variable
        firstArrayIndexInArrayOfAllSentenceEndIndicesThatTranslatesToAStringIndexThatIsGreaterThanOurCurrentStringIndex--;

        firstStringIndexInArrayOfAllSentenceEndIndicesThatIsGreaterThanOurCurrentStringIndex =
          arrayOfAllStringIndicesOfSentenceEnds[
            firstArrayIndexInArrayOfAllSentenceEndIndicesThatTranslatesToAStringIndexThatIsGreaterThanOurCurrentStringIndex
          ];

        return firstStringIndexInArrayOfAllSentenceEndIndicesThatIsGreaterThanOurCurrentStringIndex;
      }
    );

  return arrayOfAllStringIndicesOfASentenceEndImmediatelyPrecedingAStringIndexOfTrackedMetric;
}

export function givenAStringAndAnArrayOfStringIndicesOfSentenceEndsReturnAnArrayOfStringIndicesOfSentenceEndsNotImmediatelySurroundedOnBothSidesByANumber(
  string,
  arrayOfStringIndicesOfSentenceEnds
) {
  let returnedArray = [];
  for (const idx in arrayOfStringIndicesOfSentenceEnds) {
    let currentStringIndexOfSentenceEnd =
      arrayOfStringIndicesOfSentenceEnds[idx];

    if (
      isNaN(parseFloat(string[currentStringIndexOfSentenceEnd - 1])) ||
      isNaN(parseFloat(string[currentStringIndexOfSentenceEnd + 1]))
    ) {
      returnedArray.push(currentStringIndexOfSentenceEnd);
    }
  }
  return returnedArray;
}

export function givenAnArrayOfSentencesRemoveAllSentencesThatDoNotContainAtLeastOneNumber(
  arrayOfSentences
) {
  let returnedArray = [];
  for (const idx in arrayOfSentences) {
    const currentSentence = arrayOfSentences[idx];
    let currentSentenceContainsANumber = false;
    for (const idx2 in currentSentence) {
      let currentCharacter = currentSentence[idx2];
      if (!isNaN(parseFloat(currentCharacter))) {
        currentSentenceContainsANumber = true;
      }
    }
    if (currentSentenceContainsANumber) {
      returnedArray.push(currentSentence);
    }
  }
  return returnedArray;
}

export function givenAStringAndAnArrayOfAllStringIndicesOfSentenceEndsInAStringConstructAnArrayOfAllSentencesInTheString(
  string,
  arrayOfAllStringIndicesOfSentenceEndsInAString
) {
  let outputArray = [
    string.slice(0, arrayOfAllStringIndicesOfSentenceEndsInAString[0]),
  ];
  for (
    let idx = 1;
    idx < arrayOfAllStringIndicesOfSentenceEndsInAString.length;
    idx++
  ) {
    let currentStringIndexOfSentenceEnd =
      arrayOfAllStringIndicesOfSentenceEndsInAString[idx];

    let previousStringIndexOfSentenceEnd =
      arrayOfAllStringIndicesOfSentenceEndsInAString[idx - 1];

    outputArray.push(
      string.slice(
        previousStringIndexOfSentenceEnd + 1,
        currentStringIndexOfSentenceEnd + 1
      )
    );
  }

  return outputArray;
}

// ----------------------------------- DEPRECATED ----------------------------

// NOTE: DEPRECATED
// Returns HTML rendition of earnings transcript where all insights are bolded
export function convertEarningsTranscriptToHighlightedTextBasedOnInsights(
  earningsTranscript
) {
  let earningsTranscriptLowerCase = earningsTranscript.toLowerCase();
  let insights = [];
  // https://www.stat.berkeley.edu/~s133/Regexp-a.html
  // https://stackoverflow.com/questions/3903488/javascript-backslash-in-variables-is-causing-an-error#:~:text=In%20order%20to%20output%20a,two%2C%20and%20so%20on).
  // Period represents all characters in a RegExp
  // Additionally, a backslash is an escape character so you need two
  let stringIndicesOfPeriods =
    determineStringIndicesOfAllOccurrencesOfASubstring(
      earningsTranscriptLowerCase,
      "\\."
    );

  let stringIndicesOfQuestionMarks =
    determineStringIndicesOfAllOccurrencesOfASubstring(
      earningsTranscriptLowerCase,
      "\\?"
    );

  let stringIndicesOfEllipses =
    determineStringIndicesOfAllOccurrencesOfASubstring(
      earningsTranscriptLowerCase,
      "…"
    );

  let stringIndicesOfSentenceEnds = [
    ...stringIndicesOfPeriods,
    ...stringIndicesOfQuestionMarks,
    ...stringIndicesOfEllipses,
  ].sort((x, y) => x - y);

  // Next we want to remove all periods that are surrounded on both sides by a number
  stringIndicesOfSentenceEnds =
    givenAStringAndAnArrayOfStringIndicesOfSentenceEndsReturnAnArrayOfStringIndicesOfSentenceEndsNotImmediatelySurroundedOnBothSidesByANumber(
      earningsTranscriptLowerCase,
      stringIndicesOfSentenceEnds
    );

  let arrayOfAllSentencesInATranscript =
    givenAStringAndAnArrayOfAllStringIndicesOfSentenceEndsInAStringConstructAnArrayOfAllSentencesInTheString(
      earningsTranscript,
      stringIndicesOfSentenceEnds
    );
  for (const idx in trackedMetrics) {
    const trackedMetricLowerCase = trackedMetrics[idx].toLowerCase();

    const stringIndicesOfTrackedMetric =
      determineStringIndicesOfAllOccurrencesOfASubstring(
        earningsTranscriptLowerCase,
        trackedMetricLowerCase
      );

    let stringIndicesOfSentenceEndsImmediatelyPrecedingTrackedMetric =
      convertArrayOfAllStringIndicesOfTrackedMetricAndArrayOfAllStringIndicesOfSentenceEndsIntoAnArrayOfAllStringIndicesOfSentenceEndsImmediatelyPrecedingTrackedMetric(
        stringIndicesOfTrackedMetric,
        stringIndicesOfSentenceEnds
      );

    let arrayOfArrayIndicesOfSentenceEndsImmediatelyPrecedingOccurenceOfTrackedMetric =
      [...stringIndicesOfSentenceEndsImmediatelyPrecedingTrackedMetric];
    arrayOfArrayIndicesOfSentenceEndsImmediatelyPrecedingOccurenceOfTrackedMetric =
      arrayOfArrayIndicesOfSentenceEndsImmediatelyPrecedingOccurenceOfTrackedMetric.map(
        (stringIndex) => {
          return stringIndicesOfSentenceEnds.indexOf(stringIndex);
        }
      );

    stringIndicesOfTrackedMetric.forEach((stringIndex, arrayIndex) => {
      let arrayIndexOfSentenceEndImmediatelyPrecedingOccurenceOfTrackedMetric =
        arrayOfArrayIndicesOfSentenceEndsImmediatelyPrecedingOccurenceOfTrackedMetric[
          arrayIndex
        ];

      let stringIndexOfSentenceEndImmediatelyPrecedingOccurenceOfTrackedMetric =
        stringIndicesOfSentenceEnds[
          arrayIndexOfSentenceEndImmediatelyPrecedingOccurenceOfTrackedMetric
        ];

      let stringIndexOfSentenceEndImmediatelySucceedingOccurenceOfTrackedMetric =
        stringIndicesOfSentenceEnds[
          arrayIndexOfSentenceEndImmediatelyPrecedingOccurenceOfTrackedMetric +
            1
        ];

      let insight = earningsTranscript.slice(
        stringIndexOfSentenceEndImmediatelyPrecedingOccurenceOfTrackedMetric +
          1,
        stringIndexOfSentenceEndImmediatelySucceedingOccurenceOfTrackedMetric +
          1
      );
      insights.push(insight);
    });
  }

  insights =
    givenAnArrayOfSentencesRemoveAllSentencesThatDoNotContainAtLeastOneNumber(
      insights
    );

  // Remove duplicates that are sometimes created if a tracked metric is in a sentence more than once
  insights = [...new Set(insights)];

  let arrayOfArrayIndicesOfInsightsInAnArrayOfSentencesInATranscript =
    insights.map((insight) => {
      for (const idx in arrayOfAllSentencesInATranscript) {
        let sentence = arrayOfAllSentencesInATranscript[idx];
        if (sentence === insight) {
          return idx;
        }
      }
      return -1;
    });

  return (
    <div>
      {arrayOfAllSentencesInATranscript.map((sentence, idx) => {
        if (
          arrayOfArrayIndicesOfInsightsInAnArrayOfSentencesInATranscript.includes(
            `${idx}`
          )
        ) {
          return (
            <span style={{ fontWeight: "bold" }} key={`${sentence}${idx}`}>
              {sentence}
            </span>
          );
        } else {
          return <span key={`${sentence}${idx}`}>{sentence}</span>;
        }
      })}
    </div>
  );
}
