import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import ButtonWithCircularProgress from "../ButtonWithCircularProgress";
import { updateCompanyFinDataTableMetricOrderAction } from "../../actions/companyFinDataTableMetricOrdersActions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

// Components
import FinDataSelectInput from "../FinDataSelectInput";
import FinDataExpand from "../FinDataExpand";

const useStyles = makeStyles((theme) => ({
  updateCompanyFinDataTableMetricOrder: {
    backgroundColor: "white",
    padding: "8px",
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  subTitle: {
    fontWeight: "bold",
  },
}));

function UpdateCompanyFinDataTableMetricOrder(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Is Saving New CompanyFinDatatableMetricOrder
  const [isSaving, setIsSaving] = useState(false);

  // State variables used for creating a new CompanyFinDatatableMetricOrder
  const [showExpandedView, setShowExpandedView] = useState(false);

  const [
    selectedCompanyFinDataTableMetricOrderId,
    setSelectedCompanyFinDataTableMetricOrderId,
  ] = useState(props.companyFinDataTableMetricOrderId || "");
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    props.companyId || ""
  );
  const [selectedFinDataTableId, setSelectedFinDataTableId] = useState(
    props.finDataTableId || ""
  );
  const [selectedMetricId, setSelectedMetricId] = useState(
    props.metricId || ""
  );
  const [order, setOrder] = useState(props.oder || null);

  // Lookups
  const companiesLookup = useSelector(
    (state) => state.companies.companiesLookup
  );
  const metricsLookup = useSelector((state) => state.metrics.metricsLookup);
  const finDataTablesLookup = useSelector(
    (state) => state.finDataTables.finDataTablesLookup
  );
  const companyFinDataTableMetricOrdersLookup = useSelector(
    (state) =>
      state.companyFinDataTableMetricOrders
        .companyFinDataTableMetricOrdersLookup
  );

  const handleUpdateCompanyFinDataTableMetricOrder = async () => {
    setIsSaving(true);

    const updateCompanyFinDatatableMetricOrderInput = {
      id: selectedCompanyFinDataTableMetricOrderId,
      companyId: selectedCompanyId,
      metricId: selectedMetricId,
      finDataTableId: selectedFinDataTableId,
      order: order,
    };

    try {
      await updateCompanyFinDataTableMetricOrderAction(
        dispatch,
        updateCompanyFinDatatableMetricOrderInput
      );
      setIsSaving(false);
      setShowExpandedView(false);
      setSelectedMetricId("");
    } catch (error) {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (
      !selectedCompanyFinDataTableMetricOrderId ||
      !companyFinDataTableMetricOrdersLookup
    ) {
      return;
    }

    if (
      Object.keys(companyFinDataTableMetricOrdersLookup).includes(
        selectedCompanyFinDataTableMetricOrderId
      )
    ) {
      const companyFinDataTableMetricOrder =
        companyFinDataTableMetricOrdersLookup[
          selectedCompanyFinDataTableMetricOrderId
        ];
      setSelectedCompanyId(companyFinDataTableMetricOrder.companyId);
      setSelectedMetricId(companyFinDataTableMetricOrder.metricId);
      setSelectedFinDataTableId(companyFinDataTableMetricOrder.finDataTableId);
      setOrder(companyFinDataTableMetricOrder.order);
    }
  }, [
    selectedCompanyFinDataTableMetricOrderId,
    companyFinDataTableMetricOrdersLookup,
  ]);

  return (
    <div className={classes.updateCompanyFinDataTableMetricOrder}>
      <FinDataExpand
        title="Update CompanyFinDatatableMetricOrder (Optional)"
        isExpandedView={showExpandedView}
        setIsExpandedView={setShowExpandedView}
      />

      {showExpandedView && (
        <>
          <Typography
            style={{ marginTop: "16px", minWidth: 120, width: "100%" }}
          >
            ID
          </Typography>
          <TextField
            type="text"
            value={selectedCompanyFinDataTableMetricOrderId}
            style={{ minWidth: 120, width: "100%" }}
            onChange={(e) =>
              setSelectedCompanyFinDataTableMetricOrderId(e.target.value)
            }
          />
          <FinDataSelectInput
            lookupObject={companiesLookup}
            id="company"
            selectedId={selectedCompanyId}
            setSelectedId={setSelectedCompanyId}
            inputLabel="Company Symbol"
            style={{ marginTop: "12px", minWidth: 120, width: "100%" }}
            renderMenuItem={(company) => {
              return company.symbol;
            }}
          />
          <FinDataSelectInput
            lookupObject={metricsLookup}
            id="metric"
            selectedId={selectedMetricId}
            setSelectedId={setSelectedMetricId}
            inputLabel="Metric Key"
            style={{ marginTop: "12px", minWidth: 120, width: "100%" }}
            renderMenuItem={(metric) => {
              return metric.metric;
            }}
          />
          <FinDataSelectInput
            lookupObject={finDataTablesLookup}
            id="finDatableTable"
            selectedId={selectedFinDataTableId}
            setSelectedId={setSelectedFinDataTableId}
            inputLabel="FinDataTable Key"
            style={{ marginTop: "12px", minWidth: 120, width: "100%" }}
            renderMenuItem={(finDataTable) => {
              return finDataTable.key;
            }}
          />
          <Typography
            style={{ marginTop: "16px", minWidth: 120, width: "100%" }}
          >
            Order
          </Typography>
          <TextField
            type="number"
            value={order}
            style={{ minWidth: 120, width: "100%" }}
            onChange={(e) => setOrder(e.target.value)}
          />

          <div style={{ marginTop: "12px" }}>
            <ButtonWithCircularProgress
              onClick={handleUpdateCompanyFinDataTableMetricOrder}
              disabled={
                !selectedCompanyId ||
                !selectedMetricId ||
                !selectedFinDataTableId ||
                !order
              }
              isSaving={isSaving}
              buttonText="update New CompanyFinDatatableMetricOrder"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default UpdateCompanyFinDataTableMetricOrder;
