import { Auth } from "aws-amplify";
import awsConfig from "../aws-exports.js";
import AuthUser from "./authUser";
import { GQLHelper } from "./gqlHelper";

class AuthHelper {
  static #instance;

  constructor() {
    if (AuthHelper.#instance) {
      throw new Error("AuthHelper is a Singleton");
    }

    if (!awsConfig) {
      throw new Error(
        "Initialization of AuthHelper requires an aws config file"
      );
    }

    GQLHelper.init(awsConfig);
    AuthHelper.#instance = this;
  }

  async getAuthenticatedUser() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const authUser = new AuthUser(
        cognitoUser.attributes.sub,
        cognitoUser.username,
        cognitoUser.signInUserSession.accessToken.payload["cognito:groups"]
      );

      return authUser;
    } catch (err) {
      // Nothing to do here
    }
    return null;
  }

  async logOut() {
    await Auth.signOut();
  }

  async logIn(username, password) {
    try {
      const signInResult = await Auth.signIn(username, password);

      if (signInResult) {
        const authUser = new AuthUser(
          signInResult.attributes.sub,
          signInResult.username,
          signInResult.signInUserSession.accessToken.payload["cognito:groups"]
        );
        return authUser;
      }
    } catch (err) {
      // Nothing to do here
      throw new Error(err);
    }
  }

  async signUp(username, password, email, phone_number) {
    try {
      const signUpInfo = {
        username,
        password,
        attributes: {
          email,
        },
        autoSignIn: {
          enabled: true,
        },
      };
      if (!!phone_number) {
        signUpInfo.attributes.phone_number = `+1${phone_number}`;
      }
      const { user } = await Auth.signUp(signUpInfo);
      return user;
    } catch (err) {
      // Nothing to do here
      throw new Error(err);
    }
  }

  async confirmSignUp(username, code) {
    try {
      const confirmSignUpResponse = await Auth.confirmSignUp(username, code, {
        forceAliasCreation: true,
      });
      return confirmSignUpResponse;
    } catch (err) {
      // Nothing to do here
      throw new Error(err);
    }
  }

  async resendConfirmationCode(username) {
    try {
      await Auth.resendSignUp(username);
    } catch (err) {
      // Nothing to do here
      throw new Error(err);
    }
  }

  // ----------- STATIC FUNCTIONS ------------

  static Instance() {
    if (!AuthHelper.#instance) {
      new AuthHelper();
    }
    return AuthHelper.#instance;
  }
}

export default AuthHelper;
