// createColumns.js
import React from "react";
import renderCell from "./renderCell";

export default function createColumns({
  finDataTable,
  getFootnoteText,
  isHighlightedCell,
  colorizeCellInnerContent,
  classes,
}) {
  let cols = [
    {
      Header: "",
      accessor: "rowHeader",
      Cell: ({ value }) => {
        const footnoteText = getFootnoteText(value.cellAddress);
        return (
          <>
            <span>{value.value}</span>
            {footnoteText}
          </>
        );
      },
    },
  ];

  cols = cols.concat(
    finDataTable.columnHeaders.map((headerCell, index) => {
      return {
        Header: () => {
          const footnoteText = getFootnoteText(headerCell.cellAddress);
          return (
            <>
              {headerCell.value}
              {footnoteText}
            </>
          );
        },
        accessor: `col${index}`,
        Cell: ({ row, value }) =>
          renderCell({
            getFootnoteText,
            isHighlightedCell,
            colorizeCellInnerContent,
            finDataTable,
            classes,
            row,
            value,
            columnIndex: index,
          }),
      };
    })
  );

  return cols;
}
