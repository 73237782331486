import actionTypes from "../actions/actionTypes";

const initialState = {
  xbrlTagsLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setXbrlTags(state, xbrlTagsArray) {
  const xbrlTagsLookup = xbrlTagsArray.reduce((accum, currValue) => {
    accum[currValue.id] = currValue;
    return accum;
  }, {});
  return { ...state, xbrlTagsLookup };
}

function createXbrlTag(state, xbrlTag) {
  const xbrlTagsLookup = { ...state.xbrlTagsLookup };
  xbrlTagsLookup[xbrlTag.id] = xbrlTag;

  return { ...state, xbrlTagsLookup };
}

// -----------------------------------------------------
// Reducer

function xbrlTagsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_XBRL_TAGS:
      return setXbrlTags(state, action.payload);

    case actionTypes.CREATE_XBRL_TAG:
      return createXbrlTag(state, action.payload);

    default:
      return state;
  }
}

export default xbrlTagsReducer;
