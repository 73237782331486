export const calculateTextWidth = (
  text,
  fontSize = "16px",
  fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
) => {
  if (text === undefined || text === null) {
    text = "";
  }

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize} ${fontFamily}`;
  const width = context.measureText(text).width;
  return width + 10; // Adding some padding
};
