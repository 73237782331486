import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AuthHelper from "../../helpers/authHelper";
import { setAuthenticatedUser } from "../../actions/authActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../routes";
import { setUpApp } from "../../App";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  field: {
    marginTop: 10,
    marginBottom: 10,
    display: "block",
  },
  button: {
    marginTop: 10,
    marginBottom: 10,
  },
  errorMessage: {
    fontSize: 14,
  },
});

const USERNAME_NOT_CONFIRMED_EXCEPTION =
  "UserNotConfirmedException: User is not confirmed.";

/**
 * COMPONENT
 */
const LogIn = (props) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [logInError, setLogInError] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [setupComplete, setSetupComplete] = useState(false);
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );

  useEffect(() => {
    if (
      location &&
      location.state &&
      Array.isArray(location.state) &&
      location.state.length > 0 &&
      location.state[0].cameFrom
    ) {
      setUsername(location.state[0].cameFrom);
    }
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoggingIn(true);

    try {
      const authUser = await AuthHelper.Instance().logIn(username, password);
      await setUpApp(setIsLoggingIn, dispatch);
      setSetupComplete(true);
    } catch (err) {
      if (err.message === USERNAME_NOT_CONFIRMED_EXCEPTION) {
        history.push(routes.CONFIRM_EMAIL, [
          { username: username, cameFrom: location },
        ]);
      }
      setLogInError(err.message);
    }

    setIsLoggingIn(false);
  };

  useEffect(() => {
    if (setupComplete && authenticatedUser) {
      history.push(routes.HOME);
    }
  }, [setupComplete, authenticatedUser, history]);

  return isLoggingIn ? (
    <CircularProgress />
  ) : (
    <div>
      <form onSubmit={handleSubmit} name={"login"}>
        <div>
          <TextField
            label="Username"
            variant="outlined"
            name="username"
            className={classes.field}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <TextField
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            className={classes.field}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <Button
            type="submit"
            variant="outlined"
            className={classes.button}
            onClick={handleSubmit}
          >
            Log in
          </Button>
        </div>
        {logInError ? (
          <Typography className={classes.errorMessage}>
            Failed log-in. Error message: {logInError}
          </Typography>
        ) : null}
      </form>
    </div>
  );
};

export default LogIn;
