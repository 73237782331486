class FinDataFootNote {
  /**
   * @param {number} number
   * @param {string} text
   */
  constructor(number, text) {
    this.number = number;
    this.text = text;
  }
}

export default FinDataFootNote;
