import gql from "graphql-tag";

export const CompanyFragment_user = gql`
  fragment CompanyFragment_user on Company {
    id
    fiscalYearEnd
    registrantName
    symbol
  }
`;

export const CompanyFinDataTableMetricOrderFragment_user = gql`
  fragment CompanyFinDataTableMetricOrderFragment_user on CompanyFinDataTableMetricOrder {
    id
    companyId
    metricId
    order
    finDataTableId
  }
`;

export const DatumFragment_SecFiling_user = gql`
  fragment DatumFragment_SecFiling_user on Datum {
    id
    unableToConfidentlyExtractData
    value
    isSnapshot
    timePeriodInterval
    timePeriodValue
    timePeriodEndDate
    symbol
    qualifiers
    isGuidance
    asOfDate
    companyOrMarketData
    comparisonPeriod
    humanReviewed
    metricId
    earningsTranscriptId
    tenQOrKId
    companyId
    metricPlainEnglishOverride
  }
`;

export const DatumFragment_EarningsTranscript_user = gql`
  fragment DatumFragment_EarningsTranscript_user on Datum {
    id
    sentence
    unableToConfidentlyExtractData
    value
    isSnapshot
    timePeriodInterval
    timePeriodValue
    timePeriodEndDate
    symbol
    qualifiers
    isGuidance
    asOfDate
    companyOrMarketData
    comparisonPeriod
    humanReviewed
    metricId
    earningsTranscriptId
    tenQOrKId
    companyId
    metricPlainEnglishOverride
  }
`;

export const EarningsReleaseDateFragment_user = gql`
  fragment EarningsReleaseDateFragment_user on EarningsReleaseDate {
    id
  }
`;

export const EarningsTranscriptFragment_user = gql`
  fragment EarningsTranscriptFragment_user on EarningsTranscript {
    id
    content
    date
    quarter
    symbol
    year
    companyId
    earningsReleaseDateId
  }
`;

export const FinancialStatementFragment_user = gql`
  fragment FinancialStatementFragment_user on FinancialStatement {
    id
  }
`;

export const FinancialStatementTypeFragment_user = gql`
  fragment FinancialStatementTypeFragment_user on FinancialStatementType {
    id
  }
`;

export const FinDataTableFragment_user = gql`
  fragment FinDataTableFragment_user on FinDataTable {
    id
    key
    name
  }
`;

export const MetricFragment_user = gql`
  fragment MetricFragment_user on Metric {
    id
    metric
    metricPlainEnglish
    valueType
    type
    formula
  }
`;

export const MetricFinDataTableFragment_user = gql`
  fragment MetricFinDataTableFragment_user on MetricFinDataTable {
    id
  }
`;

export const TenQOrKFragment_user = gql`
  fragment TenQOrKFragment_user on TenQOrK {
    id
    filingDate
    reportDate
    form
    reportYear
    reportMonth
    S3Key
    symbol
    companyId
    isEditedAndStyled
  }
`;

export const XBRLTagFragment_user = gql`
  fragment XBRLTagFragment_user on XBRLTag {
    id
  }
`;

export const XBRLTagTypeFragment_user = gql`
  fragment XBRLTagTypeFragment_user on XBRLTagType {
    id
  }
`;

export const XSDFileFragment_user = gql`
  fragment XSDFileFragment_user on XSDFile {
    id
  }
`;
