import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

function FinDataSelectInput({
  lookupObject, // Type { id: object }
  id, // String, used for the id and labelId of Select as well as id of InputLabel
  selectedId, // String
  setSelectedId, // Function
  inputLabel, // String
  renderMenuItem, // Function, used to convert the object into in lookupObject into a string to display in the MenuItem
  style,
}) {
  return (
    <FormControl style={style ? style : {}}>
      {inputLabel && <InputLabel id={`${id}-label`}>{inputLabel}</InputLabel>}
      <Select
        labelId={`${id}-label`}
        id={`${id}`}
        value={selectedId}
        onChange={(event) => setSelectedId(event.target.value)}
        defaultValue=""
      >
        {Object.keys(lookupObject).map((key) => {
          const currentObjectInLoop = lookupObject[key];
          return (
            <MenuItem key={key} value={key}>
              {renderMenuItem(currentObjectInLoop)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default FinDataSelectInput;
