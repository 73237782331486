import awsConfig from "../aws-exports.json";
import axios from "axios";
import { Amplify, API, graphqlOperation } from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import AuthHelper from "./authHelper";
import {
  COGNITO_USER_GROUP_ADMIN,
  AUTH_MODE_API_KEY,
  AUTH_MODE_AMAZON_COGNITO_USER_POOLS,
} from "../constants";

// GQLHelper Class

const DEFAULT_LIST_QUERY_INPUT = {
  nextToken: null,
  limit: 50000,
};

export class GQLHelper {
  constructor(awsConfig) {
    if (GQLHelper.Instance) {
      throw new Error("There is already an instance of GQLHelper");
    }
    GQLHelper.Instance = this;
    this.awsConfig = awsConfig;
    API.configure(awsConfig);
    Auth.configure(awsConfig);
  }

  async executeMutation(query, params) {
    const authenticatedUser =
      await AuthHelper.Instance().getAuthenticatedUser();
    const isAdmin =
      authenticatedUser &&
      authenticatedUser.groups &&
      Array.isArray(authenticatedUser.groups) &&
      authenticatedUser.groups.length > 0 &&
      authenticatedUser.groups.includes(COGNITO_USER_GROUP_ADMIN);

    try {
      let response = await API.graphql({
        ...graphqlOperation(query, params),
        authMode: isAdmin
          ? AUTH_MODE_AMAZON_COGNITO_USER_POOLS
          : AUTH_MODE_API_KEY,
      });
      let [payload] = this.destructureGQLClientResponse(response);
      return payload;
    } catch (err) {
      if (Array.isArray(err.errors) && err.errors.length > 0) {
        throw new Error(err.errors[0].message);
      } else {
        throw err;
      }
    }
  }

  async executeGetQuery(query, params) {
    const authenticatedUser =
      await AuthHelper.Instance().getAuthenticatedUser();
    const isAdmin =
      authenticatedUser &&
      authenticatedUser.groups &&
      Array.isArray(authenticatedUser.groups) &&
      authenticatedUser.groups.length > 0 &&
      authenticatedUser.groups.includes(COGNITO_USER_GROUP_ADMIN);

    try {
      let response = await API.graphql({
        ...graphqlOperation(query, params),
        authMode: isAdmin
          ? AUTH_MODE_AMAZON_COGNITO_USER_POOLS
          : AUTH_MODE_API_KEY,
      });
      let [payload] = this.destructureGQLClientResponse(response);
      return payload;
    } catch (err) {
      if (Array.isArray(err.errors) && err.errors.length > 0) {
        throw new Error(err.errors[0].message);
      } else {
        throw err;
      }
    }
  }

  async executeListQuery(query, input) {
    const authenticatedUser =
      await AuthHelper.Instance().getAuthenticatedUser();
    const isAdmin =
      authenticatedUser &&
      authenticatedUser.groups &&
      Array.isArray(authenticatedUser.groups) &&
      authenticatedUser.groups.length > 0 &&
      authenticatedUser.groups.includes(COGNITO_USER_GROUP_ADMIN);

    const MAX_ITERATIONS = 10;
    let completedIterations = 0;
    let returnArray = [];
    input = Object.assign({ ...DEFAULT_LIST_QUERY_INPUT }, input);

    try {
      do {
        let response = await API.graphql({
          ...graphqlOperation(query, input),
          authMode: isAdmin
            ? AUTH_MODE_AMAZON_COGNITO_USER_POOLS
            : AUTH_MODE_API_KEY,
        });

        if (response.data.errors && response.data.errors.length > 0) {
          for (const idx in response.data.errors) {
            // Nothing to do here
          }
        }
        let [payload, nextToken] = this.destructureGQLClientResponse(response);

        if (Array.isArray(payload) && payload.length > 0) {
          returnArray = returnArray.concat(payload);
        }

        input.nextToken = nextToken;

        completedIterations++;
      } while (input.nextToken && completedIterations < MAX_ITERATIONS);
    } catch (err) {
      if (Array.isArray(err.errors) && err.errors.length > 0) {
        throw new Error(err.errors[0].message);
      } else {
        throw err;
      }
    }
    return returnArray;
  }

  destructureGQLClientResponse(response) {
    let queryName = Object.keys(response.data)[0];
    let nestedResponse = response.data[queryName];
    let nextToken = nestedResponse && nestedResponse.nextToken;
    let payload = nestedResponse
      ? Object.prototype.hasOwnProperty.call(nestedResponse, "items")
        ? nestedResponse.items
        : nestedResponse
      : null;
    return [payload, nextToken];
  }

  static init(awsConfig) {
    return new GQLHelper(awsConfig);
  }
}
