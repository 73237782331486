import actionTypes from "./actionTypes";
import {
  listMetricFinDataTables,
  listMetricFinDataTables_user,
} from "../gql/gqlHelperQueriesLayer";
import { createMetricFinDataTable } from "../gql/gqlHelperMutationsLayer";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllMetricFinDataTables = async (dispatch, userGroups) => {
  const isUserAdmin = determineIsUserAdmin(userGroups);

  let metricFinDataTables = isUserAdmin
    ? await listMetricFinDataTables()
    : // Harcoding to an empty array for now, can change if needed
      [];

  dispatch({
    type: actionTypes.LOAD_ALL_METRIC_FIN_DATA_TABLES,
    payload: metricFinDataTables,
  });
};

export const createMetricFinDataTableAction = async (
  dispatch,
  createMetricFinDataTableInput
) => {
  const createdMetricFinDataTableResponse = await createMetricFinDataTable(
    createMetricFinDataTableInput
  );

  dispatch({
    type: actionTypes.CREATE_METRIC_FIN_DATA_TABLE,
    payload: createdMetricFinDataTableResponse,
  });

  return createdMetricFinDataTableResponse;
};
