class FinDataTableCell {
  /**
   * @param {string} value
   * @param {string} cellAddress
   * @param {Object} metadata
   * @param {function} onClick
   * @param {ReactRouterHashLinkMeta} reactRouterHashLinkMeta
   */
  constructor(value, cellAddress, metadata, onClick, reactRouterHashLinkMeta) {
    this.value = value;
    this.cellAddress = cellAddress;
    this.metadata = metadata;
    this.onClick = onClick; // Separate from the the onClick within reactRouterHashLinkMeta
    this.reactRouterHashLinkMeta = reactRouterHashLinkMeta;
  }
}

export default FinDataTableCell;
