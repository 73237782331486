import actionTypes from "../actions/actionTypes";

const initialState = {
  filingsLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setFilings(state, filingsArray) {
  const filingsLookup = filingsArray.reduce((accum, currValue) => {
    accum[currValue.id] = currValue;
    return accum;
  }, {});
  return { ...state, filingsLookup };
}

function updateFiling(state, updatedFiling) {
  return {
    ...state,
    filingsLookup: {
      ...state.filingsLookup,
      [updatedFiling.id]: {
        ...updatedFiling,
      },
    },
  };
}

// -----------------------------------------------------
// Reducer

function filingsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_FILINGS:
      return setFilings(state, action.payload);

    case actionTypes.UPDATE_FILING:
      return updateFiling(state, action.payload);

    default:
      return state;
  }
}

export default filingsReducer;
