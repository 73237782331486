import React, { useState, useEffect, useRef } from "react";

import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentFilingFinancialStatements } from "../../store/selectors";

import { createDatumAction } from "../../actions/datumsActions";

import { getDatum } from "../../gql/gqlHelperQueriesLayer";

import CreateNewMetric from "../SimpleDynamoDBForms/CreateNewMetric";
import CreatNewXbrlTag from "../SimpleDynamoDBForms/CreateNewXbrlTag";
import SingularDatumReview10QOrK from "./SingularDatumReview10QOrK";

import ButtonWithCircularProgress from "../ButtonWithCircularProgress";

import {
  extractIDsFromHtml,
  condenseFinancialStatementToOnlyBeFieldsRelevantToSentence,
} from "../../helpers/htmlHelpers";

import { convertFinancialStatementToAppLabel } from "../../helpers/financialStatementHelpers";
import FinDataSelectInput from "../FinDataSelectInput";

const useStyles = makeStyles((theme) => ({
  createDatumForm: {
    width: "100%",
    maxHeight: "100%",
    overflowY: "scroll",
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  numberInput: {
    minWidth: 120,
    marginRight: "12px",
  },
}));

function CreateDatumForm({
  sentence,
  setSentence,
  paragraph,
  setParagraph,
  setSidePanelClosed,
  metadata,
  filing,
  filingContent,
}) {
  const classes = useStyles();
  const sentenceFieldRef = useRef();
  const paragraphFieldRef = useRef();
  const dispatch = useDispatch();

  const [metricId, setMetricId] = useState("");

  const [selectFinancialStatement, setSelectFinancialStatement] =
    useState(false);
  const [condenseTableInParagraph, setCondenseTableInParagraph] =
    useState(false);

  // Is Saving New Datum
  const [isSaving, setIsSaving] = useState(false);

  const [datumCreated, setDatumCreated] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [datum, setDatum] = useState(null);

  const [numHeadersToKeep, setNumHeadersToKeep] = useState(5);
  const [numRowsBeforeSentenceToKeep, setNumRowsBeforeSentenceToKeep] =
    useState(3);
  const [numRowsAfterSentenceToKeep, setNumRowsAfterSentenceToKeep] =
    useState(1);

  const metricsLookup = useSelector((state) => state.metrics.metricsLookup);
  const currentFilingFinancialStatementsLookup = useSelector((state) =>
    selectCurrentFilingFinancialStatements(state, filing)
  );
  const [selectedFinancialStatementId, setSelectedFinancialStatementId] =
    useState(null);

  const xbrlTagsLookup = useSelector((state) => state.xbrlTags.xbrlTagsLookup);
  const xbrlTagTypesLookup = useSelector(
    (state) => state.xbrlTagTypes.xbrlTagTypesLookup
  );

  const [selectedXbrlTagId, setSelectedXbrlTagId] = useState(null);

  const handleFinancialStatementChange = (event) => {
    setSelectedFinancialStatementId(event.target.value);
  };

  const handleXbrlTagChange = (event) => {
    setSelectedXbrlTagId(event.target.value);
  };

  useEffect(() => {
    // Commenting out the sentence field event listener for now because
    // I find it useful to copy and paste from the sentence field

    if (sentenceFieldRef.current && paragraphFieldRef.current) {
      // sentenceFieldRef.current.addEventListener("focus", setSidePanelClosed);
      paragraphFieldRef.current.addEventListener("focus", setSidePanelClosed);
    }

    return () => {
      if (sentenceFieldRef.current && paragraphFieldRef.current) {
        // sentenceFieldRef.current.removeEventListener(
        //   "focus",
        //   setSidePanelClosed
        // );
        paragraphFieldRef.current.removeEventListener(
          "focus",
          setSidePanelClosed
        );
      }
    };
  }, [setSidePanelClosed]);

  const handleMetricChange = (event) => {
    setMetricId(event.target.value);
  };

  const handleCreateDatum = async () => {
    setIsSaving(true);

    // Extract IDs from the grabbed sentence
    const sentenceIds = extractIDsFromHtml(sentence);

    const createDatumInput = {
      sentence: sentence,
      metricId: metricId,
      transcriptMetadata: metadata,
      tenQOrKId: filing.id,
      companyId: filing.companyId,
      innerContextXmlIds: sentenceIds,
      humanReviewed: false,
      xbrlTagId: selectedXbrlTagId,
      financialStatementId: selectedFinancialStatementId,
    };

    if (selectFinancialStatement || condenseTableInParagraph) {
      createDatumInput.paragraph =
        condenseFinancialStatementToOnlyBeFieldsRelevantToSentence(
          selectFinancialStatement
            ? currentFilingFinancialStatementsLookup[
                selectedFinancialStatementId
              ].rawText
            : paragraph,
          sentence,
          numHeadersToKeep,
          numRowsBeforeSentenceToKeep,
          numRowsAfterSentenceToKeep
        );
    } else {
      createDatumInput.paragraph = paragraph;
      createDatumInput.outterContextXmlIds = extractIDsFromHtml(paragraph);
    }

    try {
      const createdDatum = await createDatumAction(dispatch, createDatumInput);

      // Begin the polling after the initial datum is created
      await pollForCompletion(createdDatum.id, 0); // Start the polling with 0 attempts
    } catch (error) {
      console.error(error);
      setSaveError(error);
      setIsSaving(false);
    }
  };

  const pollForCompletion = async (datumId, attempts) => {
    const MAX_ATTEMPTS = 15;
    // If we've already tried 20 times, we stop and set the error state.
    if (attempts >= MAX_ATTEMPTS) {
      setDatumCreated(false);
      setSaveError("Something went wrong on the backend");
      setIsSaving(false);
      return;
    }

    // Wait for 5 seconds
    setTimeout(async () => {
      try {
        const result = await getDatum(datumId);

        if (result && result.rawCompletion) {
          // If rawCompletion is truthy, we update the state and finish.
          setDatum(result);
          setDatumCreated(true);
          setIsSaving(false);
        } else if (result && result.completionError) {
          setDatumCreated(false);
          setSaveError(result.completionError);
          setIsSaving(false);
        } else {
          // Otherwise, we poll again
          pollForCompletion(datumId, attempts + 1);
        }
      } catch (error) {
        console.error(error);
        setDatumCreated(false);
        setSaveError("Error fetching updated datum");
        setIsSaving(false);
      }
    }, 5000); // 5000ms or 5 seconds
  };

  const metric = metricsLookup[metricId];

  return (
    <div className={classes.createDatumForm}>
      {!datum ? (
        <div>
          <div className={classes.title}>Create Datum Form</div>

          <TextField
            id="sentence"
            label="Sentence"
            value={sentence}
            onChange={(e) => setSentence(e.target.value)}
            className={classes.formControl}
            style={{ marginTop: "12px" }}
            inputRef={sentenceFieldRef}
            multiline
            inputProps={{ ref: sentenceFieldRef }}
          />

          <Typography style={{ marginTop: "12px" }}>
            Select financial statement
          </Typography>
          <Checkbox
            checked={selectFinancialStatement}
            onChange={(e) => setSelectFinancialStatement(e.target.checked)}
            className={classes.inputContainer}
          />

          {!selectFinancialStatement ? (
            <TextField
              id="paragraph"
              label="Paragraph"
              value={paragraph}
              onChange={(e) => setParagraph(e.target.value)}
              className={classes.formControl}
              style={{ marginTop: "12px" }}
              inputRef={paragraphFieldRef}
              multiline
              inputProps={{ ref: paragraphFieldRef }}
            />
          ) : (
            <FinDataSelectInput
              lookupObject={currentFilingFinancialStatementsLookup}
              id="financialStatement"
              selectedId={selectedFinancialStatementId}
              setSelectedId={setSelectedFinancialStatementId}
              inputLabel="Financial Statement"
              style={{ marginTop: "12px", minWidth: 120, width: "100%" }}
              renderMenuItem={(financialStatement) => {
                return convertFinancialStatementToAppLabel(financialStatement);
              }}
            />
          )}
          <FinDataSelectInput
            lookupObject={metricsLookup}
            id="metric"
            selectedId={metricId}
            setSelectedId={setMetricId}
            inputLabel="Metric"
            style={{ marginTop: "12px", minWidth: 120, width: "100%" }}
            renderMenuItem={(metric) => {
              return metric.metric;
            }}
          />

          <div style={{ marginTop: "24px" }}>
            <CreateNewMetric />
          </div>

          <FinDataSelectInput
            lookupObject={xbrlTagsLookup}
            id="xbrl-tag"
            selectedId={selectedXbrlTagId}
            setSelectedId={setSelectedXbrlTagId}
            inputLabel="XBRL Tag (Optional)"
            style={{ marginTop: "12px", minWidth: 120, width: "100%" }}
            renderMenuItem={(xbrlTag) => {
              const xbrlTagType = xbrlTagTypesLookup[xbrlTag.xbrlTagTypeId];
              return `${xbrlTagType.type}:${xbrlTag.tag}`;
            }}
          />

          <div style={{ marginTop: "24px" }}>
            <CreatNewXbrlTag />
          </div>

          <TextField
            id="metadata"
            label="Metadata"
            value={metadata}
            className={classes.formControl}
            style={{ marginTop: "12px" }}
            disabled
            multiline
          />

          <Typography style={{ marginTop: "12px" }}>
            Condense Table in Paragraph
          </Typography>
          <Checkbox
            checked={condenseTableInParagraph}
            onChange={(e) => setCondenseTableInParagraph(e.target.checked)}
            className={classes.inputContainer}
          />

          {(selectFinancialStatement || condenseTableInParagraph) && (
            <div
              style={{
                marginTop: "12px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl className={classes.numberInput}>
                <InputLabel shrink htmlFor="numHeadersToKeep">
                  Headers to Keep
                </InputLabel>
                <Input
                  id="numHeadersToKeep"
                  type="number"
                  value={numHeadersToKeep}
                  onChange={(e) => setNumHeadersToKeep(Number(e.target.value))}
                />
              </FormControl>

              <FormControl className={classes.numberInput}>
                <InputLabel shrink htmlFor="numRowsBeforeSentenceToKeep">
                  Rows Before Sentence
                </InputLabel>
                <Input
                  id="numRowsBeforeSentenceToKeep"
                  type="number"
                  value={numRowsBeforeSentenceToKeep}
                  onChange={(e) =>
                    setNumRowsBeforeSentenceToKeep(Number(e.target.value))
                  }
                />
              </FormControl>

              <FormControl className={classes.numberInput}>
                <InputLabel shrink htmlFor="numRowsAfterSentenceToKeep">
                  Rows After Sentence
                </InputLabel>
                <Input
                  id="numRowsAfterSentenceToKeep"
                  type="number"
                  value={numRowsAfterSentenceToKeep}
                  onChange={(e) =>
                    setNumRowsAfterSentenceToKeep(Number(e.target.value))
                  }
                />
              </FormControl>
            </div>
          )}
          <div style={{ marginTop: "12px" }}>
            <ButtonWithCircularProgress
              onClick={handleCreateDatum}
              disabled={datumCreated}
              isSaving={isSaving}
              buttonText="Create Datum"
            />
          </div>

          {saveError && <div style={{ marginTop: "12px" }}>{saveError}</div>}
        </div>
      ) : (
        <SingularDatumReview10QOrK
          datum={datum}
          setSidePanelClosed={setSidePanelClosed}
        />
      )}
    </div>
  );
}

export default CreateDatumForm;
