import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLocation, matchPath } from "react-router-dom";
import routes from "../../routes";
import ViewEarningsTranscript from "./ViewEarningsTranscript";
import { HashLink as Link } from "react-router-hash-link";
import LinkIcon from "@material-ui/icons/Link";
import { linkStyle, linkIconStyle } from "../../styles";

const useStyles = makeStyles({
  quarterHeader: {
    marginBottom: 10,
  },
  linkStyle: { ...linkStyle },
  linkIconStyle: { ...linkIconStyle },
});

function EarningsTranscripts(props) {
  const classes = useStyles();
  const currentLocation = useLocation();
  const [viewTranscriptId, setViewTranscriptId] = useState("");

  // This hook updates viewTranscriptId based on the current route.
  useEffect(() => {
    const viewTranscriptMatch = matchPath(currentLocation.pathname, {
      path: routes.EARNINGS_TRANSCRIPT,
    });

    if (
      viewTranscriptMatch &&
      viewTranscriptMatch.params &&
      viewTranscriptMatch.params.earningsTranscriptId
    ) {
      setViewTranscriptId(viewTranscriptMatch.params.earningsTranscriptId);
    } else {
      setViewTranscriptId("");
    }
  }, [currentLocation]);

  // Handler to save the current scroll position.
  const handleLinkClick = () => {
    sessionStorage.setItem(
      "EarningsTranscripts-ScrollPosition",
      window.scrollY.toString()
    );
  };

  // This effect restores the scroll position if there's no viewTranscriptId.
  useEffect(() => {
    if (!viewTranscriptId) {
      const savedScrollPosition = sessionStorage.getItem(
        "EarningsTranscripts-ScrollPosition"
      );
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
      }
    }
  }, [viewTranscriptId]);

  const transcriptsLookup = useSelector(
    (state) => state.transcripts.transcriptsLookup
  );
  const companiesLookup = useSelector(
    (state) => state.companies.companiesLookup
  );

  let earningsQuarters = new Set();
  Object.values(transcriptsLookup).forEach((transcript) => {
    earningsQuarters.add(`Q${transcript.quarter} ${transcript.year}`);
  });
  earningsQuarters = Array.from(earningsQuarters);
  let earningsTranscriptsByQuarter = {};
  Object.values(transcriptsLookup).forEach((transcript) => {
    let quarter = `Q${transcript.quarter} ${transcript.year}`;
    earningsTranscriptsByQuarter[quarter] = (
      earningsTranscriptsByQuarter[quarter] || []
    ).concat(transcript);
  });

  return (
    <div>
      {viewTranscriptId ? (
        <ViewEarningsTranscript
          transcript={transcriptsLookup[viewTranscriptId]}
        />
      ) : (
        <div>
          {earningsQuarters.map((quarter) => (
            <div key={quarter}>
              <h3 className={classes.quarterHeader}>{quarter} transcripts</h3>
              {earningsTranscriptsByQuarter[quarter].map(
                (earningsTranscript) => {
                  const company = companiesLookup[earningsTranscript.companyId];
                  return (
                    <div key={earningsTranscript.id}>
                      <Link
                        activeStyle={{ color: "black" }}
                        className={classes.linkStyle}
                        to={{
                          pathname: routes.insertParamsIntoRoutePath(
                            routes.EARNINGS_TRANSCRIPT,
                            {
                              earningsTranscriptId: earningsTranscript.id,
                            }
                          ),
                          search: "?source=transcriptsLink",
                        }}
                        onClick={handleLinkClick}
                      >
                        {`${earningsTranscript.symbol} - ${
                          company.registrantName
                        } - Q${earningsTranscript.quarter} ${
                          earningsTranscript.year
                        } earnings transcript - ${moment(
                          earningsTranscript.date
                        ).format("YYYY-MM-DD")} - Fiscal Year Ends ${
                          company.fiscalYearEnd
                        }`}
                        <LinkIcon className={classes.linkIconStyle} />
                      </Link>
                    </div>
                  );
                }
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default EarningsTranscripts;
