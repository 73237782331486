import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import {
  METRIC_TYPE_SEC_FILING,
  METRIC_TYPE_API,
  METRIC_TYPE_COMPUTED,
} from "../../constants"; // Adjust import paths as necessary

export default function renderCell({
  getFootnoteText,
  isHighlightedCell,
  colorizeCellInnerContent,
  finDataTable,
  classes,
  row,
  value,
  columnIndex,
}) {
  const cellFootnoteText = getFootnoteText(value.cellAddress);
  const isCellHighlighted = isHighlightedCell(row.index + 1, columnIndex + 1);
  const cellInnerContent = isCellHighlighted
    ? finDataTable?.highlightedCell?.metadata?.calculatedMetricFormulaString
    : value.value;
  const colorizedCellInnerContent = isCellHighlighted
    ? colorizeCellInnerContent(cellInnerContent, finDataTable)
    : cellInnerContent;

  const cellContent = (
    <>
      <span dangerouslySetInnerHTML={{ __html: colorizedCellInnerContent }} />
      {cellFootnoteText}
    </>
  );

  const rowHeader = row.values.rowHeader;
  if (rowHeader?.metadata?.metricType === METRIC_TYPE_SEC_FILING) {
    return (
      <Link
        smooth
        to={value.reactRouterHashLinkMeta.to}
        scroll={value.reactRouterHashLinkMeta.scroll}
        onClick={value.reactRouterHashLinkMeta.onClick}
        activeStyle={{ color: "black" }}
        className={classes.clickableBlueCell}
      >
        {cellContent}
      </Link>
    );
  }

  const cellClassName =
    rowHeader?.metadata?.metricType === METRIC_TYPE_API
      ? classes.greenTextCell
      : rowHeader?.metadata?.metricType === METRIC_TYPE_COMPUTED
      ? classes.clickableCell
      : "";

  return (
    <div
      className={cellClassName}
      onClick={() => {
        if (value.onClick) {
          value.onClick();
        }
      }}
    >
      {cellContent}
    </div>
  );
}
