class AuthUser {
  constructor(id, username, groups) {
    if (!id || !username) {
      throw new Error("Missing required parameter(s)");
    }

    this.id = id;
    this.username = username;
    this.groups = groups;
  }
}

export default AuthUser;
