import React from "react";
import FinDataTableFromLookupObj from "../FinDataTableFromLookupObj";
import FinDataTableCell from "../../classes/FinDataTableCell";

function ViewCompanyMetricsForFinDataTable({
  finDataTableKey,
  finDataTablesLookup,
  metricsInUseForCompanyLookup,
}) {
  // First let's grab the finDataTableId from the finDataTableKey
  const finDataTableId = Object.values(finDataTablesLookup).find(
    (finDataTable) => finDataTable.key === finDataTableKey
  )?.id;

  const metricsInUseForCompanyForFinDataTable = Object.values(
    metricsInUseForCompanyLookup
  )
    .filter((metric) => metric.finDataTableId === finDataTableId)
    .reduce((agg, metric) => {
      return {
        ...agg,
        [metric.id]: metric,
      };
    }, {});

  return (
    <FinDataTableFromLookupObj
      lookupObj={metricsInUseForCompanyForFinDataTable}
      columnHeaders={[
        new FinDataTableCell("id"),
        new FinDataTableCell("metric"),
      ]}
    />
  );
}

export default ViewCompanyMetricsForFinDataTable;
