/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://ai4hnn2qtbebbmssmtoid7ci4i.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-cnfeagvv4zg3feq3jynmixjq4a",
    "aws_cognito_identity_pool_id": "us-east-1:b98ad6cf-6f9d-4b21-ae78-fba8b62cf8ed",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_613FrfFce",
    "aws_user_pools_web_client_id": "1ipjlo22t935t52412lvijihtj",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "authcra99b749856a974566a9085b7b6263d837161819-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
