import { useState, useRef, useEffect } from "react";
import { determineDifferentKeysBetweenTwoObjects } from "../../helpers/miscHelpers";

export default function useFormChangeHook(emptyForm) {
  let initialForm = useRef(emptyForm);
  const [form, _setForm] = useState(emptyForm);
  const [formChanged, setFormChanged] = useState(false);
  const [changedKeys, setChangedKeys] = useState([]);

  const resetInitialForm = (resetInitialFormInput) => {
    let newForm = { ...resetInitialFormInput };
    initialForm.current = newForm;
    setForm(newForm);
  };

  const setForm = (updatedForm) => {
    _setForm((oldForm) => ({ ...oldForm, ...updatedForm }));
  };

  useEffect(() => {
    let updatedForm = { ...form };
    const differentKeys = determineDifferentKeysBetweenTwoObjects(
      updatedForm,
      initialForm.current
    );

    setFormChanged(differentKeys.length > 0);
    setChangedKeys(differentKeys);
  }, [form]);

  return {
    form,
    setForm,
    formChanged,
    changedKeys,
    resetInitialForm,
  };
}
