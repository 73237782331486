import actionTypes from "./actionTypes";
import {
  listXBRLTagTypes,
  listXBRLTagTypes_user,
} from "../gql/gqlHelperQueriesLayer";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllXBRLTagTypes = async (dispatch, userGroups) => {
  const isUserAdmin = determineIsUserAdmin(userGroups);

  let xbrlTagTypes = isUserAdmin
    ? await listXBRLTagTypes()
    : // Harcoding to an empty array for now, can change if needed
      [];

  dispatch({
    type: actionTypes.LOAD_ALL_XBRL_TAG_TYPES,
    payload: xbrlTagTypes,
  });
};
