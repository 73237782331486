import actionTypes from "../actions/actionTypes";

const initialState = {
  metricFinDataTablesLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setMetricFinDataTables(state, metricFinDataTablesArray) {
  const metricFinDataTablesLookup = metricFinDataTablesArray.reduce(
    (accum, currValue) => {
      accum[currValue.id] = currValue;
      return accum;
    },
    {}
  );
  return { ...state, metricFinDataTablesLookup };
}

function createMetricFinDataTable(state, metricFinDataTable) {
  const metricFinDataTablesLookup = {
    ...state.metricFinDataTablesLookup,
  };
  metricFinDataTablesLookup[metricFinDataTable.id] = metricFinDataTable;
  return { ...state, metricFinDataTablesLookup };
}

// -----------------------------------------------------
// Reducer

function metricFinDataTablesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_METRIC_FIN_DATA_TABLES:
      return setMetricFinDataTables(state, action.payload);

    case actionTypes.CREATE_METRIC_FIN_DATA_TABLE:
      return createMetricFinDataTable(state, action.payload);

    default:
      return state;
  }
}

export default metricFinDataTablesReducer;
