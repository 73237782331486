import FinDataFootnote from "./FinDataFootnote";

/**
 * The FinDataTable class is designed to display and manage financial data within a table structure.
 * It incorporates functionalities to add, edit, and remove footnotes associated with specific cells,
 * highlight a cell upon user interaction, and manage annotations or other metadata for each cell
 * in a structured and comprehensible manner.
 *
 * Row and Column Indexing Overview:
 * - Internally, cells within the table are indexed starting at r0c0, mapping this to Excel-style
 *   alphanumeric addressing, starting from A1. This addressing scheme is used for identifying cells,
 *   where columns are labeled with letters (A, B, C, ...) and rows with numbers starting from 1.
 *
 * - Column headers start from "A", row headers from "1", and data cells populate the table starting
 *   from "B2" in Excel-style addressing, reflecting the internal structure and logical separation of
 *   data within the table.
 *
 * - The table is capable of extending beyond the 26th column by employing additional letters,
 *   adhering to Excel's addressing convention (e.g., "AA", "AB", ...), accommodating a vast range of data.
 *
 * Footnote and Highlight Lookup Mechanisms:
 * - The `footnotesLookup` utilizes a key-value store, with each key being a string formatted in
 *   Excel-style addressing. This scheme associates footnotes with their respective cells, enhancing
 *   data annotation and interpretation.
 *
 * - The `highlightedCell` property is an instance of FinDataTableHighlightedCell, encapsulating
 *   the highlighted cell's value, address, and any supplementary metadata. The cell address is
 *   expressed in Excel-style alphanumeric format (e.g., "A1", "B2"), facilitating easy identification.
 *
 * - Both footnotes and highlights can be associated with column headers, row headers, and data cells,
 *   enabling diverse annotations across the table for various financial data representations.
 *
 * - The `cellAddress` property in FinDataTableCell instances serves as a generalized reference,
 *   linking cells to their annotations or interactions in Excel-style alphanumeric format, promoting
 *   clarity and consistency in data management.
 *
 * This indexing, footnote association, and cell highlighting mechanism offer a flexible and robust
 * framework for annotating and interacting with financial data within the table, significantly
 * enhancing the clarity and utility of the presented information.
 */
class FinDataTable {
  /**
   * @param {string} tableTitle
   * @param {string} tableSubTitle
   * @param {FinDataTableCell[]} columnHeaders
   * @param {FinDataTableCell[]} rowHeaders
   * @param {FinDataTableCell[][]} data
   * @param {number[]} columnWidths
   * @param {number[]} rowHeights
   * @param {Set<string>} filingsIds
   */
  constructor(
    tableTitle,
    tableSubTitle,
    columnHeaders,
    rowHeaders,
    data,
    columnWidths,
    rowHeights,
    filingsIds
  ) {
    this.tableTitle = tableTitle;
    this.tableSubTitle = tableSubTitle;
    this.columnHeaders = columnHeaders;
    this.rowHeaders = rowHeaders;
    this.data = data;
    this.columnWidths = columnWidths;
    this.rowHeights = rowHeights;
    this.height = rowHeaders.length + 1;
    this.width = columnHeaders.length + 1;
    this.filingsIds = filingsIds;

    /**
     * @type {{string: FinDataFootnote}}
     */
    this.footnotesLookup = {};
    /**
     * @type {FinDataTableHighlightedCell}
     */
    this.highlightedCell = null;
    /**
     * @type {FinDataTableHighlightedCell[]}
     */
    this.highlightedSourceCells = [];
  }

  /**
   * Add a footnote to a specific cell and update the cell's reference.
   * @param {number} rowIdx - The row index of the cell to add the footnote to.
   * @param {number} columnIdx - The column index of the cell to add the footnote to.
   * @param {string} footnoteText - The text of the footnote to add.
   */
  addFootnote(rowIdx, columnIdx, footnoteText) {
    if (rowIdx === 0 && columnIdx === 0) {
      throw new Error("Cell at row 0, column 0 does not exist.");
    }

    const newFootnoteNumber = this.calculateFootnoteNumber(rowIdx, columnIdx);
    const columnAddress = this.transformNumericColumnIndexToAlphanumeric(
      columnIdx + 1
    );
    const rowAddress = this.transformNumericRowIndexToAlphanumeric(rowIdx);
    const key = `${columnAddress}${rowAddress}`;

    // Update existing footnotes' numbers if necessary
    Object.values(this.footnotesLookup).forEach((footnote) => {
      if (footnote.number >= newFootnoteNumber) {
        footnote.number += 1;
      }
    });

    // Create and store the new footnote
    this.footnotesLookup[key] = new FinDataFootnote(
      newFootnoteNumber,
      footnoteText
    );

    // Associate the footnote with the correct element based on its indices
    if (rowIdx === 0) {
      // Column header
      if (columnIdx > 0 && columnIdx < this.columnHeaders.length + 1) {
        this.columnHeaders[columnIdx - 1].cellAddress = key;
      } else {
        console.warn(`Column header at column ${columnIdx} does not exist.`);
      }
    } else if (columnIdx === 0) {
      // Row header
      if (rowIdx > 0 && rowIdx < this.rowHeaders.length + 1) {
        this.rowHeaders[rowIdx - 1].cellAddress = key;
      } else {
        console.warn(`Row header at row ${rowIdx} does not exist.`);
      }
    } else {
      // Data cell
      if (this.data[rowIdx - 1] && this.data[rowIdx - 1][columnIdx - 1]) {
        this.data[rowIdx - 1][columnIdx - 1].cellAddress = key;
      } else {
        console.warn(
          `Cell at row ${rowIdx}, column ${columnIdx} does not exist.`
        );
      }
    }
  }

  /**
   * Edit the text of an existing footnote.
   * @param {number} rowIdx - The row index of the cell whose footnote is to be edited.
   * @param {number} columnIdx - The column index of the cell whose footnote is to be edited.
   * @param {string} newText - The new text for the footnote.
   * @param {boolean} replaceExisting - Whether to replace the existing footnote text (default: false).
   */
  editFootnote(rowIdx, columnIdx, newText, replaceExisting = false) {
    // Convert numeric indices to Excel-style alphanumeric
    const columnAddress = this.transformNumericColumnIndexToAlphanumeric(
      columnIdx + 1
    );
    const rowAddress = this.transformNumericRowIndexToAlphanumeric(rowIdx);
    const key = `${columnAddress}${rowAddress}`;

    // Check if the footnote exists in the lookup
    if (this.footnotesLookup[key]) {
      if (replaceExisting) {
        this.footnotesLookup[key].text = newText; // Replace the existing text
      } else {
        this.footnotesLookup[key].text += newText; // Append the new text
      }
    } else {
      console.warn(`Footnote at ${key} does not exist.`);
    }
  }

  /**
   * Remove a footnote from a specific cell and renumber subsequent footnotes.
   * @param {number} rowIdx - The row index of the cell whose footnote is to be removed.
   * @param {number} columnIdx - The column index of the cell whose footnote is to be removed.
   */
  removeFootnote(rowIdx, columnIdx) {
    // Convert numeric indices to Excel-style alphanumeric
    const columnAddress = this.transformNumericColumnIndexToAlphanumeric(
      columnIdx + 1
    );
    const rowAddress = this.transformNumericRowIndexToAlphanumeric(rowIdx);
    const key = `${columnAddress}${rowAddress}`;

    // Check if the footnote exists in the lookup
    if (this.footnotesLookup[key]) {
      // Remove the footnote from the lookup
      const removedFootnoteNumber = this.footnotesLookup[key].number;
      delete this.footnotesLookup[key];

      // Decrement the number of all subsequent footnotes
      Object.keys(this.footnotesLookup).forEach((footnoteKey) => {
        const footnote = this.footnotesLookup[footnoteKey];
        if (footnote.number > removedFootnoteNumber) {
          footnote.number -= 1;
        }
      });

      // Remove the footnote reference from the appropriate place
      if (rowIdx === 0 && columnIdx > 0) {
        // Column header
        if (this.columnHeaders[columnIdx - 1]) {
          this.columnHeaders[columnIdx - 1].cellAddress = null;
        }
      } else if (columnIdx === 0 && rowIdx > 0) {
        // Row header
        if (this.rowHeaders[rowIdx - 1]) {
          this.rowHeaders[rowIdx - 1].cellAddress = null;
        }
      } else {
        // Data cell
        if (this.data[rowIdx - 1] && this.data[rowIdx - 1][columnIdx - 1]) {
          this.data[rowIdx - 1][columnIdx - 1].cellAddress = null;
        }
      }
    } else {
      console.warn(`Footnote at ${key} does not exist.`);
    }
  }

  /**
   * Check if a cell in the table is currently highlighted.
   * @param {number} rowIdx - The row index of the cell to check for highlight.
   * @param {number} columnIdx - The column index of the cell to check for highlight.
   * @returns {boolean} - A boolean indicating whether the cell is highlighted.
   */
  checkIfCellIsHighlighted(rowIdx, columnIdx) {
    if (this.highlightedCell) {
      // Adjust the column index for Excel-style (A=1, B=2, etc.)
      const columnAddress =
        this.transformNumericColumnIndexToAlphanumeric(columnIdx);
      // Adjust the row index for Excel-style, starting from 1
      const rowAddress = (rowIdx + 1).toString();
      const cellAddressToCheck = `${columnAddress}${rowAddress}`;

      return this.highlightedCell.cellAddress === cellAddressToCheck;
    }
    return false;
  }

  /**
   * Check if a cell in the table is currently highlighted as a source cell.
   * @param {number} rowIdx - The row index of the cell to check for highlight.
   * @param {number} columnIdx - The column index of the cell to check for highlight.
   * @returns {boolean} - A boolean indicating whether the cell is highlighted as a source cell.
   */
  checkIfCellIsHighlightedSource(rowIdx, columnIdx) {
    // Adjust the column index for Excel-style (A=1, B=2, etc.)
    const columnAddress =
      this.transformNumericColumnIndexToAlphanumeric(columnIdx);
    // Adjust the row index for Excel-style, starting from 1
    const rowAddress = (rowIdx + 1).toString();
    const cellAddressToCheck = `${columnAddress}${rowAddress}`;

    return this.highlightedSourceCells.some(
      (cell) => cell.cellAddress === cellAddressToCheck
    );
  }

  /**
   * Grab the color of the highlighted source cell.
   * @param {number} rowIdx - The row index of the cell to check for highlight.
   * @param {number} columnIdx - The column index of the cell to check for highlight.
   * @returns {string} - The color of the highlighted source cell.
   */
  grabColorOfHighlightedSourceCell(rowIdx, columnIdx) {
    // Adjust the column index for Excel-style (A=1, B=2, etc.)
    const columnAddress =
      this.transformNumericColumnIndexToAlphanumeric(columnIdx);
    // Adjust the row index for Excel-style, starting from 1
    const rowAddress = (rowIdx + 1).toString();
    const cellAddressToCheck = `${columnAddress}${rowAddress}`;

    return this.highlightedSourceCells.find(
      (cell) => cell.cellAddress === cellAddressToCheck
    ).color;
  }

  /**
   * Transform a numeric column index to Excel-style alphanumeric format.
   * @param {number} columnIdx - The numeric column index to transform.
   * @returns {string} - The Excel-style alphanumeric column index.
   */
  transformNumericColumnIndexToAlphanumeric(columnIdx) {
    // Adjust column index to start from 1 for Excel-style (A=1, B=2, etc.)
    // This function now accurately reflects the offset for column headers.
    let columnName = "";
    let dividend = columnIdx + 1; // Adjusting here for Excel's 1-based indexing
    let modulo;

    while (dividend > 0) {
      modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = Math.floor((dividend - modulo) / 26);
    }

    return columnName;
  }

  /**
   * Transform an Excel-style alphanumeric column index to numeric format.
   * @param {string} columnName - The Excel-style alphanumeric column index to transform.
   * @returns {number} - The numeric column index.
   */
  transformNumericRowIndexToAlphanumeric(rowIndex) {
    // Direct mapping for rows, but remember row headers take the first row in Excel.
    // Adjust rowIndex by 1 for Excel's 1-based indexing.
    return (rowIndex + 1).toString();
  }

  /**
   * Transform an Excel-style alphanumeric row index to numeric format.
   * @param {string} rowString - The Excel-style alphanumeric row index to transform.
   * @returns {number} - The numeric row index.
   */
  transformAlphanumericColumnIndexToNumeric(columnName) {
    let sum = 0;
    for (let i = 0; i < columnName.length; i++) {
      sum *= 26;
      sum += columnName.charCodeAt(i) - "A".charCodeAt(0) + 1;
    }
    return sum - 1; // Adjusting back to 0-based indexing for internal use
  }

  /**
   * Transform an Excel-style alphanumeric row index to numeric format.
   * @param {string} rowString - The Excel-style alphanumeric row index to transform.
   * @returns {number} - The numeric row index.
   */
  transformAlphanumericRowIndexToNumeric(rowString) {
    // Convert the string to a number and adjust back for 0-based internal indexing.
    return parseInt(rowString, 10) - 1;
  }

  /**
   * Clear the highlighted cell in the table.
   */
  clearHighlightedCell() {
    this.highlightedCell = null;
  }

  /**
   * Clear all highlighted source cells in the table.
   */
  clearHighlightedSourceCells() {
    this.highlightedSourceCells = [];
  }

  // ------------------------------ Utility functions ------------------------------

  /**
   * Calculate the footnote number for a specific cell based on the current footnotes.
   * @param {number} rowIdx - The row index of the target cell.
   * @param {number} columnIdx - The column index of the target cell.
   * @returns {number} - The calculated footnote number for the cell.
   */
  calculateFootnoteNumber(rowIdx, columnIdx) {
    let newFootnoteNumber = 1; // Start numbering from 1

    // Convert indices for the target cell to alphanumeric for lookup
    const targetColumnAddress = this.transformNumericColumnIndexToAlphanumeric(
      columnIdx + 1
    );
    const targetRowAddress = rowIdx.toString(); // Rows in Excel are simply numeric
    const targetKey = `${targetColumnAddress}${targetRowAddress}`;

    // Iterate through all possible cell addresses to count footnotes
    for (let r = 1; r <= this.rowHeaders.length; r++) {
      for (let c = 1; c <= this.columnHeaders.length; c++) {
        const columnAddress = this.transformNumericColumnIndexToAlphanumeric(c);
        const rowAddress = r.toString();
        const key = `${columnAddress}${rowAddress}`;

        // Increment footnote number if a footnote exists for this cell
        if (this.footnotesLookup[key]) {
          newFootnoteNumber++;
        }

        // If we've reached the target cell, return the current footnote number
        if (key === targetKey) {
          return newFootnoteNumber;
        }
      }
    }

    // Fallback to return the computed number in case the specific cell was not found
    // This could happen if the cell is beyond the current headers' range
    return newFootnoteNumber;
  }
}

export default FinDataTable;
