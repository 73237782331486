import React from "react";
import FinDataTableFromLookupObj from "../FinDataTableFromLookupObj";

function ViewMetrics({ metricsLookup }) {
  return (
    <FinDataTableFromLookupObj
      lookupObj={metricsLookup}
      columnHeaders={["id", "metric", "metricPlainEnglish"]}
    />
  );
}

export default ViewMetrics;
