import actionTypes from "./actionTypes";
import {
  listFinDataTables,
  listFinDataTables_user,
} from "../gql/gqlHelperQueriesLayer";
import { createFinDataTable } from "../gql/gqlHelperMutationsLayer";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllFinDataTables = async (dispatch, userGroups) => {
  const isUserAdmin = determineIsUserAdmin(userGroups);

  let finDataTables = isUserAdmin
    ? await listFinDataTables()
    : await listFinDataTables_user();

  dispatch({
    type: actionTypes.LOAD_ALL_FIN_DATA_TABLES,
    payload: finDataTables,
  });
};

export const createFinDataTableAction = async (
  dispatch,
  createFinDataTableInput
) => {
  const createdFinDataTableResponse = await createFinDataTable(
    createFinDataTableInput
  );

  dispatch({
    type: actionTypes.CREATE_FIN_DATA_TABLE,
    payload: createdFinDataTableResponse,
  });

  return createdFinDataTableResponse;
};
