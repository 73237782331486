import actionTypes from "../actions/actionTypes";

const initialState = {
  companiesLookup: {},
};

// -----------------------------------------------------
// State mutation functions

function setCompanies(state, companiesArray) {
  const companiesLookup = companiesArray.reduce((accum, currValue) => {
    accum[currValue.id] = currValue;
    return accum;
  }, {});
  return { ...state, companiesLookup };
}

// -----------------------------------------------------
// Reducer

function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ALL_COMPANIES:
      return setCompanies(state, action.payload);

    default:
      return state;
  }
}

export default companiesReducer;
