import React from "react";

// Classes
import FinDataTableCell from "../../classes/FinDataTableCell";
import FinDataTable from "../../classes/FinDataTable";

// Sub-components
import FinDataTableComponent from "../FinDataTableComponent";

function ManageCompanyFinDataTableMetricOrders({
  finDataTableKey,
  companyFinDataTableMetricOrdersLookupForSelectedCompany,
}) {
  const companyFinDataTableMetricOrdersDataStructure = Object.values(
    companyFinDataTableMetricOrdersLookupForSelectedCompany
  ).find((structure) => structure.key === finDataTableKey);

  if (!companyFinDataTableMetricOrdersDataStructure) {
    return <div>{"No CompanyFinDataTableMetricOrders found"}</div>;
  }

  // Define the columns to be displayed
  const columnHeaders = [
    new FinDataTableCell("id"),
    new FinDataTableCell("company.registrantName"),
    new FinDataTableCell("metric.metric"),
    new FinDataTableCell("order"),
  ];

  // Prepare data as a 2D array for the table
  const data =
    companyFinDataTableMetricOrdersDataStructure.companyFinDataTableMetricOrders.map(
      (order) => {
        return columnHeaders.map((header) => {
          // Access nested properties if needed
          let value = header.value
            .split(".")
            .reduce((obj, key) => obj?.[key], order);
          return new FinDataTableCell(value);
        });
      }
    );

  // Sort data by the column that contains "order"
  const indexOfOrderColumn = columnHeaders
    .map((header) => header.value)
    .indexOf("order");
  data.sort((a, b) => a[indexOfOrderColumn] - b[indexOfOrderColumn]);

  const finDataTable = new FinDataTable(
    null,
    null,
    columnHeaders,
    companyFinDataTableMetricOrdersDataStructure.companyFinDataTableMetricOrders.map(
      (_, index) => new FinDataTableCell(index)
    ),
    data
  );

  return (
    <div>
      <FinDataTableComponent finDataTable={finDataTable} />
    </div>
  );
}

export default ManageCompanyFinDataTableMetricOrders;
