import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import SingularDatumReview from "./SingularDatumReview";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import { COGNITO_USER_GROUP_ADMIN } from "../../constants";
import { selectNonHumanReviewedDatums } from "../../store/selectors";

const useStyles = makeStyles({
  singularDatumReviewContainer: {
    marginBottom: 75,
  },
});

function AdminDatumReview(props) {
  const classes = useStyles();
  const authenticatedUser = useSelector(
    (state) => state.auth.authenticatedUser
  );
  const history = useHistory();

  const nonHumanReviewedDatumsLookup = useSelector((state) =>
    selectNonHumanReviewedDatums(state)
  );

  useEffect(() => {
    if (
      !(
        authenticatedUser &&
        authenticatedUser.groups &&
        Array.isArray(authenticatedUser.groups) &&
        authenticatedUser.groups.length > 0 &&
        authenticatedUser.groups.includes(COGNITO_USER_GROUP_ADMIN)
      )
    ) {
      history.push(routes.HOME);
      return;
    }
  }, [authenticatedUser, history]);

  return (
    <div>
      {Object.values(nonHumanReviewedDatumsLookup).map((datum) => (
        <div className={classes.singularDatumReviewContainer}>
          <SingularDatumReview datum={datum} key={datum.id} />
        </div>
      ))}
    </div>
  );
}

export default AdminDatumReview;
