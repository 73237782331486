import React from "react";

// Classes
import FinDataTableCell from "../classes/FinDataTableCell";
import FinDataTable from "../classes/FinDataTable";

// Sub-components
import FinDataTableComponent from "./FinDataTableComponent";

function FinDataTableFromLookupObj({ lookupObj, columnHeaders }) {
  if (Object.keys(lookupObj).length === 0) {
    return <div>{"No data to display"}</div>;
  }

  // Prepare data as a 2D array for the table
  const data = Object.values(lookupObj).map((row) => {
    return columnHeaders.map((header) => {
      // Access nested properties if needed
      const value = header.split(".").reduce((obj, key) => obj?.[key], row);
      const finDataTableCell = new FinDataTableCell(value);
      return finDataTableCell;
    });
  });

  const finDataTable = new FinDataTable(
    null,
    null,
    columnHeaders,
    Object.keys(lookupObj).map((_, index) => new FinDataTableCell(index)),
    data
  );

  return (
    <div>
      <FinDataTableComponent finDataTable={finDataTable} />
    </div>
  );
}

export default FinDataTableFromLookupObj;
