import actionTypes from "./actionTypes";
import {
  listFinancialStatements,
  listFinancialStatements_user,
} from "../gql/gqlHelperQueriesLayer";
import { createFinancialStatement } from "../gql/gqlHelperMutationsLayer";
import { COGNITO_USER_GROUP_ADMIN } from "../constants";
import { determineIsUserAdmin } from "../helpers/userGroupHelpers";

export const setAllFinancialStatements = async (dispatch, userGroups) => {
  let isUserAdmin = determineIsUserAdmin(userGroups);
  let financialStatements = isUserAdmin
    ? await listFinancialStatements()
    : // Harcoding to an empty array for now, can change if needed
      [];

  dispatch({
    type: actionTypes.LOAD_ALL_FINANCIAL_STATEMENTS,
    payload: financialStatements,
  });
};

export const createFinancialStatementAction = async (
  dispatch,
  createFinancialStatementInput
) => {
  const createdFinancialStatementResponse = await createFinancialStatement(
    createFinancialStatementInput
  );

  dispatch({
    type: actionTypes.CREATE_FINANCIAL_STATEMENT,
    payload: createdFinancialStatementResponse,
  });

  return createdFinancialStatementResponse;
};
