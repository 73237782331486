import { GQLHelper } from "../helpers/gqlHelper";
import gql from "graphql-tag";
import {
  // List queries
  listCompanysQuery,
  listCompanyFinDataTableMetricOrdersQuery,
  listDataQuery,
  listEarningsTranscriptsQuery,
  listFinancialStatementsQuery,
  listFinDataTablesQuery,
  listMetricsQuery,
  listMetricFinDataTablesQuery,
  listTenQOrKsQuery,
  listXbrlTagsQuery,
  listXbrlTagTypesQuery,
  // Get queries
  getDatumQuery,
  getEarningsTranscriptQuery,
  getTenQOrKQuery,
} from "./queries";
import {
  listCompanysQuery_user,
  listCompanyFinDataTableMetricOrdersQuery_user,
  listSecFilingDataQuery_user,
  listEarningsTranscriptDataQuery_user,
  listEarningsTranscriptsQuery_user,
  listFinancialStatementsQuery_user,
  listFinDataTablesQuery_user,
  listMetricsQuery_user,
  listMetricFinDataTablesQuery_user,
  listTenQOrKsQuery_user,
  listXbrlTagsQuery_user,
  listXbrlTagTypesQuery_user,
  // Get queries
  getEarningsTranscriptQuery_user,
  getTenQOrKQuery_user,
} from "./userQueries";

// ------------------------- List Queries -------------------------

export const listCompanies = async () => {
  return await GQLHelper.Instance.executeListQuery(listCompanysQuery);
};

export const listCompanies_user = async () => {
  return await GQLHelper.Instance.executeListQuery(listCompanysQuery_user);
};

export const listCompanyFinDataTableMetricOrders = async () => {
  return await GQLHelper.Instance.executeListQuery(
    listCompanyFinDataTableMetricOrdersQuery
  );
};

export const listCompanyFinDataTableMetricOrders_user = async () => {
  return await GQLHelper.Instance.executeListQuery(
    listCompanyFinDataTableMetricOrdersQuery_user
  );
};

export const listData = async () => {
  return await GQLHelper.Instance.executeListQuery(listDataQuery);
};

export const listData_user = async () => {
  const secFilingData = await GQLHelper.Instance.executeListQuery(
    listSecFilingDataQuery_user,
    {
      filter: {
        tenQOrKId: {
          attributeExists: true,
        },
      },
    }
  );
  const earningsTranscriptData = await GQLHelper.Instance.executeListQuery(
    listEarningsTranscriptDataQuery_user,
    {
      filter: {
        earningsTranscriptId: {
          attributeExists: true,
        },
      },
    }
  );

  return secFilingData.concat(earningsTranscriptData);
};

export const listEarningsTranscripts = async () => {
  return await GQLHelper.Instance.executeListQuery(
    listEarningsTranscriptsQuery
  );
};

export const listEarningsTranscripts_user = async () => {
  return await GQLHelper.Instance.executeListQuery(
    listEarningsTranscriptsQuery_user
  );
};

export const listFilings = async () => {
  return await GQLHelper.Instance.executeListQuery(listTenQOrKsQuery, {
    filter: {
      isEditedAndStyled: { eq: true },
    },
  });
};

export const listFilings_user = async () => {
  return await GQLHelper.Instance.executeListQuery(listTenQOrKsQuery_user, {
    filter: {
      isEditedAndStyled: { eq: true },
    },
  });
};

export const listFinancialStatements = async () => {
  return await GQLHelper.Instance.executeListQuery(
    listFinancialStatementsQuery
  );
};

export const listFinancialStatements_user = async () => {
  return await GQLHelper.Instance.executeListQuery(
    listFinancialStatementsQuery_user
  );
};

export const listFinDataTables = async () => {
  return await GQLHelper.Instance.executeListQuery(listFinDataTablesQuery);
};

export const listFinDataTables_user = async () => {
  return await GQLHelper.Instance.executeListQuery(listFinDataTablesQuery_user);
};

export const listFinancialStatementTypes = async () => {
  return await GQLHelper.Instance.executeListQuery(
    listFinancialStatementsQuery
  );
};

export const listFinancialStatementTypes_user = async () => {
  return await GQLHelper.Instance.executeListQuery(
    listFinancialStatementsQuery_user
  );
};

export const listMetrics = async () => {
  return await GQLHelper.Instance.executeListQuery(listMetricsQuery);
};

export const listMetrics_user = async () => {
  return await GQLHelper.Instance.executeListQuery(listMetricsQuery_user);
};

export const listMetricFinDataTables = async () => {
  return await GQLHelper.Instance.executeListQuery(
    listMetricFinDataTablesQuery
  );
};

export const listMetricFinDataTables_user = async () => {
  return await GQLHelper.Instance.executeListQuery(
    listMetricFinDataTablesQuery_user
  );
};

export const listXBRLTags = async () => {
  const res = await GQLHelper.Instance.executeListQuery(listXbrlTagsQuery);
  return await GQLHelper.Instance.executeListQuery(listXbrlTagsQuery);
};

export const listXBRLTags_user = async () => {
  return await GQLHelper.Instance.executeListQuery(listXbrlTagsQuery_user);
};

export const listXBRLTagTypes = async () => {
  return await GQLHelper.Instance.executeListQuery(listXbrlTagTypesQuery);
};

export const listXBRLTagTypes_user = async () => {
  return await GQLHelper.Instance.executeListQuery(listXbrlTagTypesQuery_user);
};

// ------------------------- Get Queries -------------------------

export const getDatum = async (id) => {
  return await GQLHelper.Instance.executeGetQuery(getDatumQuery, { id });
};

export const getEarningsTranscript = async (id) => {
  return await GQLHelper.Instance.executeGetQuery(getEarningsTranscriptQuery, {
    id,
  });
};

export const getEarningsTranscript_user = async (id) => {
  return await GQLHelper.Instance.executeGetQuery(
    getEarningsTranscriptQuery_user,
    {
      id,
    }
  );
};

export const getFiling = async (id) => {
  return await GQLHelper.Instance.executeGetQuery(getTenQOrKQuery, { id });
};

export const getFiling_user = async (id) => {
  return await GQLHelper.Instance.executeGetQuery(getTenQOrKQuery_user, { id });
};

export async function getStockPriceDataForCompany(ticker) {
  const res = await GQLHelper.Instance.executeGetQuery(
    gql`
      query GetStockPriceDataFMP($input: GetStockPriceDataFMPInput!) {
        getStockPriceDataFMP(input: $input) {
          symbol
          bidSize
          askPrice
          volume
          askSize
          bidPrice
          lastSalePrice
          lastSaleSize
          lastSaleTime
          fmpLast
          lastUpdated
        }
      }
    `,
    {
      input: {
        ticker,
      },
    }
  );

  return res;
}

export async function getStockPriceHistoryForCompany(ticker) {
  const res = await GQLHelper.Instance.executeGetQuery(
    gql`
      query GetStockPriceHistoryFMP($input: GetStockPriceHistoryFMPInput!) {
        getStockPriceHistoryFMP(input: $input) {
          response
        }
      }
    `,
    {
      input: {
        ticker,
      },
    }
  );

  return res;
}

export async function getDatumInnerContextXmlId(datumId) {
  const res = await GQLHelper.Instance.executeGetQuery(
    gql`
      query GetDatumInnerContextXmlId($input: GetDatumInnerContextXmlIdInput!) {
        getDatumInnerContextXmlId(input: $input) {
          innerContextXmlId
        }
      }
    `,
    {
      input: {
        id: datumId,
      },
    }
  );

  return res;
}

export async function getInnerContextXmlIdForMultipleDatums(datumIdsArray) {
  const res = await GQLHelper.Instance.executeGetQuery(
    gql`
      query GetInnerContextXmlIdForMultipleDatums(
        $input: GetInnerContextXmlIdForMultipleDatumsInput!
      ) {
        getInnerContextXmlIdForMultipleDatums(input: $input) {
          innerContextXmlIds
        }
      }
    `,
    {
      input: {
        ids: datumIdsArray,
      },
    }
  );

  return res;
}
