import { SECONDARY_COLOR } from "./colors";

export const linkStyle = {
  color: "black",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
};

export const linkIconStyle = {
  marginLeft: 3,
  color: SECONDARY_COLOR,
};
