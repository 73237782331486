import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { useSelector, useDispatch } from "react-redux";

import { createFinancialStatementAction } from "../../actions/financialStatementsActions";

import ButtonWithCircularProgress from "../ButtonWithCircularProgress";

import { extractIDsFromHtml } from "../../helpers/htmlHelpers";

import FinDataSelectInput from "../FinDataSelectInput";

const useStyles = makeStyles((theme) => ({
  createFinancialStatementForm: {
    width: "100%",
    maxHeight: "100%",
    overflowY: "scroll",
  },

  fullWidthContainer: {
    width: "100%",
    marginBottom: 20,
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
}));

function CreateFinancialStatementForm({
  textSelection,
  setFinancialStatementSidePanelClosed,
  filing,
  filingContent,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Is Saving New Financial Statement
  const [isSaving, setIsSaving] = useState(false);

  const [financialStatementType, setFinancialStatementType] = useState("");
  const [financialStatementCreated, setFinancialStatementCreated] =
    useState(false);
  const [saveError, setSaveError] = useState(null);

  const financialStatementTypesLookup = useSelector(
    (state) => state.financialStatementTypes.financialStatementTypesLookup
  );

  const handleFinancialStatementTypeChange = (event) => {
    setFinancialStatementType(event.target.value);
  };

  const handleCreateFinancialStatement = async () => {
    setIsSaving(true);

    // Extract IDs from the grabbed sentence
    const rawTextIds = extractIDsFromHtml(textSelection);

    const createFinancialStatementInput = {
      tenQOrKId: filing.id,
      financialStatementTypeId: financialStatementType,
      rawText: textSelection,
      xmlIds: rawTextIds,
      companyId: filing.companyId,
    };

    try {
      await createFinancialStatementAction(
        dispatch,
        createFinancialStatementInput
      );
      setFinancialStatementCreated(true);
      setFinancialStatementSidePanelClosed();
    } catch (error) {
      console.error(error);
      setSaveError(error);
      setIsSaving(false);
    }
  };

  return (
    <div className={classes.createFinancialStatementForm}>
      <div>
        <div className={classes.title}>Create Financial Statement Form</div>

        <Typography style={{ marginTop: "12px" }}>
          Financial Statement:
        </Typography>
        <div
          dangerouslySetInnerHTML={{ __html: textSelection }}
          className={classes.fullWidthContainer}
          style={{ marginTop: "12px" }}
        />

        <Typography style={{ marginTop: "12px" }}>
          Financial Statement Raw HTML:
        </Typography>
        <TextField
          value={textSelection}
          className={classes.fullWidthContainer}
          multiline
          style={{ marginTop: "12px" }}
        />
        <FinDataSelectInput
          lookupObject={financialStatementTypesLookup}
          id="financial-statement-type"
          selectedId={financialStatementType}
          setSelectedId={setFinancialStatementType}
          inputLabel="Financial Statement Type"
          renderMenuItem={(financialStatementType) =>
            financialStatementType.financialStatementType
          }
          style={{ marginTop: "12px", minWidth: 120, width: "100%" }}
        />
        <div style={{ marginTop: "36px" }}>
          <ButtonWithCircularProgress
            onClick={handleCreateFinancialStatement}
            disabled={financialStatementCreated}
            isSaving={isSaving}
            buttonText="Create Financial Statement"
          />
        </div>

        {saveError && <div>{saveError}</div>}
      </div>
    </div>
  );
}

export default CreateFinancialStatementForm;
