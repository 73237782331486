const actionTypes = {
  // Load actions. These describe listQueries in GraphQL that then
  // are set on our respective reducer for these data types
  LOAD_ALL_TRANSCRIPTS: "LOAD_ALL_TRANSCRIPTS",
  LOAD_ALL_DATUMS: "LOAD_ALL_DATUMS",
  LOAD_ALL_FILINGS: "LOAD_ALL_FILINGS",
  LOAD_ALL_METRICS: "LOAD_ALL_METRICS",
  LOAD_ALL_FINANCIAL_STATEMENTS: "LOAD_ALL_FINANCIAL_STATEMENTS",
  LOAD_ALL_FINANCIAL_STATEMENT_TYPES: "LOAD_ALL_FINANCIAL_STATEMENT_TYPES",
  LOAD_ALL_XBRL_TAGS: "LOAD_ALL_XBRL_TAGS",
  LOAD_ALL_XBRL_TAG_TYPES: "LOAD_ALL_XBRL_TAG_TYPES",
  LOAD_ALL_COMPANIES: "LOAD_ALL_COMPANIES",
  LOAD_ALL_FIN_DATA_TABLES: "LOAD_ALL_FIN_DATA_TABLES",
  LOAD_ALL_COMPANY_FIN_DATA_TABLE_METRIC_ORDERS:
    "LOAD_ALL_COMPANY_FIN_DATA_TABLE_METRIC_ORDERS",
  LOAD_ALL_METRIC_FIN_DATA_TABLES: "LOAD_ALL_METRIC_FIN_DATA_TABLES",

  // Update actions
  UPDATE_DATUM: "UPDATE_DATUM",
  UPDATE_TRANSCRIPT: "UPDATE_TRANSCRIPT",
  UPDATE_FILING: "UPDATE_FILING",
  UPDATE_COMPANY_FIN_DATA_TABLE_METRIC_ORDER:
    "UPDATE_COMPANY_FIN_DATA_TABLE_METRIC_ORDER",
  UPDATE_METRIC: "UPDATE_METRIC",

  // Create actions
  CREATE_DATUM: "CREATE_DATUM",
  CREATE_METRIC: "CREATE_METRIC",
  CREATE_FINANCIAL_STATEMENT: "CREATE_FINANCIAL_STATEMENT",
  CREATE_XBRL_TAG: "CREATE_XBRL_TAG",
  CREATE_FIN_DATA_TABLE: "CREATE_FIN_DATA_TABLE",
  CREATE_COMPANY_FIN_DATA_TABLE_METRIC_ORDER:
    "CREATE_COMPANY_FIN_DATA_TABLE_METRIC_ORDER",
  CREATE_METRIC_FIN_DATA_TABLE: "CREATE_METRIC_FIN_DATA_TABLE",

  // Auth actions
  SET_AUTHENTICATED_USER: "SET_AUTHENTICATED_USER",
  RESET_AUTHENTICATION: "RESET_AUTHENTICATION",
};

export default actionTypes;
